const titles = {
  ru: {
    homePage: "Главная страница",
    balancePage: "Ваш баланс",
		cashbackPage: "Акции - Кэшбэк AlifAvia.uz",
  },
  en: {
    homePage: "Home page",
    balancePage: "Your balance",
		cashbackPage: "Deals - Cashback AlifAvia.uz",
  },
  uz: {
    homePage: "Главная страница",
    balancePage: "Sizning balansingiz",
		cashbackPage: "Aksiyalar - Cashback AlifAvia.uz",
  },
};

export default titles;
