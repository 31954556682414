import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#dee3e6",
  flexGrow: 1,
  padding: "40px 0",

  [theme.down("xs")]: {
    padding: "15px 0",
  },
}));

export const Block = styled("div")(({ theme }) => ({
  ...theme.mixins.flexJustifyBetween,
  alignItems: "flex-start",
  gap: 10,

  [theme.down("lg")]: {
    flexDirection: "column",
  },
}));

export const RoutesDataAndFormBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  flexGrow: 1,
  gap: 10,
  order: 1,

  [theme.down("lg")]: {
    width: "100%",
    order: 2,
  },
}));

export const PriceAndButtonsBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 10,
  order: 2,

  [theme.down("lg")]: {
    width: "100%",
    order: 1,
  },
}));
