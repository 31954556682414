import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	margin: "auto",
}));

export const CardWrapper = styled("div")(({ theme }) => ({
	width: "400px",
	padding: 30,

	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: 20,

	borderRadius: 12,
	BorderWidth: 1,
	boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",

	[theme.upDown("xs", "sm")]: {
		borderRadius: 0,
		borderWidth: 0,
		boxShadow: "none",
	},

	[theme.down("xs")]: {
		width: "300px",
		borderRadius: 0,
		borderWidth: 0,
		boxShadow: "none",
	}
}));

export const FormTitle = styled("div")(({ theme }) => ({
	fontSize: 30,
  color: "#333",
  textAlign: "center",
  fontWeight: 700,

  [theme.down(theme.laptop)]: {
    fontSize: 24,
  },

  [theme.down(theme.tablet)]: {
    fontSize: 18,
  },
}));

export const FormSubtitle = styled("div")(({ theme }) => ({
  fontSize: 20,
  color: "#333",
  textAlign: "center",
  fontWeight: 600,

  [theme.down(theme.laptop)]: {
    fontSize: 16,
  },
}));

export const ButtonBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
	width: "100%",
}));

export const SubmitButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  ...theme.paddings(0, 15),
	width: "100%",
  position: "relative",
  fontSize: 20,
  border: "none",
  backgroundColor: theme.palette.searchButtonBg,
  color: "#fff",
  height: 46,
  cursor: "pointer",

  "&:hover": {
    filter: "brightness(90%)",
  },

	[theme.down("xs")]: {
		fontSize: 16,
	},
}));

export const SubmitButtonLoadingCover = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.searchButtonBg,

  "&:hover": {
    filter: "brightness(90%)",
  },
}));

export const BackButtonBlock = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	justifyContent: "center",
	gap: 5,
}));

export const ResetButtonBlock = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	justifyContent: "flex-start",
	gap: 5,
}));

export const ResetButtonLabel = styled("span")(({ theme }) => ({
	fontSize: 14,
	LineHeight: "20px",

	[theme.down("xs")]: {
		fontSize: 11,
	},
}));

export const ResetButton = styled("button")(({ theme }) => ({
	border: "none",
	backgroundColor: "transparent",
	textDecoration: "none",

	color: theme.palette.searchButtonBg,
	fontSize: 14,
	LineHeight: "20px",

	transition: "all .5s ease-out",

	cursor: "pointer",
	"&:hover": {
		textDecoration: "underline",
	},

	[theme.down("xs")]: {
		fontSize: 11,
	},
}));

export const BackButtonLabel = styled("span")(({ theme }) => ({
	fontSize: 14,
	LineHeight: "20px",
}));

export const BackButton = styled("button")(({ theme }) => ({
	border: "none",
	backgroundColor: "transparent",
	textDecoration: "none",

	color: theme.palette.searchButtonBg,
	fontSize: 14,
	LineHeight: "20px",

	transition: "all .5s ease-out",

	cursor: "pointer",
	"&:hover": {
		textDecoration: "underline",
	},
}));