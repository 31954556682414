import { useCallback, useMemo, useState } from "react";

import { RiCloseLine, RiSearchLine } from "react-icons/ri";

import { debounce } from "../../../../utils";

import CircularLoading from "../../CircularLoading";

import { BeforeIconBlock, IconBlock, StyledInput, Wrapper } from "./style";

const TableFiltersInput = ({ filterKey, onChangeFilter, placeholder, hasBeforeIcon }) => {
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async (text) => {
    setIsLoading(true);
    await onChangeFilter(filterKey, text);
    setIsLoading(false);
  }, []);

  const debouncedGetData = useMemo(() => debounce(getData, 300), [getData]);

  const handleChangeInput = (newValue) => {
    setInputText(newValue);
    debouncedGetData(newValue);
  };

  const hasAfterIcon = isLoading || (!isLoading && inputText.length > 0);

  return (
    <Wrapper>
      {hasBeforeIcon && (
        <BeforeIconBlock>
          <RiSearchLine />
        </BeforeIconBlock>
      )}

      <StyledInput
        value={inputText}
        onChange={e => handleChangeInput(e.target.value)}
        placeholder={placeholder}
        $hasBeforeIcon={hasBeforeIcon}
        $hasAfterIcon={hasAfterIcon}
      />

      <IconBlock>
        {(!isLoading && inputText.length > 0) ? <RiCloseLine onClick={() => handleChangeInput("")} /> : null}
        {isLoading ? <CircularLoading size="16px" color="#b7b7b7" /> : null}
      </IconBlock>
    </Wrapper>
  );
};

export default TableFiltersInput;
