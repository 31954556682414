import React from "react";

import { CardDesc, Cashback, Desc, Img, Title, Wrapper } from "./style";

export default function Card ({
	imgSrc,
	title,
	href,
	cashback,
	desc
}) {
	return (
		<Wrapper>
			<Img>
				<img src={imgSrc} alt="logo" />
			</Img>

			{/* {cashback} {
				<Cashback>
					<p>{cashback}</p>
				</Cashback>
			} : {
				<Cashback className="transparent">
				</Cashback>
			} */}

			<Cashback className="transparent">
				<p>{cashback}</p>
			</Cashback>

			<CardDesc>
				<Title>
					<h2>{title}:</h2>
				</Title>

				<Desc>
					<p>{desc}</p>  {/*Кэшбек применяется на рейсы следующие из аэропортов Узбекистана*/}
				</Desc>
			</CardDesc>
		</Wrapper>
	);
};