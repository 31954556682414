import i18next from "i18next";
import { phoneMask } from "../../../config";
import { Description, FormBlock, InputBlock, Title, Wrapper } from "./style";
import { InputComponent } from "../../ui";

const BookingPayerInfoComponent = ({ form }) => {
  const { t } = i18next;

  const { values, errors, touched, handleBlur, setFieldValue } = form;

  const handleChangeEmail = value => {
    setFieldValue("payer_email", value);
    setFieldValue("passengers[0].email", value);
  };

  const handleChangePhone = value => {
    setFieldValue("payer_phone", value);
    setFieldValue("passengers[0].phone", value);
  };

  return (
    <Wrapper>
      <Title>{t("payer_contacts")}</Title>

      <Description>
        <span>{t("for_payer_mess")}.</span>
      </Description>

      <FormBlock>
        <InputBlock>
          <InputComponent
            value={values.payer_email}
            touched={touched.payer_email}
            error={touched.payer_email && errors.payer_email}
            success={touched.payer_email && !errors.payer_email && values.payer_email}
            onChange={handleChangeEmail}
            onBlur={handleBlur("payer_email")}
            placeholder={t("email")}
            label={t("email")}
            required
          />
        </InputBlock>

        <InputBlock>
          <InputComponent
            value={values.payer_phone}
            touched={touched.payer_phone}
            error={touched.payer_phone && errors.payer_phone}
            onChange={handleChangePhone}
            onBlur={handleBlur("payer_phone")}
            placeholder={t("phone_number")}
            label={t("phone_number")}
            mask={phoneMask}
            required
          />
        </InputBlock>
      </FormBlock>
    </Wrapper>
  );
};

export default BookingPayerInfoComponent;
