import moment from "moment";

export const getMonths = () => {
  const currentMonth = moment().startOf("month");
  let months = [];

  for (let i = 0; i < 12; i++) {
    months.push({
      startDate: currentMonth.clone().add(i, "months")
    });
  }

  return months;
};
