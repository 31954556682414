export const ONLY_RU_COUNTRY = "ONLY_RU_COUNTRY";
export const FRIENDLY_COUNTRY = "FRIENDLY_COUNTRY";
export const ALL_COUNTRY = "ALL_COUNTRY";
export const RU = 'RU';

export const M = 'M';
export const F = 'F';

export const PS = 'PS';
export const SR = 'SR';
export const PSP = 'PSP';
export const NP = 'NP';

export const ALL_DOCUMENTS_ITEMS = [NP, PS, SR, PSP];
