const functional = {
  paddings: (top, right, bottom, left) => ({
    paddingTop: top,
    paddingRight: right !== null && right !== undefined ? right : top,
    paddingBottom: bottom !== null && bottom !== undefined ? bottom : top,
    paddingLeft: left !== null && left !== undefined ? left : right !== null && right !== undefined ? right : top,
  }),
  margins: (top, right, bottom, left) => ({
    marginTop: top,
    marginRight: right !== null && right !== undefined ? right : top,
    marginBottom: bottom !== null && bottom !== undefined ? bottom : top,
    marginLeft: left !== null && left !== undefined ? left : right !== null && right !== undefined ? right : top,
  }),
};

export default functional;
