import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexJustifyBetween,
  gap: 10,
  marginBottom: 10,
  

  [theme.down("sm")]: {
    flexDirection: "column",
    gap: 0,
  },
}));
