import { useEffect, useRef } from "react";

import i18next from "i18next";
import moment from "moment";
import { useDispatch } from "react-redux";

import { useLocalStorage } from "../../hooks";
import { setAppConfigs } from "../../store/appConfigsSlice";
import { getRequisites } from "../../protocols";

const ConfigurationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { getItem, setItem } = useLocalStorage();

  const getRequisiteRequest = useRef(async () => {
    try {
      const result = await getRequisites();

      const { available_currencies, available_languages } = result?.data || {};
      configureCurrencies(available_currencies);
      configureLocalization(available_languages);
    } catch (error) {
      console.log({ error });
    }
  });

  useEffect(() => {
    getRequisiteRequest.current();
  }, []);

  const configureCurrencies = (availableCurrencies) => {
    const localCurrency = getItem("currency");
    const { available_currencies } = JSON.parse(availableCurrencies);

    if (localCurrency && available_currencies.includes(localCurrency)) {
      dispatch(
        setAppConfigs({
          currency: localCurrency,
          availableCurrencies: available_currencies,
        })
      );
    } else {
      dispatch(
        setAppConfigs({
          currency: available_currencies[0],
          availableCurrencies: available_currencies,
        })
      );

      setItem("currency", available_currencies[0]);
    }
  };

  const configureLocalization = availableLanguages => {
    const languages = JSON.parse(availableLanguages).reduce((acc, current) => {
      acc.push(current.lang_code);

      return acc;
    }, []);

    let currentLanguage = "";

    if (!languages.includes(i18next.language)) {
      currentLanguage = languages[0];
      i18next.changeLanguage(currentLanguage);
    } else {
      currentLanguage = i18next.language;
    }

    dispatch(
      setAppConfigs({
        availableLanguages: languages,
        language: currentLanguage,
      })
    );

    moment.updateLocale(currentLanguage === "tj" ? "tg" : currentLanguage, { week: { dow: 1 } });
  };

  return children;
};

export default ConfigurationProvider;
