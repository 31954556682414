const metaOgObject = {
  ru: {
    homePage: {
      title: "Онлайн-касса AlifAvia.uz",
      type: "website",
      url: "https://avia.alif.uz/",
      image: "https://avia.alif.uz/images/background.png",
      description: "Наша удобная платформа делает бронирование авиабилетов простым делом. Найдите идеальный маршрут, получите предложение cashback и завершите покупку за считанные минуты.",
      site_name: "AlifAviaUz",
    },
		cashbackPage: {
			title: "Акции - Кэшбэк AlifAvia.uz",
			type: "website",
			url: "https://avia.alif.uz/cashback-info",
			image: "https://avia.alif.uz/images/seo/cashback.jpg",
			description: "Надоело просто копить мили, которые вы, возможно, никогда не используете? Зарабатывайте реальные деньги на каждом рейсе, который вы бронируете у нас.",
			site_name: "AlifAviaUz Кэшбэк",
		},

  },
  en: {
    homePage: {
      title: "Online ticketing AlifAvia.uz",
      type: "website",
      url: "https://avia.alif.uz/",
      image: "https://avia.alif.uz/images/background.png",
      description: "Our user-friendly platform makes booking flights a breeze. Find the perfect itinerary, secure your cashback offer, and complete your purchase in minutes.",
      site_name: "AlifAviaUz",
    },
		cashbackPage: {
			title: "Deals - Cashback AlifAvia.uz",
			type: "website",
			url: "https://avia.alif.uz/cashback-info",
			image: "https://avia.alif.uz/images/seo/cashback.jpg",
			description: "Tired of just racking up miles you might never use? Earn real cash back on every flight you book with us.",
			site_name: "AlifAviaUz Cashback",
		},
  },
  uz: {
    homePage: {
      title: "Onlayn-kassa AlifAvia.uz",
      type: "website",
      url: "https://avia.alif.uz/",
      image: "https://avia.alif.uz/images/background.png",
      description: "Bizning qulay platformamiz parvozlarni bron qilishni osonlashtiradi. Ideal marshrutingizni toping, cashback taklifini oling va xaridingizni bir necha daqiqada yakunlang.",
      site_name: "AlifAviaUz",
    },
		cashbackPage: {
			title: "Aksiyalar - Cashback AlifAvia.uz",
			type: "website",
			url: "https://avia.alif.uz/cashback-info",
			image: "https://avia.alif.uz/images/seo/cashback.jpg",
			description: "Hech qachon foydalana olmaydigan millarni yig'ishdan charchadingizmi? Biz bilan bron qilgan har bir reysda haqiqiy pul ishlang.",
			site_name: "AlifAviaUz Cashback",
		},
  },
};

export default metaOgObject;
