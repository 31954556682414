import { useDispatch, useSelector } from "react-redux";

import { setFilterParams } from "../../../store/resultFiltersSlice";

import FilterGroupComponent from "../FilterGroupComponent";
import { CheckboxComponent } from "../../ui";

import { CheckboxItem, CheckboxesBlock } from "./style";
import i18next from "i18next";

const FilterAirlinesComponent = () => {
  const { t, language } = i18next;
  const {
    filterParams: {
      airlines: { values },
    },
  } = useSelector(state => state.resultFilters);
  const { included } = useSelector(state => state.resultData);
  const { supplier } = included;
  const airlines = Object.keys(supplier).map(key => supplier[key]);
  const dispatch = useDispatch();

  const handleChange = (item, value) => {
    let newAirlineFilter = [];

    if (!value) {
      newAirlineFilter = values.filter(val => {
        return item.iata !== val;
      });
    } else {
      newAirlineFilter = [...values, item.iata];
    }

    dispatch(
      setFilterParams({
        type: "airlines",
        values: {
          isActive: newAirlineFilter.length > 0,
          values: newAirlineFilter,
        },
      })
    );
  };

  return (
    <FilterGroupComponent title={t("airlines")} isOpenable={false}>
      <CheckboxesBlock>
        {airlines.map(item => (
          <CheckboxItem key={item.iata}>
            <CheckboxComponent
              label={item.name[language]}
              value={values.includes(item.iata)}
              onChange={e => handleChange(item, e.target.checked)}
            />
          </CheckboxItem>
        ))}
      </CheckboxesBlock>
    </FilterGroupComponent>
  );
};

export default FilterAirlinesComponent;
