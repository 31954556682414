import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { LayoutComponent } from "../components";

import {
	AuthPage,
	BalancePage,
	BookingPage,
	CashbackPage,
	HomePage,
	InfoPaymentPage,
	OrderPage,
	OrdersPage,
	PrivacyPolicyPage,
	PublicOfferPage,
	RegistrationPage,
	ResultPage,
	RulesOfUsePage,
	TransactionsPage,
	ContactsPage
} from "../pages";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutComponent />}>
      <Route index element={<HomePage />} />
      <Route path="/result/:searchId" element={<ResultPage />} />
      <Route path="/booking" element={<BookingPage />} />
      <Route path="/order/:orderId/:sessionId" element={<OrderPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/user/balance" element={<BalancePage />} />
      <Route path="/payment-info" element={<InfoPaymentPage />} />
      <Route path="/privacy-policy-info" element={<PrivacyPolicyPage />} />
      <Route path="/public-offer-info" element={<PublicOfferPage />} />
      <Route path="/rules-of-use-info" element={<RulesOfUsePage />} />
      <Route path="/transactions" element={<TransactionsPage />} />
			<Route path="/cashback-info" element={<CashbackPage />} />
			<Route path="/contacts" element={<ContactsPage />} />
    </Route>
  )
);

export default routes;
