import styled from "styled-components";

export const Wrapper = styled.div(({ theme }) => ({
  ...theme.paddings(20, 0),
  backgroundColor: "#dee3e6",
  flexGrow: 1,
}));

export const Title = styled.h2(({ theme }) => ({
  marginBottom: 20,
  color: "#151515",
}));

export const Content = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(15),
  gap: 15,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
}));

export const Header = styled.div(({ theme }) => ({
  paddingBottom: 15,
  color: "#4a4a4a",
  borderBottom: "1px solid #cecece",
}));

export const CurrentBalance = styled.div(({ theme }) => ({
  fontSize: 20,
}));

export const Body = styled.div(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 15,
}));

export const Subtitle = styled.h4(({ theme }) => ({
  color: "#4a4a4a",
}));

export const PaymentMethodsBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: 5,

  [theme.down("xl")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  [theme.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [theme.down("xs")]: {
    gridTemplateColumns: "1fr",
  },
}));

export const PaymentMethodItem = styled("div")(({ theme, $isActive }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(10),
  gap: 10,
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  border: `1px solid ${$isActive ? "#1D3557" : "#4a4a4a"}`,
  cursor: "pointer",
}));

export const PaymentMethodCheckboxBlock = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 5,
  top: 5,
}));

export const PaymentMethodLogo = styled("img")(({ theme }) => ({
  maxWidth: 160,
  maxHeight: 100,

  [theme.down("xs")]: {
    maxWidth: 120,
  },
}));

export const PaymentMethodDescription = styled("div")(({ theme, $isActive }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: $isActive ? "#151515" : "#9e9e9e",
}));

export const InputBlock = styled.div(({ theme }) => ({

}));

export const Button = styled("button")(({ theme }) => ({
  ...theme.paddings(0, 15),
  position: "relative",
  border: "none",
  backgroundColor: theme.palette.searchButtonBg,
  cursor: "pointer",
  height: 46,
  fontSize: 20,
  color: theme.palette.white,
  borderRadius: 4,

  "&:disabled": {
    filter: "brightness(50%)",
    cursor: "default",
  },

  "&:hover": {
    filter: "brightness(90%)",
  },

  [theme.down("sm")]: {
    fontSize: 16,
  },

  "& > div": {
    ...theme.mixins.flexCenterCenter,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.searchButtonBg,
  },
}));
