import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ContactsComponent } from '../../components/info/ContactsComponent';
import { setShowSearch } from "../../store/appConfigsSlice";

const ContactsPage = () => {

	const dispatch = useDispatch();
	
	useEffect(() => {
    dispatch(setShowSearch(false));
  }, []);

	return (
		<ContactsComponent />
	)
}

export default withTranslation()(ContactsPage);