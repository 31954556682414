import { appDefaultLanguage } from "../../config";
import { ContentType, request } from "../api";
import { baseParams } from "../common";

const formatResponse = (data) => {
  const result = [];

  for (const item of data) {
    result.push({
      code: item["item_code"],
      countryCode: item["country_code"],
      countryName: item["country"],
      name: item["item"],
      isMain: true,
    });

    if (item["airports"].length > 1) {
      item["airports"].forEach((airport) => {
        result.push({
          code: airport["airport_code"],
          countryCode: item["country_code"],
          countryName: item["country"],
          name: airport["airport"],
          isMain: false,
        });
      });
    }
  }

  return result;
};

export const getCities = async (value, language = appDefaultLanguage, limit = 7, params = {}) => {
  const response = await request({
    path: "/cities",
    method: "POST",
    body: { ...baseParams, value, language, limit },
    type: ContentType.Json,
    format: "json",
    ...params,
  });

  return formatResponse(response?.data?.data);
};
