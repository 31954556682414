import styled from "styled-components";
import MaskedTextInput from "react-text-mask";

export const InputGroup = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const Label = styled("label")(({ theme }) => ({
  width: "100%",
  fontSize: 10,
  paddingLeft: 15,
  paddingTop: 2,
  color: "#75787b",
}));

export const InputBlock = styled("div")(({ theme, isError, isSuccess }) => ({
  ...theme.mixins.flexColumn,
  position: "relative",
  height: 50,
  borderRadius: 0,
  border: `1px solid ${isError ? "#e53935" : "#ccc"}`,
  ...(isSuccess ? { borderLeftWidth: 3, borderLeftColor: "#8cd64e" } : {}),
  transition: "all .2s ease",
}));

export const InputElement = styled("input")(({ theme }) => ({
  ...theme.paddings(10, 30, 0, 15),
  position: "absolute",
  width: "100%",
  height: "100%",
  border: "none",
  lineHeight: 1,
  backgroundColor: "transparent",
  fontSize: 16,

  "&:hover, &:active, &:focus": {
    outline: "none",
  },
}));

export const MaskedInputElement = styled(MaskedTextInput)(({ theme }) => ({
  ...theme.paddings(10, 30, 0, 15),
  position: "absolute",
  width: "100%",
  height: "100%",
  border: "none",
  lineHeight: 1,
  backgroundColor: "transparent",
  fontSize: 16,

  "&:hover, &:active, &:focus": {
    outline: "none",
  },
}));

export const Error = styled("div")(({ theme }) => ({
  color: "#e53935",
  fontSize: 14,
  marginTop: 4,

  [theme.down("sm")]: {
    fontSize: 12,
  },
}));
