import { ContainerComponent } from "../../ui";
import OrderFlightInfoComponent from "../OrderFlightInfoComponent";
import OrderIdAndStatusComponent from "../OrderIdAndStatusComponent";
import OrderPassengersComponent from "../OrderPassengersComponent";
import OrderPaymentFormComponent from "../OrderPaymentFormComponent";
import OrderPriceComponent from "../OrderPriceComponent";
import { Block, PriceAndButtonsBlock, RoutesDataAndFormBlock, Wrapper } from "./style";
import { useState } from "react";
import { useSelector } from "react-redux";
import { checkInAccessToPaymentStatus } from "../../../utils";
import OrderCopyAndCancelComponent from "../OrderCopyAndCancelComponent";

const OrderComponent = ({
  sendPayment,
  cancelOrder,
}) => {
  const { orderData } = useSelector(state => state.order);

  const isAccessToPayment = checkInAccessToPaymentStatus(orderData?.booking_status, orderData?.order_status);

  const [voidStatus, setVoidStatus] = useState(false);

  return (
    <Wrapper>
      <ContainerComponent>
        <Block>
          <RoutesDataAndFormBlock>
            <OrderIdAndStatusComponent />
            <OrderPassengersComponent />
            <OrderFlightInfoComponent />
            <OrderCopyAndCancelComponent onCancelOrder={cancelOrder} isShowInSmallScreen />
          </RoutesDataAndFormBlock>

          <PriceAndButtonsBlock>
            <OrderIdAndStatusComponent isShowInSmallScreen />
            <OrderPriceComponent />
            {isAccessToPayment && !voidStatus && <OrderPaymentFormComponent sendPaymentRequest={sendPayment} />}
            <OrderCopyAndCancelComponent onCancelOrder={cancelOrder} />
          </PriceAndButtonsBlock>
        </Block>
      </ContainerComponent>
    </Wrapper>
  );
};

export default OrderComponent;
