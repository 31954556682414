import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")(({theme}) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	maxWidth: 900,
	margin: "40px auto",

	"& > div": {
		fontSize: "15px",
		lineHeight: "24px",
	},

	[theme.down("md")]: {
		maxWidth: 700,
	},

	[theme.down("sm")]: {
		maxWidth: 500,
	},

	[theme.down("xs")]: {
		width: "100%",
		padding: "0px 20px",
	},
}));

export const PublicOfferContent =styled("div")(({theme}) => ({}));

export const PublicOfferTitle = styled("h4")(({theme}) => ({
	fontSize: 20,
	fontWeight: 700,
	textTransform: "uppercase",
	textAlign: "center",
	lineHeight: 2,
}));

export const PublicOfferDate = styled("div")(({theme}) => ({
	margin: "32px 0",
}));

export const City = styled("p")(({theme}) => ({

}));

export const Date = styled("p")(({theme}) => ({

}));

export const PublicOfferInfo = styled("div")(({theme}) => ({
	"& a": {
		color: "#01A0D4",
	},

	"& a:hover": {
	 textDecoration: "underline",
 },
}));

export const PublicOfferInfoTitle = styled("h4")(({theme}) => ({
	marginTop: 32,
	marginBottom: 16, 
	fontSize: 20,
	fontWeight: 700,

}));

export const PublicOfferContentLink = styled("ul")(({theme}) => ({
	listStyleType: "'-'",
}));

export const PublicOfferContentLinkComponent = styled("li")(({theme}) => ({
	marginLeft: 5,
  paddingTop: 5,

  "&:firstChild(1)": {
    paddingTop: 0,
  }
}));

export const LinkItem = styled((props) => (
	<Link {...props} />
))(({ theme }) => ({
color: "#01A0D4",
transition: "all .5s ease-out",

"&:hover": {
	textDecoration: "underline",
},
}));