import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({}));

export const HeaderBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  backgroundColor: "#358ed3",
  height: 46,
  overflow: "hidden",
  cursor: "pointer",

  "& *": {
    "user-select": "none",
  },

  [theme.down("xs")]: {
    alignItems: "flex-start",
    height: "auto",
    flexWrap: "wrap",
    position: "relative",
  }
}));

export const HeaderIconBlock = styled("div")(({ theme }) => ({
  position: "relative",
  height: 46,
  width: 66,
  minWidth: 66,

  [theme.down("xs")]: {
    position: "absolute",
  }
}));

export const HeaderIcon = styled("div")(({ theme, $isChild }) => ({
  ...theme.mixins.flexCenterCenter,
  backgroundColor: "#fff",
  borderRadius: "50%",
  width: 66,
  height: 66,
  position: "absolute",
  top: -10,
  left: -10,
  boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, .5)",

  "& > div": {
    ...theme.mixins.flexCenterCenter,
    border: "1px solid #358ed3",
    borderRadius: "50%",
    height: 46,
    width: 46,

    "& > svg": {
      transform: "scale(2)",

      ...($isChild ? {
        position: "absolute",
        left: 15,
        top: 12,
      } : {}),
    },

    "& *": {
      fill: "#358ed3 !important",
    },
  },
}));

export const HeaderPassengerType = styled("div")(({ theme }) => ({
  color: "#fff",
  fontWeight: 700,
  letterSpacing: "1.5px",
  lineHeight: "12px",
  fontSize: 12,
  width: 180,

  [theme.down("lg")]: {
    width: 90,
    minWidth: 90,
  },

  [theme.down("xs")]: {
    width: "100%",
    paddingRight: 10,
    lineHeight: "unset",
    textAlign: "right",
  },
}));

export const HeaderToggleButton = styled("div")(({ theme, $isActive }) => ({
  ...theme.mixins.flexCenterCenter,
  height: 46,
  width: 46,
  minWidth: 46,
  color: "#fff",
  transition: "all .3s ease",
  ...($isActive ? { transform: "rotate(90deg)" } : {}),

  [theme.down("xs")]: {
    display: "none",
  }
}));

export const HeaderPassengerName = styled("div")(({ theme }) => ({
  flexGrow: 1,
  fontSize: 16,
  color: "#fff",
  fontWeight: 700,
  letterSpacing: ".5px",

  [theme.down("xs")]: {
    width: "100%",
    textAlign: "right",
    paddingRight: 10,
    paddingLeft: 66,
  }
}));

export const HeaderPassengerBirthday = styled("div")(({ theme }) => ({
  fontSize: 16,
  color: "#fff",
  fontWeight: 700,
  letterSpacing: ".5px",
  paddingRight: 10,

  [theme.down("xs")]: {
    width: "100%",
    textAlign: "right",
    flexGrow: 1,
  }
}));

export const BodyBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: 10,
  paddingTop: 10,

  [theme.down("xl")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  [theme.down("xs")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

export const InfoItemBlock = styled("div")(({ theme, $isCitizenship }) => ({
  ...theme.mixins.flexColumn,
  gap: 5,

  [theme.down("xl")]: {
    ...($isCitizenship ? { gridColumn: "1 / 3" } : {}),
  },

  [theme.down("xs")]: {
    gridColumn: "auto",
    gap: 3,
  },
}));

export const InfoItemTitle = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: "#9e9e9e",
}));

export const InfoItemValue = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: "#151515",
}));
