import { ContentType, request } from "../api";
import { baseParams } from "../common";
import { formattedRoutesData } from "./searchFlights";

const searchBrandFares = (result) => {
  result?.flights.forEach(recommendation => formattedRoutesData(recommendation?.routes));

  return result;
};

export const getBrandFares = async (data = {}, params = {}) => {
  const response = await request({
    path: "/brand-fare",
    method: "POST",
    body: { ...baseParams, ...data },
    type: ContentType.Json,
    format: "json",
    ...params,
  });

  return searchBrandFares(response?.data);
};
