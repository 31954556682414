import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, $width }) => ({
  position: "relative",
  minWidth: "100%",
  cursor: "default",

  "& > *": {
    userSelect: "none",
  },
}));

export const StyledButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterBetween,
  ...theme.paddings(0, 30, 0, 10),
  height: 32,
  backgroundColor: "#fff",
  color: "#151515",
  border: "1px solid #9e9e9e",
  borderRadius: 0,
  width: "100%",
  zIndex: 5,
  cursor: "pointer",
  fontSize: 14,  
}));

export const DropdownIcon = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  padding: 4,
  position: "absolute",
  right: 4,
  top: 5,
  color: "#808080",
  transition: "all .3s ease",
  transform: "rotate(0deg)",

  "&.active": {
    transition: "all .3s ease",
    transform: "rotate(180deg)",
  },
}));

export const DropdownBlock = styled("div")(({ theme }) => ({
  display: "none",
  flexDirection: "column",
  position: "absolute",
  backgroundColor: "#fff",
  boxShadow: "0 1px 1px rgba(0,0,0,.1)",
  border: "1px solid #b8b8b8",
  borderTop: "none",
  minWidth: "100%",
  borderRadius: 4,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  top: "calc(100% - 1px)",
  zIndex: 3,
  
  "&.active": {
    display: "flex",
  },
}));

export const DropdownOption = styled("div")(({ theme }) => ({
  ...theme.paddings(7, 10),
  cursor: "pointer",
  color: "#151515",
  fontSize: 14,

  "&:hover": {
    backgroundColor: "#f2fbff",
  },
}));
