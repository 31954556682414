import { useSelector } from "react-redux";
import { DownloadBlock, DownloadButton, InfoBlock, ItemTitle, ItemValue, Wrapper } from "./style";
import i18next from "i18next";
import { checkForMIMEType, checkIsAllowedMK, getBookStatus, getColorStatus } from "../../../utils";
import { TbDownload } from "react-icons/tb";
import { getDocument } from "../../../protocols";

const OrderIdAndStatusComponent = ({ isShowInSmallScreen }) => {
  const { language, t } = i18next;
  const { orderData } = useSelector(state => state.order);

  const isAlloweMK = checkIsAllowedMK(orderData?.booking_status);

  const downloadMk = () => {
    const params = {
      type: "mk",
      order_id: orderData.order_id,
      session_id: orderData.session_id,
    };

    getDocument(params, language).then(response => {
      const { pdf } = response?.data;

      checkForMIMEType(pdf, "pdf");
    });
  };

  return (
    <Wrapper $isShowInSmallScreen={isShowInSmallScreen}>
      <InfoBlock>
        <div>
          <ItemTitle>{t("order_num")}</ItemTitle>
          <ItemValue>{orderData?.order_id}</ItemValue>
        </div>

        <div>
          <ItemTitle>{t("status")}</ItemTitle>
          <ItemValue>
            <span className="order-id" style={{ backgroundColor: getColorStatus(orderData?.booking_status) }}>{getBookStatus(orderData?.booking_status, orderData?.order_status, t)}</span>
          </ItemValue>
        </div>
      </InfoBlock>

      {isAlloweMK && (
        <DownloadBlock>
          <DownloadButton onClick={downloadMk} title={t("download_itinerary_receipt")}>
            <TbDownload />
          </DownloadButton>
        </DownloadBlock>
      )}
    </Wrapper>
  );
};

export default OrderIdAndStatusComponent;
