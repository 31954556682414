import { ContentType, request } from "../api";

import { baseParams } from "../common";

export const getOrders = async (data = {}, params = {}) => request({
  path: "/order-list",
  method: "POST",
  secure: true,
  body: { ...baseParams, ...data },
  type: ContentType.Json,
  format: "json",
  ...params,
});
