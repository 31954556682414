const sizes = {
  xs: 630,
  sm: 830,
  md: 1030,
  lg: 1230,
  xl: 1530,
};

const breakpoints = {
  ...sizes,
  up: (value) => `@media (min-width: ${sizes[value] || 0}px)`,
  down: (value) => `@media (max-width: ${sizes[value] || sizes.xl - 1}px)`,
  upDown: (min, max) => `@media (min-width: ${sizes[min] || 0}px) and (max-width: ${sizes[max] || sizes.xl - 1}px)`,
};

export default breakpoints;
