import { useRef, useState } from "react";

import i18next from "i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { ContainerComponent, DotsLoadingComponent, TableComponent, TableFilters } from "../../ui";

import { LoadingBlock } from "../../../pages/ResultPage/style";

import { NotFoundComponent, TitleComponent, Wrapper } from "./style";
import { formatDateTime } from "../../../config";
import { getBookStatus, normalizePrice } from "../../../utils";

const OrdersComponent = ({ isLoading, orders, included, page, lastPage, request }) => {
  const { language, t } = i18next;
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(page || 1);
  const filters = useRef({});

  const CreatedTime = ({ createdAt }) => {
    const time = moment(createdAt, formatDateTime);

    return (
      <>
        <div>{time.format("DD.MM.YYYY")}</div>
        <div>{time.format("HH:mm:ss")}</div>
      </>
    );
  };

  const Routes = ({ routes }) => routes.map(route => {
    const departureAirportCode = route?.departure_airport_code;
    const arrivalAirportCode = route?.arrival_airport_code;
    const departureAirportCityCode = included.airport[departureAirportCode].city_code;
    const arrivalAirportCityCode = included.airport[arrivalAirportCode].city_code;
    const departureCity = included.city[departureAirportCityCode][language];
    const arrivalCity = included.city[arrivalAirportCityCode][language];

    return (
      <div key={route.id}>{`${departureCity} → ${arrivalCity}`}</div>
    );
  });

  const DepartureTime = ({ routes }) => {
    const route = routes?.length ? routes[0] : {};
    const time = moment(route?.departure_at, formatDateTime);

    return (
      <>
        <div>{time.format("DD.MM.YYYY")}</div>
        <div>{time.format("HH:mm")}</div>
      </>
    );
  };

  const Timelimit = ({ timelimit }) => {
    const timelimitUtc = moment.utc(timelimit, formatDateTime).tz(moment.tz.guess());

    return (
      <>
        <div>{timelimitUtc.format("DD.MM.YYYY")}</div>
        <div>{timelimitUtc.format("HH:mm:ss")}</div>
      </>
    );
  };

  const handleChangePage = async (pageType) => {
    if (pageType === "next") {
      await request({ page: currentPage + 1 });
      setCurrentPage(prev => prev + 1);
    }

    if (pageType === "prev") {
      await request({ page: currentPage - 1 });
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleChangeFilters = async (key, value) => {
    filters.current = { ...filters.current, [key]: value };
    await request({ ...filters.current, page: 1 });
    setCurrentPage(1);
  };

  return (
    <Wrapper>
      <ContainerComponent>
        <TitleComponent>{t("orders")}</TitleComponent>

        {isLoading && (
          <LoadingBlock>
            <DotsLoadingComponent />
          </LoadingBlock>
        )}

        {(!isLoading) ? (
          <TableFilters
            filters={[
              {
                type: "input",
                key: "search_term",
                placeholder: t("locator_order_number_or_booking_number"),
                hasBeforeIcon: true,
              }, {
                type: "date",
                key: "timestamp_start",
                placeholder: t("date_from_d"),
              }, {
                type: "date",
                key: "timestamp_end",
                placeholder: t("date_to_d"),
              }
            ]}
            onChangeFilters={handleChangeFilters}
          />
        ) : null}
      </ContainerComponent>

      <ContainerComponent fixed={false}>
        {(!isLoading && !orders?.length) && (
          <NotFoundComponent>У вас нет заказов!</NotFoundComponent>
        )}

        {(!isLoading && orders?.length) ? (
          <TableComponent
            columns={[
              { key: "created_at", label: t("date"), render: (column) => <CreatedTime createdAt={column.created_at} /> },
              { key: "order_id", label: t("order_num") },
              { key: "booking_number", label: "PNR" },
              { key: "routes", label: t("route"), render: (column) => <Routes routes={column.routes} /> },
              { key: "departure_time", label: t("departure_time"), render: (column) => <DepartureTime routes={column.routes} /> },
              { key: "price", label: t("order_cost"), render: (column) => `${normalizePrice(column?.price)} ${column?.currency}` },
              { key: "timelimit", label: t("pay_before"), render: (column) => <Timelimit timelimit={column.timelimit} /> },
              { key: "booking_status", label: t("order_status_label"), render: (column) => getBookStatus(column?.booking_status, column?.order_status, t) },
            ]}
            data={orders}
            minWidth={1250}
            isClickableRow
            onClickRow={(column) => navigate(`/order/${column?.order_id}/${column?.session_id}`)}
            onChangePage={handleChangePage}
            prevButtonDisabled={currentPage === 1}
            nextButtonDisabled={currentPage === lastPage}
          />
        ) : null}
      </ContainerComponent>
    </Wrapper>
  );
};

export default OrdersComponent;
