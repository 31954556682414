import { useEffect, useRef } from 'react';

import i18next from 'i18next';
import { RiCloseLine } from "react-icons/ri";

import {
  Wrapper,
  Backdrop,
  Modal,
  CloseButton,
  TextBlock,
  LogoBlock,
  Logo,
  EventText,
  ButtonBlock,
  Button,
} from './style';

const VisaPaymentModal = ({ showPopup, handleClose, data }) => {
  const backdropRef = useRef(null);
  const { t } = i18next;

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (showPopup) {
        document.body.classList.add('hidden');
      } else {
        document.body.classList.remove('hidden');
      }
    };
  }, [showPopup]);

  const handleBackdropClick = event => {
    if (event.target === backdropRef.current) {
      handleClose();
    }
  };

  if (!showPopup) return null;

  return (
    <Wrapper isShow={showPopup}>
      <Backdrop isShow={showPopup} onClick={handleBackdropClick} ref={backdropRef}>
        <Modal isShow={showPopup}>
          <CloseButton onClick={handleClose}>
            <RiCloseLine />
          </CloseButton>

          <TextBlock>
            <LogoBlock>
              <Logo src="/images/visa.png" alt="visa-logo" />
            </LogoBlock>

            <EventText>{t('confirm_actions')}</EventText>

            <form action={data.payUrl} method="POST">
              <input name="appendix" value={data.appendix} hidden />
              <input name="PostLink" value={data.PostLink} hidden />
              <input name="BackLink" value={data.BackLink} hidden />
              <input name="email" value={data.email} hidden />
              <input name="Signed_Order_B64" value={data.Signed_Order_B64} hidden />
              <ButtonBlock>
                <Button type="submit">{t('confirm')}</Button>
              </ButtonBlock>
            </form>
          </TextBlock>
        </Modal>
      </Backdrop>
    </Wrapper>
  );
};

export default VisaPaymentModal;
