import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, $isLoading }) => ({
  display: $isLoading ? "block" : "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 10,
  width: "100%",

  [theme.down("md")]: {
    gridTemplateColumns: "1fr",
  },

  [theme.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

export const LoadingBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  width: "100%",
  minHeight: 50,
}));

export const TariffItem = styled("div")(({ theme }) => ({
  ...theme.paddings(10, 0),
  ...theme.mixins.flexColumn,
  alignItems: "center",
  justifyContent: "center",
  gap: 5,
  border: "2px solid transparent",
  cursor: "pointer",
  width: "100%",

  "&.active": {
    borderColor: "#01A0D4",
  },
}));

export const TariffItemPrice = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontSize: 18,
  color: "#777",
  fontWeight: 700,
  textTransform: "uppercase",
  paddingBottom: 5,
}));

export const TariffItemIcons = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  gap: 3,
  fontSize: 26,
  lineHeight: 1,
  color: "#0b4da2",

  "& .notActive": {
    color: "#aaa",
  },

  "& > div": {
    display: "flex",
    position: "relative",
  },

  "& .hand-luggage-weight": {
    position: "absolute",
    top: 9,
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1,
  },

  "& .baggage-weight": {
    position: "absolute",
    top: 8,
    left: 7,
    width: 12,
    height: 12,
    textAlign: "center",
    backgroundColor: "#fff",
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: 1,
  },
}));

export const TariffItemDetails = styled("div")(({ theme, $isLoading }) => ({
  fontSize: 10,
  color: "#777",
  borderBottom: $isLoading ? "none" : "1px solid #333",
}));

export const TariffItemTitle = styled(TariffItemPrice)(({ theme }) => ({
  paddingBottom: 0,
}));

export const TariffItemClass = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: "#777",
  lineHeight: 1,
  textTransform: "capitalize",
}));

export const TariffItemButton = styled("button")(({ theme }) => ({
  padding: "5px 15px",
  border: "none",
  backgroundColor: "#01A0D4",
  cursor: "pointer",
  fontSize: 14,
  color: "rgb(255, 255, 255)",
  borderRadius: "4px",
}));
