import styled from "styled-components";

export const CheckboxesBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.margins(0, -20),
  gap: 3,
}));

export const CheckboxItem = styled("div")(({ theme }) => ({
  ...theme.paddings(3, 20),

  "&:hover": {
    backgroundColor: "#c7e0f4",
  },
}));
