const palette = {
  headerBg: "rgba(225, 242, 250, 1)",
  footerBg: "#01A0D4",
  searchFormBg: "rgba(0, 0, 0, .5)",
  searchButtonBg: "#01A0D4",
  removeRouteButtonBg: "#ff6663",

  white: "#fff",
};

export default palette;
