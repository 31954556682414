import { useState } from "react";

import i18next from "i18next";

import { phoneMask } from "../../../config";

import { FRIENDLY_COUNTRY, NP, ONLY_RU_COUNTRY, PS, PSP, SR } from "../../../constants";

import { InputComponent } from "../../ui";

import BookingCitizenshipSelectComponent from "../BookingCitizenshipSelectComponent";

import { FormWrapper, InputBlock, Title, Wrapper } from "./style";
import BookingGenderSelectComponent from "../BookingGenderSelectComponent";
import BookingDocumentTypeSelectComponent from "../BookingDocumentTypeSelectComponent";

const BookingPassengerItemComponent = ({ passenger, index, form, flightType }) => {
  const { errors, touched, handleChange, handleBlur, setFieldValue } = form;
  const { t } = i18next;

  const passengersItems = {
    adt: { mainLabel: t("adt_main_label") },
    chd: { mainLabel: t("chd_main_label") },
    ins: { mainLabel: `${t("inf_main_label")} (${t("inf_with_seat_second_label")})` },
    inf: { mainLabel: t("inf_main_label") },
  };

  const allDocuments = [
    { value: PS, label: t("russian_passport") },
    { value: SR, label: t("birth_certificate") },
    { value: PSP, label: t("international_passport") },
    { value: NP, label: t("foreign_document") },
  ];

  const [isShowMiddleName, setIsShowMiddleName] = useState(
    passenger.document_type !== PS && passenger.document_type !== SR
  );
  const [availableDocumentTypes, setAvailableDocumentTypes] = useState(
    passenger.document_items.map(item => allDocuments.find(value => value.value === item))
  );

  const handleChangeCitizenship = code => {
    setFieldValue(`passengers[${index}].document_number`, "");
    setFieldValue(`passengers[${index}].expiration_date`, "");

    const passengerType = passenger.type;

    let actualDocument = PSP;
    let actualAvailableDocumentTypes;

    if (code === "RU") {
      setIsShowMiddleName(true);

      if (flightType === ONLY_RU_COUNTRY) {
        actualAvailableDocumentTypes = allDocuments.filter(doc =>
          passengerType === "adt" ? doc.value !== NP : doc.value !== NP && doc.value !== PS
        );

        setAvailableDocumentTypes(actualAvailableDocumentTypes);

        actualDocument = passengerType === "adt" ? PS : SR;
      } else if (flightType === FRIENDLY_COUNTRY) {
        actualAvailableDocumentTypes = allDocuments.filter(doc =>
          passengerType === "adt" ? doc.value !== NP && doc.value !== SR : doc.value !== PSP
        );

        setAvailableDocumentTypes(actualAvailableDocumentTypes);

        actualDocument = passengerType === "adt" ? PS : PSP;
      } else {
        actualAvailableDocumentTypes = allDocuments.filter(doc => doc.value === PSP);

        setAvailableDocumentTypes(actualAvailableDocumentTypes);
      }

      setFieldValue(`passengers[${index}].document_type`, actualDocument);
    } else {
      setIsShowMiddleName(false);
      setFieldValue(`passengers[${index}].middle_name`, "");

      actualAvailableDocumentTypes = allDocuments.filter(doc => doc.value === NP);
      setAvailableDocumentTypes(actualAvailableDocumentTypes);
      setFieldValue(`passengers[${index}].document_type`, NP);
    }

    setFieldValue(`passengers[${index}].citizenship`, code);
  };

  const handleChangeGender = gender => {
    setFieldValue(`passengers[${index}].gender`, gender);
  };

  const handleChangeDocumentType = documentType => {
    setFieldValue(`passengers[${index}].document_type`, documentType);
  };

  return (
    <Wrapper>
      <Title>{passengersItems[passenger.type].mainLabel}</Title>

      <FormWrapper>
        <InputBlock>
          <InputComponent
            value={passenger.surname}
            touched={touched?.passengers && touched?.passengers[index]?.surname}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.surname &&
              errors?.passengers &&
              errors?.passengers[index]?.surname
            }
            onChange={handleChange(`passengers[${index}].surname`)}
            onBlur={handleBlur(`passengers[${index}].surname`)}
            label={t("surname_as_in_document")}
            normalize={value => value.toUpperCase()}
            success={
              touched?.passengers &&
              touched?.passengers[index]?.surname &&
              (!errors?.passengers || !errors?.passengers[index]?.surname) &&
              passenger.surname
            }
            required
          />
        </InputBlock>

        <InputBlock>
          <InputComponent
            value={passenger.name}
            touched={touched?.passengers && touched?.passengers[index]?.name}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.name &&
              errors?.passengers &&
              errors?.passengers[index]?.name
            }
            onChange={handleChange(`passengers[${index}].name`)}
            onBlur={handleBlur(`passengers[${index}].name`)}
            label={t("name_as_in_document")}
            normalize={value => value.toUpperCase()}
            success={
              touched?.passengers &&
              touched?.passengers[index]?.name &&
              (!errors?.passengers || !errors?.passengers[index]?.name) &&
              passenger.name
            }
            required
          />
        </InputBlock>

        <InputBlock removeOnMobile={isShowMiddleName ? 0 : 1}>
          {isShowMiddleName && (
            <InputComponent
              value={passenger.middle_name}
              touched={touched?.passengers && touched?.passengers[index]?.middle_name}
              error={
                touched?.passengers &&
                touched?.passengers[index]?.middle_name &&
                errors?.passengers &&
                errors?.passengers[index]?.middle_name
              }
              onChange={handleChange(`passengers[${index}].middle_name`)}
              onBlur={handleBlur(`passengers[${index}].middle_name`)}
              label={t("middlename_as_in_document")}
              normalize={value => value.toUpperCase()}
              success={
                touched?.passengers &&
                touched?.passengers[index]?.middle_name &&
                (!errors?.passengers || !errors?.passengers[index]?.middle_name) &&
                passenger.middle_name
              }
              required
            />
          )}
        </InputBlock>

        <InputBlock>
          <BookingCitizenshipSelectComponent
            touched={touched?.passengers && touched?.passengers[index]?.citizenship}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.citizenship &&
              errors?.passengers &&
              errors?.passengers[index]?.citizenship
            }
            success={
              touched?.passengers &&
              touched?.passengers[index]?.citizenship &&
              (!errors?.passengers || !errors?.passengers[index]?.citizenship) &&
              passenger.citizenship
            }
            onChange={handleChangeCitizenship}
            onBlur={handleBlur(`passengers[${index}].citizenship`)}
            clearFieldError={() => form.setFieldError(`passengers[${index}].citizenship`, "")}
          />
        </InputBlock>

        <InputBlock>
          <BookingGenderSelectComponent
            touched={touched?.passengers && touched?.passengers[index]?.gender}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.gender &&
              errors?.passengers &&
              errors?.passengers[index]?.gender
            }
            success={
              touched?.passengers &&
              touched?.passengers[index]?.gender &&
              (!errors?.passengers || !errors?.passengers[index]?.gender) &&
              passenger.gender
            }
            onChange={handleChangeGender}
            onBlur={handleBlur(`passengers[${index}].gender`)}
            clearFieldError={() => form.setFieldError(`passengers[${index}].gender`, "")}
          />
        </InputBlock>

        <InputBlock>
          <InputComponent
            value={passenger.date_of_birth}
            touched={touched?.passengers && touched?.passengers[index]?.date_of_birth}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.date_of_birth &&
              errors?.passengers &&
              errors?.passengers[index]?.date_of_birth
            }
            success={
              touched?.passengers &&
              touched?.passengers[index]?.date_of_birth &&
              (!errors?.passengers || !errors?.passengers[index]?.date_of_birth) &&
              passenger.date_of_birth
            }
            onChange={handleChange(`passengers[${index}].date_of_birth`)}
            onBlur={handleBlur(`passengers[${index}].date_of_birth`)}
            label={t("date_of_birth")}
            mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
            required
          />
        </InputBlock>

        <InputBlock>
          <BookingDocumentTypeSelectComponent
            availableDocumentTypes={availableDocumentTypes}
            documentValue={passenger.document_type}
            touched={touched?.passengers && touched?.passengers[index]?.document_type}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.document_type &&
              errors?.passengers &&
              errors?.passengers[index]?.document_type
            }
            success={
              touched?.passengers &&
              touched?.passengers[index]?.document_type &&
              (!errors?.passengers || !errors?.passengers[index]?.document_type) &&
              passenger.document_type
            }
            onChange={handleChangeDocumentType}
            onBlur={handleBlur(`passengers[${index}].document_type`)}
          />
        </InputBlock>

        <InputBlock>
          <InputComponent
            value={passenger.document_number}
            touched={touched?.passengers && touched?.passengers[index]?.document_number}
            error={
              touched?.passengers &&
              touched?.passengers[index]?.document_number &&
              errors?.passengers &&
              errors?.passengers[index]?.document_number
            }
            success={
              touched?.passengers &&
              touched?.passengers[index]?.document_number &&
              (!errors?.passengers || !errors?.passengers[index]?.document_number) &&
              passenger.document_number
            }
            onChange={handleChange(`passengers[${index}].document_number`)}
            onBlur={handleBlur(`passengers[${index}].document_number`)}
            label={t("series_and_number")}
            required
          />
        </InputBlock>

        <InputBlock removeOnMobile={passenger.document_type !== PS && passenger.document_type !== SR ? 0 : 1}>
          {passenger.document_type !== PS && passenger.document_type !== SR && (
            <InputComponent
              value={passenger.expiration_date}
              touched={touched?.passengers && touched?.passengers[index]?.expiration_date}
              error={
                touched?.passengers &&
                touched?.passengers[index]?.expiration_date &&
                errors?.passengers &&
                errors?.passengers[index]?.expiration_date
              }
              success={
                touched?.passengers &&
                touched?.passengers[index]?.expiration_date &&
                (!errors?.passengers || !errors?.passengers[index]?.expiration_date) &&
                passenger.expiration_date
              }
              onChange={handleChange(`passengers[${index}].expiration_date`)}
              onBlur={handleBlur(`passengers[${index}].expiration_date`)}
              label={t("valid_until")}
              mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
              required
            />
          )}
        </InputBlock>

        <InputBlock removeOnMobile={passenger.type === "adt" ? 0 : 1}>
          {passenger.type === "adt" && (
            <InputComponent
              value={passenger.email}
              touched={touched?.passengers && touched?.passengers[index]?.email}
              error={
                touched?.passengers &&
                touched?.passengers[index]?.email &&
                errors?.passengers &&
                errors?.passengers[index]?.email
              }
              success={
                touched?.passengers &&
                touched?.passengers[index]?.email &&
                (!errors?.passengers || !errors?.passengers[index]?.email) &&
                passenger.email
              }
              onChange={handleChange(`passengers[${index}].email`)}
              onBlur={handleBlur(`passengers[${index}].email`)}
              placeholder="example@mail.com"
              label={t("email")}
              required
            />
          )}
        </InputBlock>

        <InputBlock removeOnMobile={passenger.type === "adt" ? 0 : 1}>
          {passenger.type === "adt" && (
            <InputComponent
              value={passenger.phone}
              touched={touched?.passengers && touched?.passengers[index]?.phone}
              error={
                touched?.passengers &&
                touched?.passengers[index]?.phone &&
                errors?.passengers &&
                errors?.passengers[index]?.phone
              }
              success={
                touched?.passengers &&
                touched?.passengers[index]?.phone &&
                (!errors?.passengers || !errors?.passengers[index]?.phone) &&
                passenger.phone
              }
              onChange={handleChange(`passengers[${index}].phone`)}
              onBlur={handleBlur(`passengers[${index}].phone`)}
              label={t("phone_number")}
              mask={phoneMask}
              required
            />
          )}
        </InputBlock>

        <InputBlock removeOnMobile />
      </FormWrapper>
    </Wrapper>
  );
};

export default BookingPassengerItemComponent;
