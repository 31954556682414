import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";

import { setFilterParams } from "../../../store/resultFiltersSlice";

import { BY_MAX_PRICE, BY_MIN_PRICE } from "../../../constants";

import FilterGroupComponent from "../FilterGroupComponent";
import { SelectComponent } from "../../ui";

const FilterSortingComponent = () => {
  const { t } = i18next;
  const {
    filterParams: {
      sorting: { value },
    },
  } = useSelector(state => state.resultFilters);
  const dispatch = useDispatch();

  const handleSelect = newValue => {
    dispatch(
      setFilterParams({
        type: "sorting",
        values: {
          isActive: newValue === BY_MAX_PRICE,
          value: newValue,
        },
      })
    );
  };

  return (
    <FilterGroupComponent title={t("sorting")} isOpenable={false}>
      <SelectComponent
        value={value}
        options={[
          { value: BY_MIN_PRICE, label: t("label_price_by_min") },
          { value: BY_MAX_PRICE, label: t("label_price_by_max") },
        ]}
        onSelect={handleSelect}
      />
    </FilterGroupComponent>
  );
};

export default FilterSortingComponent;
