import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(15),
  gap: 10,
  width: 320,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",

  [theme.down("xl")]: {
    width: 270,
  },

  [theme.down("lg")]: {
    width: "100%",
  },
}));

export const PriceItem = styled("div")(({ theme, $isSmallText }) => ({
  ...theme.mixins.flexCenterBetween,
  fontSize: $isSmallText ? 16 : 20,
  color: "#4a4a4a",

  [theme.down("xs")]: {
    fontSize: $isSmallText ? 14 : 18,
  },
}));

export const LineDivider = styled("div")(({ theme }) => ({
  width: "100%",
  height: 1,
  backgroundColor: "#ccc",
}));
