import { BsCheck } from "react-icons/bs";

import { CheckboxBlock, CheckboxInput, Label, Wrapper } from "./style";

const Checkbox = ({ value = false, onChange, label, hasLabel = true }) => {
  return (
    <Wrapper>
      <CheckboxInput type="checkbox" onChange={onChange} checked={value} />

      <Label>
        <CheckboxBlock checked={value} $hasLabel={hasLabel}>{value && <BsCheck />}</CheckboxBlock>
        {label}
      </Label>
    </Wrapper>
  );
};

export default Checkbox;
