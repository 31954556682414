import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  width: 320,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",

  [theme.down("xl")]: {
    width: 270,
  },

  [theme.down("lg")]: {
    width: "100%",
  },
}));

export const FiltersButton = styled("button")(({ theme }) => ({
  position: "relative",
  borderRadius: 0,
  width: "100%",
  backgroundColor: "#fff",
  border: "1px solid #9e9e9e",
  color: "#333",
  margin: 0,
  height: 32,
  fontSize: 14,
  cursor: "pointer",

  [theme.up("lg")]: {
    display: "none",
  },
}));

export const IconBlock = styled("span")(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 5,
  display: "flex",
  fontSize: 20,
  color: "#333",
  transform: "rotate(0deg)",
  transition: "all .3s ease",

  "&.active": {
    transform: "rotate(180deg)",
  },
}));

export const FiltersBlock = styled("div")(({ theme }) => ({
  height: "auto",
  overflow: "hidden",

  [theme.down("lg")]: {
    height: 0,

    "&.active": {
      height: "auto",
    },
  },
}));
