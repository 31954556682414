import { withTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { LuAlertCircle, LuCheckCircle, LuXCircle } from "react-icons/lu";

import { FooterComponent } from "../footer";
import { HeaderComponent } from "../header";
import { SearchComponent } from "../search";

import { Wrapper } from "./style";

const Icon = ({ type }) => {
  if (type === "success") {
    return <LuCheckCircle />;
  }

  if (type === "warning") {
    return <LuAlertCircle />;
  }

  if (type === "error") {
    return <LuXCircle />;
  }

  return null;
};

const Layout = () => (
  <>
    <Wrapper>
      <HeaderComponent />
      <SearchComponent />
      <Outlet />
    </Wrapper>

    <FooterComponent />

    <ToastContainer
      hideProgressBar
      icon={Icon}
      theme="colored"
      closeButton={false}
      position="top-right"
      autoClose={5000}
    />
  </>
);

export default withTranslation()(Layout);
