import { InputGroup, Label, InputBlock, InputElement, Error, MaskedInputElement } from "./style";

const Input = ({
  value,
  onChange = () => null,
  onBlur = () => null,
  onClick = () => null,
  onFocus = () => null,
  type = "text",
  placeholder = null,
  required = false,
  mask = null,
  normalize = null,
  label = null,
  touched = false,
  error = null,
  success = false,
  disabled,
  ...rest
}) => {
  const handleChange = e => {
    if (normalize) {
      onChange(normalize(e.target.value));
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <InputGroup>
      <InputBlock isError={touched && error} isSuccess={success}>
        {label && (
          <Label>
            {label} {required && <span>*</span>}
          </Label>
        )}
        {mask ? (
          <MaskedInputElement
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            disabled={disabled}
            mask={mask}
            {...rest}
          />
        ) : (
          <InputElement
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            disabled={disabled}
            {...rest}
          />
        )}
      </InputBlock>
      {touched && error && <Error>{error}</Error>}
    </InputGroup>
  );
};

export default Input;
