import { useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/auth";
import { getOrders } from "../../protocols/rest/getOrders";
import { setShowSearch } from "../../store/appConfigsSlice";

import OrdersComponent from "../../components/orders/OrdersComponent";

const limit = 10;

const OrdersPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const [ordersData, setOrdersData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const request = useRef(async (params) => {
    try {
      const result = await getOrders({ ...params, limit });
      setOrdersData(result?.data);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    dispatch(setShowSearch(false));
    request.current();
  }, []);

  if (!isAuth) {
    navigate("/");
    return null;
  }

  return (
    <OrdersComponent
      isLoading={isLoading}
      orders={ordersData?.data}
      included={ordersData?.included}
      page={ordersData?.current_page}
      lastPage={ordersData?.last_page}
      request={request.current}
    />
  );
};

export default withTranslation()(OrdersPage);
