import { createSlice } from "@reduxjs/toolkit";

import { BY_MIN_PRICE } from "../constants";
import { handleFilterParamsChange } from "../utils";

const initialState = {
  filterParams: {
    baggage: {
      isActive: false,
      values: {
        withBaggage: false,
        withoutBaggage: false,
      },
    },

    transfers: {
      isActive: false,
      values: {
        withoutTransfers: false,
        oneTransfer: false,
        manyTransfers: false,
      },
    },

    sorting: {
      isActive: false,
      value: BY_MIN_PRICE,
    },

    airlines: {
      isActive: false,
      values: [],
    },

    flightNumbers: {
      isActive: false,
      values: [],
    },

    price: {
      isActive: false,
      values: {
        min: 0,
        max: 100,
      },
    },

    airports: {
      isActive: false,
      values: [], // { routeIndex: 0, departures: ['LED', 'DYU'], arrivals: ['LED', 'DME'] }
    },

    duration: {
      isActive: false,
      values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
    },

    departureTime: {
      isActive: false,
      values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
    },

    arrivalTime: {
      isActive: false,
      values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
    },
  },

  filtersData: {
    airlinesData: [],
    priceRange: {
      min: 0,
      max: 100,
    },
    durationRange: {
      min: null,
      max: null,
    },
    routesData: [],
  },
};

export const resultFiltersSlice = createSlice({
  name: "resultFilters",
  initialState,
  reducers: {
    changePriceRange: (state, action) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        price: {
          ...state.price,
          isActive:
            action.payload.min > state.filtersData.priceRange.min ||
            action.payload.max < state.filtersData.priceRange.max,
          values: action.payload,
        },
      },
    }),
    changeSorting: (state, action) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        sorting: {
          isActive: action.payload !== BY_MIN_PRICE,
          value: action.payload,
        },
      },
    }),
    changeTransfers: (state, action) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        transfers: {
          isActive: action.payload?.withoutTransfers || action.payload?.oneTransfer || action.payload?.manyTransfers,
          values: {
            ...state.filterParams.transfers.values,
            ...action.payload,
          },
        },
      },
    }),
    changeBaggage: (state, action) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        baggage: {
          isActive: action.payload?.withBaggage || action.payload?.withoutBaggage,
          values: {
            ...state.filterParams.baggage.values,
            ...action.payload,
          },
        },
      },
    }),
    changeAirlines: (state, action) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        airlines: {
          isActive: action.payload.length,
          values: action.payload,
        },
      },
    }),
    setFiltersData: (state, action) => ({
      ...state,
      filtersData: {
        ...state.filtersData,
        ...action.payload,
      },
    }),
    setAllFilterParams: (state, action) => ({
      ...state,
      filterParams: {
        ...state.filterParams,
        ...action.payload,
      },
    }),
    setFilterParams: (state, action) => {
      handleFilterParamsChange(action.payload);

      return {
        ...state,
        filterParams: { ...state.filterParams, [action.payload.type]: action.payload.values },
      };
    },
  },
});

export const {
  changePriceRange,
  changeSorting,
  changeTransfers,
  changeBaggage,
  changeAirlines,
  setFiltersData,
  setAllFilterParams,
  setFilterParams,
} = resultFiltersSlice.actions;

export default resultFiltersSlice.reducer;
