import _ from "lodash";
import moment from "moment";

import { webTransferTimeFormat } from "../config";

import { parserLangCode } from "./common";

export const getCityString = (included, language, cityCode, airportCode) => {
  const { city, airport } = included;

  const cityName = city[cityCode].name[language];
  const airportName = airport[airportCode].name[language];

  if (cityName === airportName) {
    return cityName;
  } else {
    return `${cityName}, ${airportName}`;
  }
};

export const getFreeSeatsLabel = (freeSeats, one, four, nine) => {
  if (freeSeats === 1) return `${freeSeats} ${one}`;

  if (freeSeats > 1 && freeSeats < 5) return `${freeSeats} ${four}`;

  if (freeSeats > 4 && freeSeats < 9) return `${freeSeats} ${nine}`;

  return `9+ ${nine}`;
};

export const getServiceClass = serviceClass => {
  const { code, name } = serviceClass;

  if (!_.isEmpty(code)) {
    return `${name} (${code})`;
  }

  return name;
};

export const getTransferTime = (route, index, hoursShortText, minutesShortText) => {
  const prevDate = route.segments[index - 1]?.arrival.time;
  const actualDate = route.segments[index]?.departure.time;
  const prevDateUnix = moment(prevDate, webTransferTimeFormat).unix();
  const actualDateUnix = moment(actualDate, webTransferTimeFormat).unix();
  const unixDifference = actualDateUnix - prevDateUnix;
  const hours = Math.trunc(unixDifference / 3600);
  const minutes = Math.floor((unixDifference - hours * 3600) / 60);

  return `${hours} ${hoursShortText} ${minutes} ${minutesShortText}`;
};

export const checkIsNightFlightAndIsChangeAirport = (route, currentSegmentIndex) => {
  if (currentSegmentIndex === 0) {
    return [false, false];
  }

  let isNightTransfer = false;
  let isChangeAirport = false;

  if (
    route.segments[currentSegmentIndex].departure.airport !== route.segments[currentSegmentIndex - 1].arrival.airport
  ) {
    isChangeAirport = true;
  }

  const timeArray = route.segments[currentSegmentIndex - 1].arrival.time.split(" ");

  const arrivalTime = moment(timeArray[1], "hh:mm"),
    beforeTime = moment("06:00", "hh:mm"),
    afterTime = moment("18:00", "hh:mm");

  if (arrivalTime.isSameOrBefore(beforeTime) || arrivalTime.isSameOrAfter(afterTime)) {
    isNightTransfer = true;
  }

  return [isChangeAirport, isNightTransfer];
};

const getMomentObject = (data, language) => {
  return moment(data, webTransferTimeFormat).locale(parserLangCode(language));
};

export const getDayByFormat = (data, format, language) => {
  const momentObj = getMomentObject(data, language);

  return momentObj.format(format).replace(".", "");
};
