import { useState } from "react";

import i18next from "i18next";

import { InputComponent, DropdownComponent } from "../../ui";
import { CountryItem, CountryLabel, Wrapper } from "./style";

const BookingGenderSelectComponent = ({
  touched,
  error,
  success,
  onChange,
  onBlur,
  clearFieldError,
}) => {
  const { t } = i18next;
  const [value, setValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const genderItems = [
    { value: "M", label: t('male') },
    { value: "F", label: t('female') },
];

  const handleOnSelect = gender => {
    setValue(gender.label);
    onChange(gender.value);
    setShowDropdown(false);
  };

  const renderGenders = () => {
    return genderItems.map((gender, index) => {
      return (
        <CountryItem key={index} onClick={() => handleOnSelect(gender)}>
          <CountryLabel>{gender.label}</CountryLabel>
        </CountryItem>
      );
    });
  };

  return (
    <Wrapper>
      <InputComponent
        value={value}
        label={t("gender")}
        placeholder={t("choose_gender")}
        touched={touched}
        error={!showDropdown ? error : null}
        onBlur={onBlur}
        onClick={() => setShowDropdown(true)}
        onFocus={clearFieldError}
        success={success}
        readOnly
        required
      />

      {showDropdown && (
        <DropdownComponent show={showDropdown} onClickOutside={() => setShowDropdown(false)} noPadding>
          {renderGenders()}
        </DropdownComponent>
      )}
    </Wrapper>
  );
};

export default BookingGenderSelectComponent;
