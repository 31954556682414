import classNames from "classnames";
import { BsChevronRight } from "react-icons/bs";

import { BodyBlock, HeaderBlock, IconBlock, TitleBlock, Wrapper } from "./style";
import { useToggle } from "../../../hooks";

const FilterGroupComponent = ({ children, title, isOpen, isOpenable = true }) => {
  const { isTrue, toggle } = useToggle(isOpen);

  const handleToggle = () => {
    if (isOpenable) {
      toggle();
    }
  };

  return (
    <Wrapper $isOpenable={isOpenable}>
      {title && (
        <HeaderBlock $hasPaddingTop={isOpenable} onClick={handleToggle}>
          {isOpenable && (
            <IconBlock className={classNames({ active: isTrue })}>
              <BsChevronRight />
            </IconBlock>
          )}

          <TitleBlock>{title}</TitleBlock>
        </HeaderBlock>
      )}

      <BodyBlock className={classNames({ active: !isOpenable || isTrue, paddingBottom: isTrue })}>{children}</BodyBlock>
    </Wrapper>
  );
};

export default FilterGroupComponent;
