import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "UZS",
  availableCurrencies: ["UZS"],
  language: "ru",
  availableLanguages: ["ru"],
  showSearch: true,
  searchBlockCompact: false,
};

export const appConfigsSlice = createSlice({
  name: "appConfigs",
  initialState,
  reducers: {
    setAppConfigs: (state, action) => ({ ...state, ...action.payload }),
    setShowSearch: (state, action) => ({ ...state, showSearch: action.payload }),
    setSearchBlockCompact: (state, action) => ({ ...state, searchBlockCompact: action.payload }),
  },
});

export const { setAppConfigs, setShowSearch, setSearchBlockCompact } = appConfigsSlice.actions;

export default appConfigsSlice.reducer;
