import { useEffect, useState } from "react";

import i18next from "i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

import { getPaymentSystems, rechargeBalance } from "../../../protocols";

import { useAuth } from "../../../context/auth";

import { CheckboxComponent, ContainerComponent, DotsLoadingComponent, InputComponent } from "../../ui";

import {
  Body,
  Button,
  Content,
  CurrentBalance,
  Header,
  InputBlock,
  PaymentMethodCheckboxBlock,
  PaymentMethodDescription,
  PaymentMethodItem,
  PaymentMethodLogo,
  PaymentMethodsBlock,
  Subtitle,
  Title,
  Wrapper,
} from "./style";

const BalanceRechargeComponent = () => {
  const { language, t } = i18next;

  const { user } = useAuth();
  const { currency } = useSelector(state => state.appConfigs);

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  useEffect(() => {
    const request = async () => {
      const result = await getPaymentSystems("balance");
      if (result?.length) {
        setPaymentMethods(result);
        setPaymentMethod(result[0]?.id);
      }
    };

    request();
  }, []);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError(t("only_numbers"))
      .test("minAmount", `${t("min_number")} 1000 ${user?.currency || currency}`, val => val >= 1000)
      .test("not_number", t("only_numbers"), value => /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(value))
      .required(t("required")),
  });

  const chargeBalance = async values => {
    setIsPaymentLoading(true);

    await rechargeBalance({
      amount: values.amount,
      payment_systems_id: paymentMethod,
      return_url: window.location.origin,
      fail_url: window.location.origin,
    }).then(res => {
      const { pay_url } = res.data || {};

      if (pay_url) {
        window.location.href = pay_url;
      }
    }).finally(() => {
      setIsPaymentLoading(false);
    });
};

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      amount: null,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: chargeBalance,
  });

  const handleOnClickItem = (id) => {
    if (id !== paymentMethod) {
      setPaymentMethod(id);
    }
  };

  return (
    <Wrapper>
      <ContainerComponent>
        <Title>{t("recharging_balance")}</Title>
        <Content>
          <Header>
            <CurrentBalance>{t("current_balance")}: <span>{user.balance || 0} {user.currency || currency}</span></CurrentBalance>
          </Header>
          <Body>
            <Subtitle>{t("deposit_method")}</Subtitle>

            <PaymentMethodsBlock>
              {paymentMethods?.map(method => (
                <PaymentMethodItem
                  key={method.id}
                  $isActive={method.id === paymentMethod}
                  onClick={() => handleOnClickItem(method.id)}
                >
                  <PaymentMethodCheckboxBlock>
                    <CheckboxComponent value={method.id === paymentMethod} hasLabel={false} />
                  </PaymentMethodCheckboxBlock>
                  <PaymentMethodLogo src={method.logo} alt="payment-method-logo" />
                  {method.description[language] && (
                    <PaymentMethodDescription $isActive={method.id === paymentMethod}>
                      {method.description[language]}
                    </PaymentMethodDescription>
                  )}
                </PaymentMethodItem>
              ))}
            </PaymentMethodsBlock>

            <Subtitle>
              {t("recharging_amount")}
            </Subtitle>

            <InputBlock>
              <InputComponent
                value={values.amount}
                touched={touched?.amount}
                error={touched?.amount && errors?.amount}
                onChange={(value) => setFieldValue("amount", value)}
                onBlur={handleBlur("amount")}
                label={t("amount")}
                success={touched?.amount && !errors?.amount && values.amount}
                placeholder={10000}
                required
              />
            </InputBlock>

            <InputBlock>
              <Button onClick={handleSubmit} type="submit">
                {t("recharge")}
                {isPaymentLoading ? (
                  <div>
                    <DotsLoadingComponent color="#01A0D4" />
                  </div>
                ) : null}
              </Button>
            </InputBlock>
          </Body>
        </Content>
      </ContainerComponent>
    </Wrapper>
  )
};

export default BalanceRechargeComponent;
