import { ContentType, request } from "../api";
import { baseParams } from "../common";

export const getUserInfo = async (params = {}) => request({
  path: "/client-get",
  secure: true,
  method: "GET",
  query: baseParams,
  type: ContentType.Json,
  format: "json",
  ...params,
});
