import { useEffect } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setShowSearch } from "../../store/appConfigsSlice";

import { useAuth } from "../../context/auth";

import LoginForm from "../../components/auth/LoginForm";

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  useEffect(() => {
    dispatch(setShowSearch(false));
  }, []);

  if (isAuth) {
    navigate("/");
    return null;
  }

  return (
    <LoginForm />
  )
};

export default withTranslation()(AuthPage);
