import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")(({theme}) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	
	width: "100%",
	maxWidth: 900,
	
	margin: "40px auto",

	"& > div": {
		fontSize: "15px",
		lineHeight: "24px",
	},

	[theme.down("md")]: {
		maxWidth: 700,
	},

	[theme.down("sm")]: {
		maxWidth: 500,
	},

	[theme.down("xs")]: {
		width: "100%",
		padding: "0px 20px",
	},
}));

export const RulesOfUseTitle = styled("h4")(({theme}) => ({
	fontSize: 20,
	fontWeight: 700,

	textTransform: "uppercase",
}));

export const RulesOfUseItem = styled("div")(({theme}) => ({

}));

export const RulesOfUseItemTitle = styled("h4")(({theme}) => ({
	marginTop: 32,
	marginBottom: 16,
	textTransform: "uppercase",
}));

export const RulesOfUseContent = styled("p")(({theme}) => ({

}));

export const RulesOfUseContentList = styled("ul")(({theme}) => ({
	marginLeft: 14,
}));

export const LinkItem = styled((props) => (
    <Link {...props} />
))(({ theme }) => ({
	color: "#01A0D4",
	transition: "all .5s ease-out",

	"&:hover": {
		textDecoration: "underline",
	},
}));