import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
// import * as ymaps3 from 'imaps3';
import i18next from "i18next";
import {Content, Info, List, MapLocation, Subtitles, Title, Wrapper, WrapperInner} from "./style";


export const ContactsComponent = () => {
	
	const {t} = i18next;
	

	return (
		<Wrapper>
			<Title>
				{t("contact_information")}
			</Title>
			<WrapperInner>
				<Info>
					<div>
						<Subtitles>
							{t("location")}
						</Subtitles>
						<Content>
							140100, Узбекистан, Самарканд,
							ул. Абдурахмана Жомий, 35
						</Content>
					</div>
					
					<div>
						<Subtitles>
							{t("phone_number")}
						</Subtitles>
						<Content>
							+998 90 926 42 11 (har kuni 9:00 dan 23:00 gacha)
						</Content>
					</div>
					
					<div>
						<Subtitles>
							{t("email")}
						</Subtitles>
						<Content>
							<a href="mailto:biletbor.me@gmail.com">biletbor.me@gmail.com</a>
						</Content>
					</div>
					
					<div>
						<Subtitles>
							{t("workH")}
						</Subtitles>
						<Content>
							Biz har kuni soat 09:00 dan 23:00 gacha aloqadamiz.
						</Content>
					</div>
					
					<div>
						<Subtitles>
							{t("weekends")}
						</Subtitles>
						<List>
							<li>{t("weekends1")}</li>
							<li>{t("weekends2")}</li>
							<li>{t("weekends3")}</li>
							<li>{t("weekends4")}</li>
							<li>{t("weekends5")}</li>
							<li>{t("weekends6")}</li>
							<li>{t("weekends7")}</li>
						</List>
					</div>
				</Info>

				<MapLocation>
					<Subtitles>
						{t("location_map")}
					</Subtitles>
					<YMaps
						preload={true}
						query={{
							lang: 'ru_RU',
							// ns: "use-load-option",
							// load: "Map, Placemark, control.ZoomControl, control.FullscreenControl, getObject.addon.balloon"
					}}
					>
						<Map
							width={"350px"}
							height={"350px"}
							defaultState={{
								center: [39.656418, 66.953953],
								zoom: 15 ,
								controls: ["zoomControl", "fullscreenControl"],
							}}
							modules={["control.ZoomControl", "control.FullscreenControl"]}
						>
							<Placemark
								// modules={["getObject.addon.balloon"]}
								defaultGeometry={[39.656418, 66.953953]}
								properties={{
									balloonContentBody:
										"This is balloon loaded by the Yandex.Maps API module system"
								}}
							/>
						</Map>
					</YMaps>
				</MapLocation>
			</WrapperInner>
		</Wrapper>
	)
};

