import { useDispatch, useSelector } from "react-redux";

import { setFilterParams } from "../../../store/resultFiltersSlice";

import FilterGroupComponent from "../FilterGroupComponent";
import { CheckboxComponent } from "../../ui";

import { CheckboxItem, CheckboxesBlock, RouteBlock, RouteCities } from "./style";
import i18next from "i18next";

const FilterAirportsComponent = () => {
  const { language, t } = i18next;
  const {
    filterParams: {
      airports: { values },
    },
    filtersData,
  } = useSelector(state => state.resultFilters);
  const { included } = useSelector(state => state.resultData);
  const { routesData } = filtersData;
  const dispatch = useDispatch();

  const handleChangeDeparture = (routeIndex, value) => {
    let params = [...values];
    const index = params.findIndex(param => param.routeIndex === routeIndex);

    if (index > -1) {
      if (params[index].departures.includes(value)) {
        params = params.map((param, ind) =>
          ind === index ? { ...param, departures: param.departures.filter(item => item !== value) } : param
        );

        if (params[index].departures.length === 0 && params[index].arrivals.length === 0) {
          params = params.filter((_, ind) => ind !== index);
        }
      } else {
        params = params.map((param, ind) =>
          ind === index ? { ...param, departures: [...param.departures, value] } : param
        );
      }
    } else {
      params = [...params, { routeIndex, departures: [value], arrivals: [] }];
    }

    dispatch(
      setFilterParams({
        type: "airports",
        values: {
          isActive: params.length > 0,
          values: params,
        },
      })
    );
  };

  const handleChangeArrival = (routeIndex, value) => {
    let params = [...values];
    const index = params.findIndex(param => param.routeIndex === routeIndex);

    if (index > -1) {
      if (params[index].arrivals.includes(value)) {
        params = params.map((param, ind) =>
          ind === index ? { ...param, arrivals: param.arrivals.filter(item => item !== value) } : param
        );

        if (params[index].departures.length === 0 && params[index].arrivals.length === 0) {
          params = params.filter((_, ind) => ind !== index);
        }
      } else {
        params = params.map((param, ind) =>
          ind === index ? { ...param, arrivals: [...param.arrivals, value] } : param
        );
      }
    } else {
      params = [...params, { routeIndex, departures: [], arrivals: [value] }];
    }

    dispatch(
      setFilterParams({
        type: "airports",
        values: {
          isActive: params.length > 0,
          values: params,
        },
      })
    );
  };

  const renderRoute = route => {
    return (
      <RouteBlock key={`${route.routeIndex}-${route.departureCityCode}-${route.arrivalCityCode}`}>
        <RouteCities>
          {included.city[route.departureCityCode].name[language]} →{" "}
          {included.city[route.arrivalCityCode].name[language]}
        </RouteCities>

        <RouteCities>{t("departure")}</RouteCities>

        <CheckboxesBlock>
          {route.departureAirports.map(airport => (
            <CheckboxItem key={airport}>
              <CheckboxComponent
                label={included.airport[airport].name[language]}
                value={values.findIndex(x => x.routeIndex === route.index && x.departures.includes(airport)) > -1}
                onChange={value => handleChangeDeparture(route.index, airport)}
              />
            </CheckboxItem>
          ))}
        </CheckboxesBlock>

        <RouteCities>{t("arrival")}</RouteCities>

        <CheckboxesBlock>
          {route.arrivalAirports.map(airport => (
            <CheckboxItem key={airport}>
              <CheckboxComponent
                label={included.airport[airport].name[language]}
                value={values.findIndex(x => x.routeIndex === route.index && x.arrivals.includes(airport)) > -1}
                onChange={value => handleChangeArrival(route.index, airport)}
              />
            </CheckboxItem>
          ))}
        </CheckboxesBlock>
      </RouteBlock>
    );
  };

  return <FilterGroupComponent title={t("airports")}>{routesData.map(route => renderRoute(route))}</FilterGroupComponent>;
};

export default FilterAirportsComponent;
