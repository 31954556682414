import breakpoints from "./breakpoints";
import functional from "./functional";
import mixins from "./mixins";
import palette from "./palette";

const theme = {
  ...functional,
  ...breakpoints,
  mixins,
  palette,
};

export default theme;
