import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import appConfigsSlice from "./appConfigsSlice";
import resultDataSlice from "./resultDataSlice";
import resultFiltersSlice from "./resultFiltersSlice";
import searchParamsReducer from "./searchParamsSlice";
import prebookSlice from "./prebookSlice";
import orderSlice from "./orderSlice";

export const store = configureStore({
  reducer: {
    appConfigs: appConfigsSlice,
    order: orderSlice,
    prebook: prebookSlice,
    resultData: resultDataSlice,
    resultFilters: resultFiltersSlice,
    searchParams: searchParamsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
