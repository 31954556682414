import i18next from "i18next";
import { Helmet } from "react-helmet";

import { seo } from "../../seo";

const SeoTags = ({ pageKey }) => {
  const { language } = i18next;

  const pageTitle = seo.titles[language][pageKey] || "";
  
  const pageMetaDescription = seo.metaDescriptions[language][pageKey] || "";
  const pageMetaKeywords = seo.metaKeywords[language][pageKey] || "";
  const pageMetaTitle = seo.metaTitles[language][pageKey] || "";

  const pageMetaOgObject = seo.metaOgObject[language][pageKey] || {};

  return (
    <Helmet>
      {!!pageTitle && <title>{pageTitle}</title>}

      {!!pageMetaDescription && <meta name="description" content={pageMetaDescription} />}
      {!!pageMetaKeywords && <meta name="keywords" content={pageMetaKeywords} />}
      {!!pageMetaTitle && <meta name="title" content={pageMetaTitle} />}

      {!!pageMetaOgObject?.description && <meta property="og:description" content={pageMetaOgObject.description} />}
      {!!pageMetaOgObject?.image && <meta property="og:image" content={pageMetaOgObject.image} />}
      {!!pageMetaOgObject?.site_name && <meta property="og:site_name" content={pageMetaOgObject.site_name} />}
      {!!pageMetaOgObject?.title && <meta property="og:title" content={pageMetaOgObject.title} />}
      {!!pageMetaOgObject?.type && <meta property="og:type" content={pageMetaOgObject.type} />}
      {!!pageMetaOgObject?.url && <meta property="og:url" content={pageMetaOgObject.url} />}
    </Helmet>
  );
};

export default SeoTags;
