export const normalizePrice = price => {
  price += "";
  let x = price.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  let rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1 $2");
  }

  return x1 + x2;
};

export const parserLangCode = language => {
  return language === "tj" ? "tg" : language;
};

export const paramsToObject = entries => {
  const result = {};

  for (const [key, value] of entries) {
    result[key] = value;
  }

  return result;
};

export const paramsToArrayObject = entries => {
  const result = [];

  for (const [key, value] of entries) {
    result.push({ key, value });
  }

  return result;
};

export const objectsToParams = objects => {
  if (objects.length > 0) {
    let params = "";

    objects.forEach((value, index) => {
      if (index === 0) {
        params = `?${value.key}=${value.value}`;
      } else {
        params = `${params}&${value.key}=${value.value}`;
      }
    });

    return params;
  } else {
    return window.location.pathname;
  }
};

export const noun = (count, first, second, third) => {
  if (+count === 1) {
    return first;
  }

  if (+count > 1 && +count < 5) {
    return second;
  }

  if (+count === 0 || +count >= 5) {
    return third;
  }
};
