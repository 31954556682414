import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, $isShowInSmallScreen }) => ({
  ...theme.mixins.flexAlignCenter,
  ...theme.paddings(15),
  justifyContent: "space-between",
  display: $isShowInSmallScreen ? "none" : "flex",
  width: "100%",
  gap: 30,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",

  [theme.down("lg")]: {
    display: $isShowInSmallScreen ? "flex" : "none",
  },

  [theme.down("xs")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
  },
}));

export const InfoBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  gap: 30,

  [theme.down("xs")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
  },
}));

export const ItemTitle = styled("div")(({ theme }) => ({
  fontSize: 10,
  color: "#777",
}));

export const ItemValue = styled("div")(({ theme }) => ({
  fontSize: 18,
  color: "#151515",

  "& .order-id": {
    display: "flex",
    padding: "2px 5px",
    color: "#fff",
    backgroundColor: "#358ed3",
    fontSize: 16,
  },

  [theme.down("xs")]: {
    fontSize: 16,
  },
}));

export const DownloadBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  justifyContent: "flex-end",

  [theme.down("xs")]: {
    width: "100%",
  },
}));

export const DownloadButton = styled("button")(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: "50%",
  border: "none",
  padding: 0,
  margin: 0,
  color: "#545454",
  backgroundColor: "#dee3e6",
  fontSize: 16,
  cursor: "pointer",
  transition: "all .2s ease",

  "&:hover": {
    backgroundColor: "#d3d3d3",
    color: "#333",
  },
}));
