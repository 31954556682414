import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, $isOpenable }) => ({
  ...theme.mixins.flexColumn,
  ...($isOpenable
    ? { ...theme.margins(0, -20), ...theme.paddings(0, 20), borderTop: "1px solid #ccc" }
    : { paddingBottom: 10 }),
}));

export const HeaderBlock = styled("div")(({ theme, $hasPaddingTop }) => ({
  ...theme.mixins.flexAlignCenter,
  ...theme.paddings($hasPaddingTop ? 10 : 0, 0, 10),
  ...($hasPaddingTop ? { cursor: "pointer" } : {}),
  gap: 10,
}));

export const IconBlock = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: 20,
  color: "#787878",
  transform: "rotate(0deg)",
  transition: "all .3s ease",
  marginLeft: -5,

  "&.active": {
    transform: "rotate(90deg)",
  },
}));

export const TitleBlock = styled("div")(({ theme }) => ({
  color: "#787878",
  fontSize: 18,
}));

export const BodyBlock = styled("div")(({ theme }) => ({
  height: 0,
  overflow: "hidden",

  "&.active": {
    height: "auto",
    overflow: "unset",
  },

  "&.paddingBottom": {
    paddingBottom: 10,
  },
}));
