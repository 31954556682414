import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 20,

  [theme.down("sm")]: {
    flexDirection: "column",
  },
}));

export const Title = styled("div")(({ theme }) => ({
  width: 120,
  fontSize: 12,
  color: "#75787b",
  letterSpacing: "1.5px",
  paddingLeft: 1,

  [theme.down("sm")]: {
    width: "100%",
  },
}));

export const FormWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: 5,
  flexGrow: 1,

  [theme.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [theme.down("xs")]: {
    gridTemplateColumns: "1fr",
  },
}));

export const InputBlock = styled("div")(({ theme, removeOnMobile }) => ({
  [theme.down("sm")]: {
    display: removeOnMobile ? "none" : "block",
  },
}));
