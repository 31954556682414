import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(10, 20),
  gap: 20,
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",

  [theme.down("sm")]: {
    ...theme.paddings(10),
  },
}));
