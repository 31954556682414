import { Fragment, useEffect, useState } from "react";

import i18next from "i18next";
import { useSelector } from "react-redux";

import { getFilterRecommendations, getSortingRecommendations } from "../../../utils";

import RecommendationComponent from "../RecommendationComponent";

import { ShowMoreButton, ShowMoreButtonBlock, Wrapper } from "./style";
import NotFoundRecommendationsComponent from "../NotFoundRecommendationsComponent";

const ResultsComponent = () => {
  const { t } = i18next;
  const { recommendations } = useSelector(state => state.resultData);
  const { filterParams } = useSelector(state => state.resultFilters);
  const { currency } = useSelector(state => state.appConfigs);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [filterParams]);

  const filterRecommendation = getFilterRecommendations(filterParams, recommendations, currency);
  const sortRecommendation = getSortingRecommendations(filterParams, filterRecommendation, currency);

  const renderResultBlock = () => {
    if (sortRecommendation.length === 0) {
      return <NotFoundRecommendationsComponent byFilters={true} />;
    }

    const fullList = sortRecommendation.map((rec, ind) => renderRecommendation(rec, ind));
    const list = fullList.filter(item => item.key < page * 10);

    return (
      <div>
        {list}
        {list.length < fullList.length ? (
          <ShowMoreButtonBlock>
            <ShowMoreButton onClick={() => setPage(prev => prev + 1)}>{t("show_more")}</ShowMoreButton>
          </ShowMoreButtonBlock>
        ) : null}
      </div>
    );
  };

  const renderRecommendation = (recommendation, index) => {
    return (
      <Fragment key={index}>
        <RecommendationComponent recommendation={recommendation} index={index} />
      </Fragment>
    );
  };

  return <Wrapper>{renderResultBlock()}</Wrapper>;
};

export default ResultsComponent;
