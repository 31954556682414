import { About, AboutMain, AboutSecondary, Concern, ConcernContent, ConcernTitle, PaymentFaults, PaymentFaultsContent, PaymentFaultsReasons, PaymentFaultsTitle, PaymentInfoTitle, PaymentVariants, PaymentVariantsList, PaymentVariantsTitle, Security, SecurityContent, SecuritySubContent, SecurityTitle, Variants, Wrapper } from "./style"

export const PaymentInfo = () => {
	return (
		<Wrapper>
			<PaymentInfoTitle>способы оплаты</PaymentInfoTitle>
			<div>
				<About>
					<AboutMain>
						Для наших Клиентов доступно несколько способов оплаты, каждый из которых гарантирует полную безопасность и неприкосновенность персональных данных.
					</AboutMain>
					<AboutSecondary>
						Независимо от выбранного способа оплаты, расчёт производится в национальной валюте – узбекский сум.
					</AboutSecondary>
				</About>

				<PaymentVariants>
					<PaymentVariantsTitle>
						Физическим лицам доступна оплата по:
					</PaymentVariantsTitle>
					<PaymentVariantsList>
						<Variants>
							Национальным картам UzCard
						</Variants>
						<Variants>
							Картам VISA, MasterCard, выпущенными любыми банками мира
						</Variants>
						<Variants>
							Наличным расчётом в кассе компании, расположенной на улице Гагарина.
						</Variants>
						<Variants>
							Наличными или картой в любой ближайшей точке PAYNET
						</Variants>
					</PaymentVariantsList>
				</PaymentVariants>

				<PaymentVariants>
					<PaymentVariantsTitle>
							Юридическим лицам доступна оплата по:
					</PaymentVariantsTitle>
					<PaymentVariantsList>
							<Variants>
								Корпоративной карте UzCard;
							</Variants>
							<Variants>
								Банковским переводом.
							</Variants>
					</PaymentVariantsList>
				</PaymentVariants>

				<Security>
					<SecurityTitle>
						БЕЗОПАСНОСТЬ
					</SecurityTitle>
					<SecurityContent>
						Мы гарантируем полную безопасность при осуществлении оплаты за наши услуги, т.к. конфиденциальные данные Плательщика (реквизиты карты, регистрационные данные и др.) не поступают в Bookhara.uz, их обработка происходит на защищенных протоколами безопасности сайтах платежных систем. 
					</SecurityContent>
					<SecuritySubContent>
						При обработке платежей по картам VISA, MasterCard и МИР, используется технология 3D-Secure, которая предоставляет дополнительную защиту персональных данных. 
					</SecuritySubContent>
				</Security>

				<Concern>
					<ConcernTitle>
						ТОНКОСТИ ПРИ ОПЛАТЕ КАРТОЙ
					</ConcernTitle>
					<ConcernContent>
						Мы рекомендуем заранее обратиться в свой банк, чтобы удостовериться в том, что Ваша карта может быть использована для онлайн платежей. 
					</ConcernContent>
				</Concern>

				<PaymentFaults>
					<PaymentFaultsTitle>
						Возможные причины отказа в авторизации карты:
					</PaymentFaultsTitle>
					<PaymentFaultsContent>
						<PaymentFaultsReasons>
							на карте недостаточно средств; 
						</PaymentFaultsReasons>
						<PaymentFaultsReasons>
							карта заблокирована; 
						</PaymentFaultsReasons>
						<PaymentFaultsReasons>
							истекло время ожидания ввода данных карты; 
						</PaymentFaultsReasons>
						<PaymentFaultsReasons>
							введенные данные были указаны с ошибкой;
						</PaymentFaultsReasons>
						<PaymentFaultsReasons>
							банк, выпустивший карту, установил запрет или лимит на оплату в Интернете. 
						</PaymentFaultsReasons>
					</PaymentFaultsContent>
				</PaymentFaults>


				<PaymentFaults>
					<PaymentFaultsTitle>
							В зависимости от причины отказа в авторизации, Вы можете:
					</PaymentFaultsTitle>
					<PaymentFaultsContent>
						<PaymentFaultsReasons>
							обратиться за разъяснениями в банк, выпустивший карту; 
						</PaymentFaultsReasons>
						<PaymentFaultsReasons>
							повторить попытку оплаты, воспользовавшись другой картой; 
						</PaymentFaultsReasons>
						<PaymentFaultsReasons>
							выбрать другой доступный способ оплаты
						</PaymentFaultsReasons>
					</PaymentFaultsContent>
				</PaymentFaults>
			</div>
		</Wrapper>
	)
}