export const getCalendarRtPositioning = isCompact => ({
  main: {
    left: -160,
    top: isCompact ? 60 : "auto",
    right: "auto",
    bottom: isCompact ? "auto" : 60,
  },
  xl: {
    left: -160,
    top: isCompact ? "auto" : 60,
    right: "auto",
    bottom: isCompact ? 60 : "auto",
  },
  lg: {
    left: -222,
    top: isCompact ? "auto" : 60,
    right: "auto",
    bottom: isCompact ? 60 : "auto",
  },
  md: {
    left: 0,
    top: isCompact ? "auto" : 60,
    right: "auto",
    bottom: isCompact ? 60 : "auto",
  },
  sm: {
    left: 0,
    top: 60,
    right: "auto",
    bottom: "auto",
  },
});

export const calendarCfPositioning = {
  main: {
    left: "auto",
    top: 60,
    right: 0,
    bottom: "auto",
  },
  xl: {
    left: "auto",
    top: 60,
    right: 0,
    bottom: "auto",
  },
  lg: {
    left: "auto",
    top: 60,
    right: 0,
    bottom: "auto",
  },
  md: {
    left: "auto",
    top: 60,
    right: 0,
    bottom: "auto",
  },
  sm: {
    left: 0,
    top: 60,
    right: "auto",
    bottom: "auto",
  },
};
