import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(15),
  gap: 15,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
}));

export const BlockTitle = styled("h3")(({ theme }) => ({
  color: "#787878",
  borderBottom: "1px solid #ababab87",
  fontWeight: 400,
  paddingBottom: 10,
}));
