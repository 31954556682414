import { useCallback, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import i18next from "i18next";
import PropTypes from "prop-types";
import { CiLocationOn } from "react-icons/ci";
import { RiCloseLine } from "react-icons/ri";
import { useSelector } from "react-redux";

import { fromInputDefaultAirports, toInputDefaultAirports } from "../../../config";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_OW, FLIGHT_TYPE_RT } from "../../../constants";

import { getCities } from "../../../protocols";

import { debounce } from "../../../utils";

import SearchAirportDropdownComponent from "../SearchAirportDropdownComponent";

import {
  AirportInput,
  FlagImage,
  InputAirportCodeAndClearBlock,
  InputIconBlock,
  InputPlaceholder,
  Wrapper,
} from "./style";

const SearchAirportSelectComponent = ({ type, flightType, airport, onAirportSelect, onClearAirport, routeIndex }) => {
  const inputRef = useRef(null);

  const { notFilledFields } = useSelector(state => state.searchParams);

  const { language, t } = i18next;

  const [value, setValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [airports, setAirports] = useState(type === "from" ? fromInputDefaultAirports : toInputDefaultAirports);

  useEffect(() => {
    if (airport) {
      setValue(`${airport?.name[language]}, ${airport?.countryName[language]}` || "");
    } else {
      setValue("");
    }
  }, [airport, language]);

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleGetCities = useCallback(
    debounce(val => {
      getCities(val).then(response => {
        setAirports(response);

        if (response.length) {
          setShowDropdown(true);
        }
      });
    }),
    []
  );

  const handleChangeInput = async ({ target }) => {
    setValue(target.value);

    if (target.value.length > 2) {
      handleGetCities(target.value);
    }

    if (!target.value.length) {
      setAirports(type === "from" ? fromInputDefaultAirports : toInputDefaultAirports);
    }
  };

  const handleFocusInput = () => {
    if (!airport || !value.length) {
      setShowDropdown(true);
    } else {
      inputRef.current?.select();
    }
  };

  const handleBlurInput = () => {
    if (airport) {
      setValue(`${airport?.name[language]}, ${airport?.countryName[language]}` || "");
    } else {
      setValue("");
    }
  };

  const handleClickAirport = code => {
    onAirportSelect(routeIndex, type, code);
    setShowDropdown(false);
  };

  const handleClearAirport = () => {
    onClearAirport(routeIndex, type);
  };

  return (
    <>
      <Wrapper type={type} flightType={flightType} className={classNames({ "notFilled": notFilledFields[type] && routeIndex === 0 })}>
        <InputPlaceholder className={value.length && "not-empty"}>
          {type === "from" ? t("route_from") : t("route_to")}
        </InputPlaceholder>

        <InputIconBlock>
          {airport ? <FlagImage src={`/flags/${airport.countryCode}.png`} alt="country-flag" /> : <CiLocationOn />}
        </InputIconBlock>

        <AirportInput
          ref={inputRef}
          value={value}
          onChange={handleChangeInput}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
          type={type}
          flightType={flightType}
          autoComplete="off"
        />

        {airport && (
          <InputAirportCodeAndClearBlock>
            <span>{airport.code}</span>
            <RiCloseLine onClick={handleClearAirport} />
          </InputAirportCodeAndClearBlock>
        )}

        {showDropdown && (
          <SearchAirportDropdownComponent
            show={showDropdown}
            onClickOutside={handleCloseDropdown}
            airports={airports}
            onClickAirport={handleClickAirport}
          />
        )}
      </Wrapper>
    </>
  );
};

export default SearchAirportSelectComponent;

SearchAirportSelectComponent.defaultProps = {
  type: "from",
  flightType: FLIGHT_TYPE_RT,
  airport: {},
};

SearchAirportSelectComponent.propTypes = {
  type: PropTypes.oneOf(["from", "to"]),
  flightType: PropTypes.oneOf([FLIGHT_TYPE_OW, FLIGHT_TYPE_RT, FLIGHT_TYPE_CF]),
  airport: PropTypes.shape({}),
};
