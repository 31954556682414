import RecommendationFlightsComponent from "../RecommendationFlightsComponent";
import RecommendationPricesComponent from "../RecommendationPricesComponent";

import { Wrapper } from "./style";

const RecommendationComponent = ({ recommendation }) => (
  <Wrapper>
    <RecommendationFlightsComponent recommendation={recommendation} />
    <RecommendationPricesComponent recommendation={recommendation} />
  </Wrapper>
);

export default RecommendationComponent;
