import { ContentType, request } from "../api";
import { baseParams } from "../common";

export const ticketing = async (data = {}, params = {}) => request({
  path: "/ticketing",
  method: "POST",
  body: { ...baseParams, ...data },
  type: ContentType.Json,
  format: "json",
  secure: true,
  ...params,
});
