import i18next from "i18next";
import RoutesDataComponent from "../RoutesDataComponent";
import { BlockTitle, Wrapper } from "./style";

const OrderFlightInfoComponent = () => {
  const { t } = i18next;

  return (
    <Wrapper>
      <BlockTitle>{t("flight_overview")}</BlockTitle>
      <RoutesDataComponent />
    </Wrapper>
  )
};

export default OrderFlightInfoComponent;
