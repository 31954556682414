import { useState } from "react";

import i18next from "i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

import { setPaymentMethod } from "../../../store/orderSlice";

import { CheckboxComponent, DotsLoadingComponent } from "../../ui";

import {
  BlockTitle,
  PaymentButton,
  PaymentButtonLoadingCover,
  PaymentMethodCheckboxBlock,
  PaymentMethodDescription,
  PaymentMethodItem,
  PaymentMethodLogo,
  PaymentMethodsBlock,
  TimelimitAndPaymentButtonBlock,
  Wrapper,
} from "./style";
import OrderTimelimitComponent from "../OrderTimelimitComponent";
import { TimelimitExpired } from "../OrderTimelimitComponent/style";
import { parserLangCode } from "../../../utils";
import { useToggle } from "../../../hooks";
import { useAuth } from "../../../context/auth";

const OrderPaymentFormComponent = ({ sendPaymentRequest }) => {
  const { language, t } = i18next;
  const {
    availablePaymentMethods,
    orderData: { timelimit, total_price },
    paymentMethod,
  } = useSelector(state => state.order);
  const dispatch = useDispatch();
  const {
    isTrue,
    set: { on, off },
  } = useToggle();
  const { isAuth, user } = useAuth();

  const [timerIsActive, setTimerIsActive] = useState(timelimit > 0);

  const handleOnClickItem = code => {
    if (code !== paymentMethod) {
      if (code !== "BALANCE") {
        dispatch(setPaymentMethod({ method: code }));
      }

      if (code === "BALANCE" && checkEnoughBalance()) {
        dispatch(setPaymentMethod({ method: code }));
      }
    }
  };

  const handleTimeEnd = () => setTimerIsActive(false);

  const handleSendPaymentRequest = () => {
    if (!isTrue) {
      on();
      sendPaymentRequest(off);
    }
  };

  const checkEnoughBalance = () => {
    return parseFloat(user?.balance) >= parseFloat(total_price);
};

  if (timerIsActive) {
    const timelimitTime = moment()
      .locale(parserLangCode(language))
      .add(timelimit, "seconds")
      .format("DD MMM YYYY, dd, HH:mm");

    return (
      <>      
        <Wrapper>
          <BlockTitle>{t("payment_method")}</BlockTitle>

          <PaymentMethodsBlock>
            {availablePaymentMethods?.map(method => {
              if (method.id === 0 && !isAuth) return null;

              return (
                <PaymentMethodItem
                  key={method.id}
                  $isActive={method.code === paymentMethod}
                  onClick={() => handleOnClickItem(method.code)}
                >
                  <PaymentMethodCheckboxBlock>
                    <CheckboxComponent value={method.code === paymentMethod} hasLabel={false} />
                  </PaymentMethodCheckboxBlock>

                  {method.id === 0 ? (
                    <span className="balance-icon"><MdOutlineAccountBalanceWallet /></span>
                  ) : (
                    <PaymentMethodLogo src={method.logo} alt="payment-method-logo" />
                  )}

                  {method.id === 0 ? t("payment_with_balance") : method.description[language] && (
                    <PaymentMethodDescription $isActive={method.code === paymentMethod}>
                      {method.code === "VSL" ? t("payment_with_vsl") : method.description[language]}
                    </PaymentMethodDescription>
                  )}

                  {(method.id === 0 && !checkEnoughBalance()) ? (
                    <span className="balance-not-enough">{t("not_enough_balance")}</span>
                  ) : null}
                </PaymentMethodItem>
              )
            })}
          </PaymentMethodsBlock>
        </Wrapper>

        <PaymentButton onClick={handleSendPaymentRequest} disabled={isTrue}>
          {t("pay_for_the_order")}
          {isTrue && (
            <PaymentButtonLoadingCover>
              <DotsLoadingComponent color="#fff" />
            </PaymentButtonLoadingCover>
          )}
        </PaymentButton>

        <Wrapper>
          <TimelimitAndPaymentButtonBlock>
            <div>{t("left_before_the_time_limit")}:</div>
            <OrderTimelimitComponent timelimit={timelimit} onTimerEnd={handleTimeEnd} />
            <div>{t("expires")} {timelimitTime}</div>
          </TimelimitAndPaymentButtonBlock>
        </Wrapper>
      </>
    );
  }

  return (
    <Wrapper>
      <TimelimitExpired>{t("payment_timed_out")}</TimelimitExpired>
    </Wrapper>
  );
};

export default OrderPaymentFormComponent;
