import { useState } from "react";

import classNames from "classnames";
import i18next from "i18next";
import { BiChevronDown } from "react-icons/bi";

import { useWindowDimensions } from "../../../hooks";

import TableFiltersDate from "./TableFiltersDate";
import TableFiltersInput from "./TableFiltersInput";
import TableFiltersSelect from "./TableFiltersSelect";

import { ButtonIcon, FilterButton, FiltersBlock, Wrapper } from "./style";

const TableFilters = ({ filters, onChangeFilters }) => {
  const { width } = useWindowDimensions();
  const { t } = i18next;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      {width <= 460 && (
        <FilterButton onClick={() => setIsOpen(prev => !prev)}>
          {t("filters")}

          <ButtonIcon className={classNames({ active: isOpen })}>
            <BiChevronDown />
          </ButtonIcon>
        </FilterButton>
      )}

      {(width > 460 || (width <= 460 && isOpen)) ? (
        <FiltersBlock>
          {filters.map(filter => {
            if (filter.type === "input") {
              return (
                <TableFiltersInput
                  key={filter.key}
                  filterKey={filter.key}
                  onChangeFilter={onChangeFilters}
                  placeholder={filter.placeholder}
                  hasBeforeIcon={filter.hasBeforeIcon}
                />
              );
            }

            if (filter.type === "date") {
              return (
                <TableFiltersDate
                  key={filter.key}
                  filterKey={filter.key}
                  onChangeFilter={onChangeFilters}
                  placeholder={filter.placeholder}
                  hasBeforeIcon={filter.hasBeforeIcon}
                />
              );
            }

            if (filter.type === "select") {
              return (
                <TableFiltersSelect
                  key={filter.key}
                  filterKey={filter.key}
                  options={filter.options}
                  onChangeFilter={onChangeFilters}
                  placeholder={filter.placeholder}
                  hasBeforeIcon={filter.hasBeforeIcon}
                />
              );
            }

            return null;
          })}
        </FiltersBlock>
      ) : null}
    </Wrapper>
  );
};

export default TableFilters;
