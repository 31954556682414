import styled from "styled-components";

export const RangeBlock = styled("div")(({ theme }) => ({
  ...theme.paddings(0, 8, 5),
}));

export const RangeText = styled("div")(({ theme }) => ({
  ...theme.margins(10, -8),
  fontSize: 10,
  color: "#151515",
}));
