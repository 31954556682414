import { useEffect } from "react";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import { instance } from "../protocols";

const throttle = (
  fn,
  delay = 100
) => {
  let timerId = null;
  return (...args) => {
    if (!timerId) {
      fn(...args);
    } else {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      timerId = null;
    }, delay);
  };
};

const throttledErrorToast = throttle(toast.error, 5000);

const useAxiosErrors = () => {
  useEffect(() => {
    const id = instance.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.code === AxiosError.ERR_NETWORK) {
          throttledErrorToast("Нет соединения");
        }
        
        const { response: { status } } = err || {};

        toast.error(`Произошла ошибка при отправке запроса. Код ошибки: ${status}`);

        return Promise.reject(err);
      }
    );

    return () => instance.interceptors.response.eject(id);
  }, []);
};

export default useAxiosErrors;
