import i18next from "i18next";
import PropTypes from "prop-types";
import { BiChevronDown } from "react-icons/bi";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_OW, FLIGHT_TYPE_RT } from "../../../constants";

import { useToggle } from "../../../hooks";

import { setCabin, setPassengers } from "../../../store/searchParamsSlice";

import { ReactComponent as AdultIcon } from "../../../assets/icons/adult.svg";
import { ReactComponent as ChildIcon } from "../../../assets/icons/child.svg";
import { ReactComponent as InfantIcon } from "../../../assets/icons/infant.svg";
import { ReactComponent as InfantWithPlaceIcon } from "../../../assets/icons/infantWithPlace.svg";

import { DropdownComponent } from "../../ui";

import {
  ClassBlock,
  ClassButton,
  ClassesBlock,
  CounterBlock,
  DropdownIcon,
  DropdownPassengerItem,
  DropdownPassengerItemTitleAndDescription,
  DropdownPassengersBlock,
  PassengerCount,
  PassengersCountBlock,
  Wrapper,
} from "./style";

const SearchClassPassengersSelectComponent = ({ flightType }) => {
  const { t } = i18next;
  const {
    isTrue: isShowDropdown,
    set: { on, off },
  } = useToggle();

  const { cabin, passengers } = useSelector(state => state.searchParams);
  const dispatch = useDispatch();

  const cabins = [
    { cabin: "all", label: t("any_cabin") },
    { cabin: "economy", label: t("economy") },
    { cabin: "business", label: t("business") },
    { cabin: "first", label: t("first") },
  ];

  const handleMinusClick = type => {
    const currentPassengers = { ...passengers };

    if (type === "adt" && currentPassengers.adt > 1 && currentPassengers.adt > currentPassengers.inf) {
      currentPassengers.adt = currentPassengers.adt - 1;
      dispatch(setPassengers(currentPassengers));
    }

    if (type === "chd" && currentPassengers.chd > 0) {
      currentPassengers.chd = currentPassengers.chd - 1;
      dispatch(setPassengers(currentPassengers));
    }

    if (type === "ins" && currentPassengers.ins > 0) {
      currentPassengers.ins = currentPassengers.ins - 1;
      dispatch(setPassengers(currentPassengers));
    }

    if (type === "inf" && currentPassengers.inf > 0) {
      currentPassengers.inf = currentPassengers.inf - 1;
      dispatch(setPassengers(currentPassengers));
    }
  };

  const handlePlusClick = type => {
    const currentPassengers = { ...passengers };
    const passengersSum = currentPassengers.adt + currentPassengers.chd + currentPassengers.ins + currentPassengers.inf;

    if (passengersSum < 9) {
      if (type === "adt") {
        currentPassengers.adt = currentPassengers.adt + 1;
        dispatch(setPassengers(currentPassengers));
      }

      if (type === "chd") {
        currentPassengers.chd = currentPassengers.chd + 1;
        dispatch(setPassengers(currentPassengers));
      }

      if (type === "inf" && currentPassengers.inf < currentPassengers.adt) {
        currentPassengers.inf = currentPassengers.inf + 1;
        dispatch(setPassengers(currentPassengers));
      }

      if (type === "ins" && currentPassengers.ins <= 4) {
        currentPassengers.ins = currentPassengers.ins + 1;
        dispatch(setPassengers(currentPassengers));
      }
    }
  };

  const getIsDisabledMinus = type => {
    const currentPassengers = { ...passengers };

    if (type === "adt") {
      if (currentPassengers.adt === 1 || currentPassengers.adt <= currentPassengers.inf) {
        return true;
      }
    }

    if (type === "chd") {
      if (currentPassengers.chd === 0) {
        return true;
      }
    }

    if (type === "inf") {
      if (currentPassengers.inf === 0) {
        return true;
      }
    }

    if (type === "ins") {
      if (currentPassengers.ins === 0) {
        return true;
      }
    }
  };

  const getIsDisabledPlus = type => {
    const currentPassengers = { ...passengers };
    const passengersSum = currentPassengers.adt + currentPassengers.chd + currentPassengers.ins + currentPassengers.inf;

    if (passengersSum >= 9) {
      return true;
    }

    if (type === "ins" && passengers.ins >= 5) {
      return true;
    }

    if (type === "inf") {
      if (currentPassengers.inf >= currentPassengers.adt) {
        return true;
      }
    }
  };

  const handleClickCabin = newCabin => {
    dispatch(setCabin(newCabin));
  };

  return (
    <Wrapper flightType={flightType} onClick={on}>
      <ClassBlock>{cabins.find(value => value.cabin === cabin)?.label || t("any_cabin")}</ClassBlock>

      <PassengersCountBlock>
        <PassengerCount>
          <AdultIcon />
          <span>×{passengers.adt}</span>
        </PassengerCount>
        <PassengerCount>
          <ChildIcon />
          <span>×{passengers.chd}</span>
        </PassengerCount>
        <PassengerCount>
          <InfantIcon />
          <span>×{passengers.inf}</span>
        </PassengerCount>
        <PassengerCount>
          <InfantWithPlaceIcon />
          <span>×{+passengers.ins}</span>
        </PassengerCount>
      </PassengersCountBlock>

      <DropdownIcon className={isShowDropdown && "active"}>
        <BiChevronDown />
      </DropdownIcon>

      {isShowDropdown && (
        <DropdownComponent show={isShowDropdown} onClickOutside={off} width={260}>
          <DropdownPassengersBlock>
            <DropdownPassengerItem>
              <AdultIcon />

              <DropdownPassengerItemTitleAndDescription>
                <span className="title">{t("adt_main_label")}</span>
                <span className="description">{t("adt_second_label")}</span>
              </DropdownPassengerItemTitleAndDescription>

              <CounterBlock>
                <CiCircleMinus
                  className={getIsDisabledMinus("adt") && "disabled"}
                  onClick={() => handleMinusClick("adt")}
                />
                <span>{passengers.adt}</span>
                <CiCirclePlus
                  className={getIsDisabledPlus("adt") && "disabled"}
                  onClick={() => handlePlusClick("adt")}
                />
              </CounterBlock>
            </DropdownPassengerItem>

            <DropdownPassengerItem>
              <ChildIcon />

              <DropdownPassengerItemTitleAndDescription>
                <span className="title">{t("chd_main_label")}</span>
                <span className="description">{t("chd_second_label")}</span>
              </DropdownPassengerItemTitleAndDescription>

              <CounterBlock>
                <CiCircleMinus
                  className={getIsDisabledMinus("chd") && "disabled"}
                  onClick={() => handleMinusClick("chd")}
                />
                <span>{passengers.chd}</span>
                <CiCirclePlus
                  className={getIsDisabledPlus("chd") && "disabled"}
                  onClick={() => handlePlusClick("chd")}
                />
              </CounterBlock>
            </DropdownPassengerItem>

            <DropdownPassengerItem>
              <InfantIcon />

              <DropdownPassengerItemTitleAndDescription>
                <span className="title">{t("inf_main_label")}</span>
                <span className="description">{t("inf_second_label")}</span>
              </DropdownPassengerItemTitleAndDescription>

              <CounterBlock>
                <CiCircleMinus
                  className={getIsDisabledMinus("inf") && "disabled"}
                  onClick={() => handleMinusClick("inf")}
                />
                <span>{passengers.inf}</span>
                <CiCirclePlus
                  className={getIsDisabledPlus("inf") && "disabled"}
                  onClick={() => handlePlusClick("inf")}
                />
              </CounterBlock>
            </DropdownPassengerItem>

            <DropdownPassengerItem>
              <InfantWithPlaceIcon />

              <DropdownPassengerItemTitleAndDescription>
                <span className="title">{t("inf_main_label")} </span>
                <span className="description">{t("ins_second_label")}</span>
              </DropdownPassengerItemTitleAndDescription>

              <CounterBlock>
                <CiCircleMinus
                  className={getIsDisabledMinus("ins") && "disabled"}
                  onClick={() => handleMinusClick("ins")}
                />
                <span>{passengers.ins}</span>
                <CiCirclePlus
                  className={getIsDisabledPlus("ins") && "disabled"}
                  onClick={() => handlePlusClick("ins")}
                />
              </CounterBlock>
            </DropdownPassengerItem>
          </DropdownPassengersBlock>

          <ClassesBlock>
            {cabins.map((data, index) => (
              <ClassButton
                key={data.cabin}
                onClick={() => handleClickCabin(data.cabin)}
                className={cabin === data.cabin && "active"}
                {...(index === 0 || index === 1 ? { position: "1/3" } : {})}
              >
                {data.label}
              </ClassButton>
            ))}
          </ClassesBlock>
        </DropdownComponent>
      )}
    </Wrapper>
  );
};

export default SearchClassPassengersSelectComponent;

SearchClassPassengersSelectComponent.defaultProps = {
  flightType: FLIGHT_TYPE_RT,
};

SearchClassPassengersSelectComponent.propTypes = {
  flightType: PropTypes.oneOf([FLIGHT_TYPE_OW, FLIGHT_TYPE_RT, FLIGHT_TYPE_CF]),
};
