import i18next from "i18next";
import _ from "lodash";
import { useSelector } from "react-redux";

import { getPassTypeCountString, normalizePrice } from "../../../utils";

import { LineDivider, PriceItem, Wrapper } from "./style";

const OrderPriceComponent = () => {
  const { t } = i18next;
  const {
    orderData: { total_price, passenger_data, currency },
  } = useSelector(state => state.order);

  const renderPassengersAmount = () => {
    const adtCount = _.filter(passenger_data, passenger => passenger.type === "adt").length;
    const chdCount = _.filter(passenger_data, passenger => passenger.type === "chd").length;
    const insCount = _.filter(passenger_data, passenger => passenger.type === "ins").length;
    const infCount = _.filter(passenger_data, passenger => passenger.type === "inf").length;

    let adtAmount = 0;
    let chdAmount = 0;
    let insAmount = 0;
    let infAmount = 0;

    if (adtCount > 0) {
      adtAmount = passenger_data.reduce((acc, pass) => {
        if (pass.type === "adt") {
          acc += +pass.total_price;
        }

        return acc;
      }, 0);
    }

    if (chdCount > 0) {
      chdAmount = _.find(passenger_data, passenger => passenger.type === "adt").total_price;
    }

    if (insCount > 0) {
      insAmount = _.find(passenger_data, passenger => passenger.type === "adt").total_price;
    }

    if (infCount > 0) {
      infAmount = _.find(passenger_data, passenger => passenger.type === "adt").total_price;
    }

    if (_.isNull(adtAmount)) {
      return null;
    }

    return (
      <>
        <PriceItem $isSmallText>
          <span>
            - {adtCount > 1 && adtCount} {getPassTypeCountString(adtCount, "adt")}
          </span>
          <span>
            {normalizePrice(adtAmount)} <span>{currency}</span> {adtCount > 1 && "х" + adtCount}
          </span>
        </PriceItem>

        {chdCount ? (
          <PriceItem $isSmallText>
            <span>
              - {chdCount > 1 && chdCount} {getPassTypeCountString(chdCount, "chd")}
            </span>
            <span>
              {normalizePrice(chdAmount)} <span>{currency}</span> {chdCount > 1 && "х" + chdCount}
            </span>
          </PriceItem>
        ) : null}

        {insCount ? (
          <PriceItem $isSmallText>
            <span>
              - {insCount > 1 && insCount} {getPassTypeCountString(insCount, "ins")} ({t("inf_with_seat_second_label")})
            </span>
            <span>
              {normalizePrice(insAmount)} <span>{currency}</span> {insCount > 1 && "х" + insCount}
            </span>
          </PriceItem>
        ) : null}

        {infCount ? (
          <PriceItem $isSmallText>
            <span>
              - {infCount > 1 && infCount} {getPassTypeCountString(infCount, "inf")}
            </span>
            <span>
              {normalizePrice(infAmount)} <span>{currency}</span> {infCount > 1 && "х" + infCount}
            </span>
          </PriceItem>
        ) : null}
      </>
    );
  };

  return (
    <Wrapper>
      <PriceItem>
        <span>{t("flights_tickets")}</span>
        <span>
          {normalizePrice(_.toFinite(total_price).toFixed(2))} <span>{currency}</span>
        </span>
      </PriceItem>

      {renderPassengersAmount()}

      <LineDivider />

      <PriceItem $isTotal>
        <span>{t("total")}</span>
        <span>
          {normalizePrice(_.toFinite(total_price).toFixed(2))} <span>{currency}</span>
        </span>
      </PriceItem>
    </Wrapper>
  );
};

export default OrderPriceComponent;
