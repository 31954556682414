import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  position: "relative",
}));

export const Label = styled("label")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  fontSize: 12,
  lineHeight: "16px",
}));

export const CheckboxInput = styled("input")(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 2,
  width: "100%",
  height: "100%",
  opacity: 0,
  margin: 0,
  cursor: "pointer",
}));

export const CheckboxBlock = styled("div")(({ theme, $hasLabel }) => ({
  ...theme.mixins.flexAlignCenter,
  width: 16,
  height: 16,
  border: ".5px solid #9b9b9b",
  marginRight: $hasLabel ? 8 : 0,
  borderRadius: 4,
  color: "#039be5",
  fontSize: 14,
}));
