import { ContentType, request } from "../api";
import { baseParams } from "../common";

const paymentSystemsResultFormat = data => {
  return data.map(item => ({
    id: item["id"],
    code: item["code"],
    logo: item["logo"],
    name: item["name"],
    description: JSON.parse(item["description"]),
  }));
};

export const getPaymentSystems = async (purpose, params = {}) => {
  const response = await request({
    path: "/payment-systems",
    method: "POST",
    secure: true,
    body: { ...baseParams, purpose },
    type: ContentType.Json,
    format: "json",
    ...params,
  });

  return paymentSystemsResultFormat(response.data);
};
