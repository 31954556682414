import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const CountryItem = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  ...theme.paddings(7, 10),
  gap: 5,
  cursor: "pointer",
  color: "#151515",
  fontSize: 14,

  "&:hover": {
    backgroundColor: "#f2fbff",
  },
}));

export const CountryLabel = styled("span")(({ theme }) => ({
  color: "#333",
  fontSize: 13,
}));
