import styled, { css, keyframes } from "styled-components";
import { FLIGHT_TYPE_RT, calendarCfPositioning, getCalendarRtPositioning } from "../../../constants";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
`;

const dropdownIn = keyframes`
  0% {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(0);
  }
`;

const dropdownOut = keyframes`
  0% {
    transform: translateY(0)
  }

  to {
    transform: translateY(-5px)
  }
`;

export const Wrapper = styled("div")(({ theme, active, $flightType, isCompact }) => ({
  display: active ? "block" : "none",
  position: "absolute",
  zIndex: 151,
  width: 730,
  ...($flightType === FLIGHT_TYPE_RT ? getCalendarRtPositioning(isCompact).main : calendarCfPositioning.main),

  [theme.down("xl")]: {
    ...($flightType === FLIGHT_TYPE_RT ? getCalendarRtPositioning(isCompact).xl : calendarCfPositioning.xl),
  },

  [theme.down("lg")]: {
    ...($flightType === FLIGHT_TYPE_RT ? getCalendarRtPositioning(isCompact).lg : calendarCfPositioning.lg),
  },

  [theme.down("md")]: {
    ...($flightType === FLIGHT_TYPE_RT ? getCalendarRtPositioning(isCompact).md : calendarCfPositioning.md),
    width: 335,
  },

  [theme.down("sm")]: {
    ...($flightType === FLIGHT_TYPE_RT ? getCalendarRtPositioning(isCompact).sm : calendarCfPositioning.sm),
  },
}));

export const DropdownBlock = styled("div")(
  ({ theme }) => ({
    display: "block",
    backgroundColor: "#fff",
    borderRadius: "4px 4px 0 0",
    padding: 0,
    boxShadow: "0 1px 1px rgba(0,0,0,.1)",
    border: "1px solid #b8b8b8",
  }),
  css`
    animation: ${props => (props.active ? dropdownIn : dropdownOut)} 0.2s forwards,
      ${props => (props.active ? fadeIn : fadeOut)} 0.2s forwards;
  `
);
