import { useState } from "react";

import i18next from "i18next";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  AgreementBookLink,
  AgreementText,
  Button,
  CheckboxBlock,
  PassengersBlock,
  SubmitBlock,
  Wrapper,
} from "./style";
import { ALL_DOCUMENTS_ITEMS, FRIENDLY_COUNTRY, ONLY_RU_COUNTRY, PS, SR } from "../../../constants";
import { useFormik } from "formik";
import { getBookingFormValidationSchema } from "../../../schemas";
import BookingPayerInfoComponent from "../BookingPayerInfoComponent";
import { Title } from "../BookingPayerInfoComponent/style";
import BookingPassengerItemComponent from "../BookingPassengerItemComponent";
import { CheckboxComponent, DotsLoadingComponent } from "../../ui";
import { useToggle } from "../../../hooks";
import { useAuth } from "../../../context/auth";

const BookingFormComponent = ({ passengers, handleBookClick }) => {
  const { t } = i18next;
  const { routes, flightType, included, fareRules } = useSelector(state => state.prebook);

  const {
    isTrue: isShowRulesModal,
    set: { on },
  } = useToggle();

  const { isAuth, user } = useAuth();

  const [agreeWithRules, setAgreeWithRules] = useState(true);
  const [isBookLoading, setIsBookLoading] = useState(false);

  const lastRoute = _.last(routes);
  const deadlineDate = _.last(lastRoute.segments).arrival.time;
  // const [showRulesModal, setShowRulesModal] = useState(false);

  const getPassengers = () => {
    let passengersArray = [];
    let chdArray = [];
    let insArray = [];

    _.forEach(passengers, (count, passengerType) => {
      if (passengerType === "adt") {
        _.times(count, () => {
          passengersArray.push([passengerType]);
        });
      }

      if (passengerType === "chd") {
        _.times(count, () => {
          chdArray.push(passengerType);
        });
      }

      if (passengerType === "ins") {
        _.times(count, () => {
          insArray.push(passengerType);
        });
      }

      if (passengerType === "inf") {
        _.times(count, index => {
          passengersArray[index] = [...passengersArray[index], passengerType];
        });
      }
    });

    const passengersList = [..._.flatten(passengersArray), ...chdArray, ...insArray];

    return passengersList.map((value, index) => {
      let documentItems = [];

      if (flightType === ONLY_RU_COUNTRY) {
        documentItems = value === "adt" ? ALL_DOCUMENTS_ITEMS : ALL_DOCUMENTS_ITEMS.filter(item => item !== PS);
      } else if (flightType === FRIENDLY_COUNTRY) {
        documentItems =
          value === "adt"
            ? ALL_DOCUMENTS_ITEMS.filter(item => item !== SR)
            : ALL_DOCUMENTS_ITEMS.filter(item => item !== PS && item !== SR);
      } else {
        documentItems = ALL_DOCUMENTS_ITEMS.filter(item => item !== PS && item !== SR);
      }

      return {
        type: value,
        surname: "",
        name: "",
        middle_name: "",
        citizenship: "",
        gender: "",
        document_items: documentItems,
        document_type: _.first(documentItems),
        document_number: "",
        email: isAuth ? user?.login : "",
        phone: "",
        expiration_date: "",
        date_of_birth: "",
      };
    });
  };

  const handleSubmitFunction = values => {
    if (!isBookLoading) {
      handleBookClick(values);
      setIsBookLoading(true);
    }
  };

  const form = useFormik({
    initialValues: {
      payer_email: isAuth ? user?.login : "",
      payer_phone: "",
      passengers: getPassengers(),
    },
    validationSchema: getBookingFormValidationSchema(t, deadlineDate),
    onSubmit: handleSubmitFunction,
  });

  return (
    <Wrapper>
      <BookingPayerInfoComponent form={form} />

      <PassengersBlock>
        <Title>{form.values.passengers.length === 1 ? t("passenger_data") : t("passengers")}</Title>
        {form.values.passengers.map((value, index) => (
          <BookingPassengerItemComponent
            key={index}
            passenger={value}
            index={index}
            form={form}
            flightType={flightType}
          />
        ))}
      </PassengersBlock>

      <CheckboxBlock>
        <div>
          <CheckboxComponent value={agreeWithRules} onChange={() => setAgreeWithRules(prev => !prev)} />
        </div>

        <AgreementText>
          {t("i_read_fares")} <AgreementBookLink onClick={on}>{t("agreement_book_link")}</AgreementBookLink>{" "}
          {t("i_accept_fares")}
        </AgreementText>
      </CheckboxBlock>

      <SubmitBlock>
        <div>
          <Button onClick={form.handleSubmit} disabled={!agreeWithRules}>
            {t("book_and_proceed_to_payment")}
            {isBookLoading ? (<div><DotsLoadingComponent color="#fff" /></div>) : null}
          </Button>
        </div>
      </SubmitBlock>
    </Wrapper>
  );
};

export default BookingFormComponent;
