import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { PaymentInfo } from "../../components/info/PaymentComponent";
import { setShowSearch } from "../../store/appConfigsSlice";

const InfoPaymentPage = () => {
	const dispatch = useDispatch()

	useEffect (() => {
		dispatch(setShowSearch(false))
	}, []);

	return (
		<PaymentInfo />
	);
};

export default InfoPaymentPage;