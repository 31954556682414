import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentMethod: null,
  isLoad: false,
  orderData: null,
  isLoadPaymentMethod: false,
  availablePaymentMethods: [],
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderData: (state, action) => ({
      ...state,
      orderData: action.payload.orderData,
      isLoad: action.payload.loadStatus,
    }),
    setAvailablePaymentMethods: (state, action) => ({
      ...state,
      isLoadPaymentMethod: action.payload.loadStatus,
      availablePaymentMethods: action.payload.methods,
    }),
    setPaymentMethod: (state, action) => ({ ...state, paymentMethod: action.payload.method }),
  },
});

export const { setOrderData, setAvailablePaymentMethods, setPaymentMethod } = orderSlice.actions;

export default orderSlice.reducer;
