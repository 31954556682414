import { useCallback, useState } from "react";

import { useFormik } from "formik";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { login } from "../../../protocols";

import { useEventListener } from "../../../hooks";

import { DotsLoadingComponent, InputComponent } from "../../ui";

import { useAuth } from "../../../context/auth";

import {
	BackButton,
	BackButtonBlock,
	BackButtonLabel,
	ButtonBlock,
	CardWrapper,
	FormSubtitle,
	FormTitle,
	ResetButton,
	ResetButtonBlock,
	ResetButtonLabel,
	SubmitButton,
	SubmitButtonLoadingCover,
	Wrapper
} from "../RegistrationForm/style";

const LoginForm = () => {
  const { language, t } = i18next;
  const { getUserData } = useAuth();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    login: Yup.string().trim().email(t("enter_valid_mail")).required(t("required")),
    password: Yup.string().min(6, t("must_field_characters")).required(t("required")),
});


  const handleFormSubmit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const loginResult = await login({ ...values, language });

      if (loginResult?.data?.error_code) {
        setFieldError("login", loginResult.error_desc);
      } else {
        localStorage.setItem("token", loginResult?.data?.token);
        await getUserData(loginResult?.data);
        navigate("/");
      }
    } catch (error) {
      console.log({ error });
      setFieldError("login", error.response.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik(
    {
      initialValues: {
        login: "",
        password: "",
      },
      validationSchema,
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
    }
);

  const handleKeyPress = useCallback(e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSubmit();
    }
  }, [handleSubmit]);

  useEventListener("keypress", handleKeyPress);

  return (
    <Wrapper>
			<CardWrapper>
				<FormTitle>
					{t("welcome_back")}
				</FormTitle>

				<FormSubtitle>
					Please enter your credentials below to access your account.
					{/* {t("login_as_user")} */}
				</FormSubtitle>

				<InputComponent
					value={values.login}
					onChange={handleChange("login")}
					type="email"
					placeholder={t("enter_email")}
					label={t("email")}
					error={errors.login}
					onBlur={handleBlur("login")}
					touched={touched.login}
					required
				/>

				<InputComponent
					value={values.password}
					onChange={handleChange("password")}
					type="password"
					placeholder={t("enter_password")}
					label={t("password_text")}
					error={errors.password}
					onBlur={handleBlur("password")}
					touched={touched.password}
					required
				/>

				<ResetButtonBlock>
					<ResetButtonLabel>
						Forgot your password?
					</ResetButtonLabel>
					<ResetButton>
						<Link to="/registration">
							You can reset it here
						</Link>
					</ResetButton>
				</ResetButtonBlock>

				<ButtonBlock>
					<SubmitButton
						onClick={handleSubmit}
						type="button"
					>
						{t("enter_text")}
						{isSubmitting && (
							<SubmitButtonLoadingCover>
								<DotsLoadingComponent color="#fff" />
							</SubmitButtonLoadingCover>
						)}
					</SubmitButton>
				</ButtonBlock>

				<BackButtonBlock>
					<BackButtonLabel>
						New Customer?
					</BackButtonLabel>
					<BackButton>
						<Link to="/registration">
							Register here
						</Link>
					</BackButton>
				</BackButtonBlock>
			</CardWrapper>
    </Wrapper>
  )
};

export default LoginForm;
