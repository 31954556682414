import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setShowSearch } from '../../store/appConfigsSlice';

import { SeoTags } from '../../components';
import { CashBackInfo } from '../../components/info/CashbackComponent';

const CashbackPage = () => {
	const dispatch = useDispatch();

	useEffect (() => {
		dispatch(setShowSearch(false))
	}, []);

	return (	
		<>
			<SeoTags pageKey="cashbackPage" />
			<CashBackInfo />
		</>
	);
};

export default withTranslation()(CashbackPage);