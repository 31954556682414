import styled from "styled-components";

export const RouteBlock = styled("div")(({ theme }) => ({
  // ...theme.margins(8, 0),

  // "&:nth-of-type(1)": {
    // ...theme.marginsY(2, 0),
  // },
}));

export const RouteCities = styled("div")(({ theme }) => ({
  fontSize: 14,
  marginBottom: 4,
  color: "#151515",
}));
