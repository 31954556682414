import { useCallback, useState } from "react";

import { useFormik } from "formik";
import i18next from "i18next";
import * as Yup from "yup";

import { Link } from "react-router-dom";
import { registration } from "../../../protocols";

import { useAxiosError, useEventListener } from "../../../hooks";

import { DotsLoadingComponent, InputComponent } from "../../ui";

import {
	BackButton,
	BackButtonBlock,
	BackButtonLabel,
	ButtonBlock,
	CardWrapper,
	FormSubtitle,
	FormTitle,
	SubmitButton,
	SubmitButtonLoadingCover,
	Wrapper,
} from "./style";

const RegistrationForm = ({ onChangeStep, onChangeClientId }) => {
  const { language, t } = i18next;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useAxiosError();

  const validationSchema = Yup.object().shape({
    login: Yup.string().trim().email(t("enter_valid_mail")).required(t("required")),
    first_name: Yup.string().trim().min(2, t("min_2_symbol")).required(t("required")),
    last_name: Yup.string().trim().min(2, t("min_2_symbol")).required(t("required")),
  });

  const handleFormSubmit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    registration({ ...values, language })
      .then((response) => {
        onChangeStep("activation");
        onChangeClientId(response.data?.client_id);
      })
      .catch(error => {
        console.log({ error });
        setFieldError("login", error?.response?.data?.error_code === 3 ? "Пользователь с данным E-mail уже существует!" : error?.response?.data?.error_desc);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik(
    {
      initialValues: {
        login: "",
        first_name: "",
        last_name: "",
      },
      validationSchema,
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
    }
);

  const handleKeyPress = useCallback(e => {
    if (e.key === "Enter" || e.keyCode === 13) {
        handleSubmit();
    }
  }, [handleSubmit]);

  useEventListener("keypress", handleKeyPress);

  return (
		<Wrapper>
			<CardWrapper>
				<FormTitle>{t("registration")}</FormTitle>

				<FormSubtitle>{t("register_to_save_data")}</FormSubtitle>

				<InputComponent
					value={values.login}
					onChange={handleChange("login")}
					type="email"
					placeholder={t("enter_email")}
					label={t("email")}
					error={errors.login}
					onBlur={handleBlur("login")}
					touched={touched.login}
					required
				/>

				<InputComponent
					value={values.first_name}
					onChange={handleChange("first_name")}
					type="text"
					placeholder={t("enter_name")}
					label={t("first_name")}
					error={errors.first_name}
					onBlur={handleBlur("first_name")}
					touched={touched.first_name}
					required
				/>

				<InputComponent
					value={values.last_name}
					onChange={handleChange("last_name")}
					type="text"
					placeholder={t("enter_surname")}
					label={t("last_name")}
					error={errors.last_name}
					onBlur={handleBlur("last_name")}
					touched={touched.last_name}
					required
				/>

				<ButtonBlock>
					<SubmitButton
						onClick={handleSubmit}
						type="button"
					>
						{t("continue")}
						{isSubmitting && (
							<SubmitButtonLoadingCover>
								<DotsLoadingComponent color="#fff" />
							</SubmitButtonLoadingCover>
						)}
					</SubmitButton>
				</ButtonBlock>

				<BackButtonBlock>
					<BackButtonLabel>
						Already have an account?
					</BackButtonLabel>
					<BackButton>
						<Link to="/auth">
							Login here
						</Link>
					</BackButton>
				</BackButtonBlock>
			</CardWrapper>
		</Wrapper>
  )
};

export default RegistrationForm;
