import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flightType: "RT",
  cabin: "all",
  passengers: {
    adt: 1,
    chd: 0,
    ins: 0,
    inf: 0,
  },
  routes: [
    {
      from: null,
      to: null,
      date: null,
    },
    {
      from: null,
      to: null,
      date: null,
    },
  ],
  notFilledFields: {
    from: false,
    to: false,
    date: false,
  },
};

export const searchParamsSlice = createSlice({
  name: "searchParams",
  initialState,
  reducers: {
    setSearchParams: (state, action) => ({ ...state, ...action.payload }),
    setFlightType: (state, action) => ({
      ...state,
      flightType: action.payload,
    }),
    setCabin: (state, action) => ({ ...state, cabin: action.payload }),
    setPassengers: (state, action) => ({
      ...state,
      passengers: action.payload,
    }),
    setRoutes: (state, action) => ({ ...state, routes: action.payload }),
    addRoute: state => ({
      ...state,
      routes: [...state.routes, { from: null, to: null, date: null }],
    }),
    changeRoute: (state, { payload: { index, data } }) => ({
      ...state,
      routes: state.routes.map((route, ind) => (ind === index ? { ...route, ...data } : route)),
    }),
    removeRoute: (state, action) => ({
      ...state,
      routes: state.routes.filter((_, index) => index !== action.payload),
    }),
    removeExtraRoutes: state => ({
      ...state,
      routes: state.routes.filter((_, index) => index < 2),
    }),
  },
});

export const {
  setSearchParams,
  setFlightType,
  setCabin,
  setPassengers,
  setRoutes,
  addRoute,
  changeRoute,
  removeRoute,
  removeExtraRoutes,
} = searchParamsSlice.actions;

export default searchParamsSlice.reducer;
