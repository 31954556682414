import i18next from "i18next";

import { BlockTitle, PassengersBlock, Wrapper } from "./style";
import { useSelector } from "react-redux";
import OrderPassengerItemComponent from "../OrderPassengerItemComponent";

const OrderPassengersComponent = () => {
  const { t } = i18next;
  const {
    orderData: { passenger_data: passengers },
  } = useSelector(state => state.order);

  return (
    <Wrapper>
      <BlockTitle>{t("passengers")}</BlockTitle>
      <PassengersBlock>
        {passengers?.map(passenger => (
          <OrderPassengerItemComponent key={passenger.id} data={passenger} />
        ))}
      </PassengersBlock>
    </Wrapper>
  );
};

export default OrderPassengersComponent;
