import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { RiCloseLine, RiArrowUpDownLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_OW, FLIGHT_TYPE_RT } from "../../../constants";

import { encodeSearchId } from "../../../utils";

import { addRoute, changeRoute, removeRoute, setRoutes, setSearchParams } from "../../../store/searchParamsSlice";

import SearchFlightTypeSelectComponent from "../SearchFlightTypeSelectComponent";
import SearchAirportSelectComponent from "../SearchAirportSelectComponent";
import SearchDatesSelectComponent from "../SearchDatesSelectComponent";
import SearchClassPassengersSelectComponent from "../SearchClassPassengersSelectComponent";

import {
  AddRouteButton,
  FormBlock,
  RemoveRouteButton,
  RouteBlock,
  SearchButton,
  SearchButtonBlock,
  SearchFormBlock,
  SelectAirportsFromToBlock,
  SwapAirportButton,
  Wrapper,
} from "./style";
import i18next from "i18next";

const SearchFormComponent = () => {
  const { flightType, cabin, passengers, routes } = useSelector(store => store.searchParams);
  const { searchBlockCompact } = useSelector(store => store.appConfigs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = i18next;

  const handleAddRoute = () => {
    if (routes.length < 4) {
      dispatch(addRoute());
    }
  };

  const handleRemoveRoute = index => {
    dispatch(removeRoute(index));
  };

  const handleAirportSelect = (routeIndex, type, newAirport) => {
    dispatch(changeRoute({ index: routeIndex, data: { [type]: newAirport } }));
  };

  const handleClearAirport = (routeIndex, type) => {
    dispatch(changeRoute({ index: routeIndex, data: { [type]: null } }));
  };

  const handleChangeDate = (routeIndex, date) => {
    dispatch(changeRoute({ index: routeIndex, data: { date } }));
  };

  const handleSearch = () => {
    let currentRoutes = [...routes];
    let currentFlightType = flightType;

    if (flightType === FLIGHT_TYPE_CF) {
      currentRoutes = currentRoutes.filter(item => {
        return item.from && item.to && item.date;
      });
    }

    if (currentRoutes[0]?.from && currentRoutes[0]?.to && currentRoutes[0]?.date) {
      const searchId = encodeSearchId(flightType, cabin, currentRoutes, passengers);

      if (currentFlightType === FLIGHT_TYPE_CF) {
        if (currentRoutes.length === 1) {
          dispatch(
            setSearchParams({
              flightType: FLIGHT_TYPE_OW,
              routes: [
                ...currentRoutes,
                {
                  from: null,
                  to: null,
                  date: null,
                },
              ],
            })
          );
          currentFlightType = FLIGHT_TYPE_OW;
        }

        if (
          currentRoutes.length === 2 &&
          currentRoutes[0].from === currentRoutes[1].to &&
          currentRoutes[0].to === currentRoutes[1].from
        ) {
          dispatch(setSearchParams({ flightType: FLIGHT_TYPE_RT }));
          currentFlightType = FLIGHT_TYPE_RT;
        }
      }

      navigate(`/result/${searchId}`);
    } else {
      dispatch(
        setSearchParams({
          notFilledFields: {
            from: !!!routes[0].from,
            to: !!!routes[0].to,
            date: !!!routes[0].date,
          },
        })
      );

      setTimeout(() => {
        dispatch(
          setSearchParams({
            notFilledFields: {
              from: false,
              to: false,
              date: false,
            },
          })
        );
      }, 3000);
    }
  };

  const handleReplaceRoute = () => {
    let currentRoutes = JSON.parse(JSON.stringify(routes));
    const newRoutes = [];

    newRoutes.push({
        from: currentRoutes[0].to,
        to: currentRoutes[0].from,
        date: currentRoutes[0].date,
    });

    newRoutes.push({
        from: currentRoutes[1].to,
        to: currentRoutes[1].from,
        date: currentRoutes[1].date,
    });

    dispatch(setRoutes(newRoutes));
  };

  return (
    <Wrapper $isCompact={searchBlockCompact}>
      <SearchFormBlock>
        <SearchFlightTypeSelectComponent />

        {flightType !== FLIGHT_TYPE_CF && (
          <FormBlock flightType={flightType}>
            <SelectAirportsFromToBlock>
              <SearchAirportSelectComponent
                type="from"
                flightType={flightType}
                airport={routes[0].from}
                onAirportSelect={handleAirportSelect}
                onClearAirport={handleClearAirport}
                routeIndex={0}
              />

              <SwapAirportButton onClick={handleReplaceRoute}>
                <RiArrowUpDownLine />
              </SwapAirportButton>

              <SearchAirportSelectComponent
                type="to"
                flightType={flightType}
                airport={routes[0].to}
                onAirportSelect={handleAirportSelect}
                onClearAirport={handleClearAirport}
                routeIndex={0}
              />
            </SelectAirportsFromToBlock>

            <SearchDatesSelectComponent
              flightType={flightType}
              onChangeDate={handleChangeDate}
              fromActiveDate={routes[0]?.date}
              toActiveDate={routes[1]?.date}
              selectedDays={routes.map(route => route.date)}
            />

            <SearchClassPassengersSelectComponent flightType={flightType} />
          </FormBlock>
        )}

        {flightType === FLIGHT_TYPE_CF && (
          <FormBlock flightType={flightType}>
            {routes.map((route, index) => (
              <RouteBlock key={`route-${index}-${Math.random(99999)}`}>
                {index > 0 && routes.length > 2 && (
                  <RemoveRouteButton onClick={() => handleRemoveRoute(index)}>
                    <RiCloseLine />
                  </RemoveRouteButton>
                )}
                <SelectAirportsFromToBlock>
                  <SearchAirportSelectComponent
                    type="from"
                    flightType={flightType}
                    airport={route.from}
                    onAirportSelect={handleAirportSelect}
                    onClearAirport={handleClearAirport}
                    routeIndex={index}
                  />

                  <SearchAirportSelectComponent
                    type="to"
                    flightType={flightType}
                    airport={route.to}
                    onAirportSelect={handleAirportSelect}
                    onClearAirport={handleClearAirport}
                    routeIndex={index}
                  />
                </SelectAirportsFromToBlock>

                <SearchDatesSelectComponent
                  flightType={flightType}
                  routeIndex={index}
                  onChangeDate={handleChangeDate}
                  fromActiveDate={routes[index].date}
                  selectedDays={routes.map(route => route.date)}
                />
              </RouteBlock>
            ))}

            <RouteBlock gap={2} smGap={20}>
              <AddRouteButton onClick={handleAddRoute} disabled={routes.length === 4}>
                + {t("add_route")}
              </AddRouteButton>
              <SearchClassPassengersSelectComponent flightType={flightType} />
            </RouteBlock>
          </FormBlock>
        )}

        <SearchButtonBlock>
          <SearchButton onClick={handleSearch}>{t("find")}</SearchButton>
        </SearchButtonBlock>
      </SearchFormBlock>
    </Wrapper>
  );
};

export default SearchFormComponent;

SearchFormComponent.defaultProps = {
  variant: "full",
};

SearchFormComponent.propTypes = {
  variant: PropTypes.oneOf(["full", "compact"]),
};
