import { useDispatch, useSelector } from "react-redux";

import FilterGroupComponent from "../FilterGroupComponent";
import { FiltersRangeSliderComponent } from "../../ui";
import { setFilterParams } from "../../../store/resultFiltersSlice";
import { RouteBlock, RouteCities } from "./style";
import i18next from "i18next";

const FilterDurationComponent = () => {
  const { language, t } = i18next;
  const { included } = useSelector(state => state.resultData);
  const {
    filterParams: {
      duration: { values },
    },
    filtersData,
  } = useSelector(state => state.resultFilters);
  const { routesData } = filtersData;

  const dispatch = useDispatch();

  const handleChange = (routeIndex, newValues) => {
    let params = [...values];
    const { duration } = routesData.find(route => route.index === routeIndex);

    if (duration.defaultRange[0] !== newValues.min || duration.defaultRange[1] !== newValues.max) {
      const index = params.findIndex(param => param.routeIndex === routeIndex);

      if (index > -1) {
        params = params.map((param, ind) => (ind === index ? { ...param, values: newValues } : param));
      } else {
        params.push({ routeIndex, values: newValues });
      }
    } else {
      params = params.filter(param => param.routeIndex !== routeIndex);
    }

    dispatch(
      setFilterParams({
        type: "duration",
        values: {
          isActive: params.length > 0,
          values: params,
        },
      })
    );
  };

  const renderDurationTime = duration => {
    const hours = Math.trunc(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);

    return `${hours} ${t("hour_short")} ${minutes} ${t("minutes_short")}`;
  };

  const renderRoute = route => {
    const { defaultRange } = route.duration;
    const rangeValues = values.find(value => value.routeIndex === route.index)?.values || {
      min: defaultRange[0],
      max: defaultRange[1],
    };

    return (
      <RouteBlock key={`${route.routeIndex}-${route.departureCityCode}-${route.arrivalCityCode}`}>
        <RouteCities>
          {included.city[route.departureCityCode].name[language]} →{" "}
          {included.city[route.arrivalCityCode].name[language]}
        </RouteCities>

        <FiltersRangeSliderComponent
          formatLabel={number => renderDurationTime(number)}
          value={rangeValues}
          min={defaultRange[0]}
          max={defaultRange[1]}
          step={300}
          onChange={values => handleChange(route.index, values)}
        />
      </RouteBlock>
    );
  };

  return (
    <FilterGroupComponent title={t("travel_time")}>{routesData.map(route => renderRoute(route))}</FilterGroupComponent>
  );
};

export default FilterDurationComponent;
