import moment from "moment-timezone";
import { formatDateTime, serverTimeZone, webTransferTimeFormat } from "../config";

export const formattedDateWithTimeZone = data => {
  const timeZoneName = moment.tz.guess();
  const serverMomentTime = moment.tz(data, serverTimeZone);

  return serverMomentTime.clone().tz(timeZoneName).format(webTransferTimeFormat);
};

export const formattedDate = data => {
  return moment(data, formatDateTime).format(webTransferTimeFormat);
};
