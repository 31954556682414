import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#dee3e6",
  flexGrow: 1,
}));

export const Block = styled("div")(({ theme }) => ({
  ...theme.mixins.flexJustifyBetween,
  alignItems: "flex-start",
  gap: 20,
  padding: "10px 0",

  [theme.down("lg")]: {
    flexDirection: "column",
  },
}));

export const LoadingBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  width: "100%",
  minHeight: 500,
  backgroundColor: "#dee3e6",
}));

export const ErrorBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  flexDirection: "column",
  width: "100%",
  minHeight: 500,
  backgroundColor: "#dee3e6",
}));

export const SessionBlock = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: "#777",
  marginTop: 5,
}));
