import { useRef, useState } from "react";

import i18next from "i18next";
import moment from "moment";
import { Link } from "react-router-dom";

import { ContainerComponent, DotsLoadingComponent, TableComponent, TableFilters } from "../../ui";

import { LoadingBlock } from "../../../pages/ResultPage/style";

import { NotFoundComponent, TitleComponent, Wrapper } from "./style";
import { formatDateTime } from "../../../config";
import { normalizePrice } from "../../../utils";

const TransactionsComponent = ({ isLoading, transactions, page, lastPage, request }) => {
  const { t } = i18next;

  const [currentPage, setCurrentPage] = useState(page || 1);
  const filters = useRef({});

  const operationTypes = {
    "balance": "Операция с балансом",
    "order": "Оплата заказа",
  };

  const CreatedTime = ({ createdAt }) => {
    const time = moment(createdAt, formatDateTime);

    return (
      <>
        <div>{time.format("DD.MM.YYYY")}</div>
        <div>{time.format("HH:mm:ss")}</div>
      </>
    );
  };

  const handleChangePage = async (pageType) => {
    if (pageType === "next") {
      await request({ ...filters.current, page: currentPage + 1 });
      setCurrentPage(prev => prev + 1);
    }

    if (pageType === "prev") {
      await request({ ...filters.current, page: currentPage - 1 });
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleChangeFilters = async (key, value) => {
    filters.current = { ...filters.current, [key]: value };
    await request({ ...filters.current, page: 1 });
    setCurrentPage(1);
  };

  return (
    <Wrapper>
      <ContainerComponent>
        <TitleComponent>{t("transactions")}</TitleComponent>

        {isLoading && (
          <LoadingBlock>
            <DotsLoadingComponent />
          </LoadingBlock>
        )}

        {(!isLoading) ? (
          <TableFilters
            filters={[
              {
                type: "input",
                key: "order_id",
                placeholder: t("order_num"),
                hasBeforeIcon: true,
              }, {
                type: "select",
                key: "type_operation",
                placeholder: "Тип операции",
                options: [
                  { value: "balance", label: "Операция с балансом" },
                  { value: "order", label: "Оплата заказа" },
                ],
              }, {
                type: "date",
                key: "timestamp_start",
                placeholder: t("date_from_d"),
              }, {
                type: "date",
                key: "timestamp_end",
                placeholder: t("date_to_d"),
              }
            ]}
            onChangeFilters={handleChangeFilters}
          />
        ) : null}
      </ContainerComponent>

      <ContainerComponent fixed={false}>
        {(!isLoading && !transactions?.length) ? (
          <NotFoundComponent>У вас нет транзакций!</NotFoundComponent>
        ) : null}

        {(!isLoading && transactions?.length) ? (
          <TableComponent
            columns={[
              { key: "created_at", label: t("date"), render: (column) => <CreatedTime createdAt={column.created_at} /> },
              { key: "payment_system_name", label: "Эквайринг" },
              { key: "amount", label: t("amount"), render: (column) => `${normalizePrice(column?.amount)} ${column?.currency}` },
              { key: "type_operation", label: "Тип операции", render: (column) => operationTypes[column.type_operation] },
              { key: "payment_status", label: t("status") },
              { key: "order_id", label: t("order_num"), render: (column) => <Link to={`/order/${column.order_id}/${column.session_id}`}>{column.order_id}</Link> },
              { key: "comment", label: t("comment") },
            ]}
            data={transactions}
            minWidth={1250}
            onChangePage={handleChangePage}
            prevButtonDisabled={currentPage === 1}
            nextButtonDisabled={currentPage === lastPage}
          />
        ) : null}
      </ContainerComponent>
    </Wrapper>
  );
};

export default TransactionsComponent;
