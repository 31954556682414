import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
`;

const dropdownIn = keyframes`
  0% {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(0);
  }
`;

const dropdownOut = keyframes`
  0% {
    transform: translateY(0)
  }

  to {
    transform: translateY(-5px)
  }
`;

export const Wrapper = styled("div")(({ theme, active, $width }) => ({
  display: active ? "flex" : "none",
  justifyContent: "center",
  position: "absolute",
  top: "calc(100% + 4px)",
  right: 0,
  zIndex: 151,
  width: "100%",
  minWidth: $width || "100%",
  cursor: "default",

  "& > *": {
    userSelect: "none",
  },
}));

export const DropdownBlock = styled("div")(
  ({ theme, noPadding, $width }) => ({
    display: "block",
    backgroundColor: "#fff",
    borderRadius: 4,
    padding: noPadding ? 0 : 10,
    boxShadow: "0 1px 1px rgba(0,0,0,.1)",
    border: "1px solid #b8b8b8",
    width: $width || "100%",
  }),
  css`
    animation: ${props => (props.active ? dropdownIn : dropdownOut)} 0.2s forwards,
      ${props => (props.active ? fadeIn : fadeOut)} 0.2s forwards;
  `
);
