export const fromInputDefaultAirports = [
  {
    code: "TAS",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Ташкент Международный аэропорт",
      en: "Tashkent International Airport",
			uz: "Toshkent Xalqaro Aeroporti",
    },
    isMain: true,
  },
  {
    code: "SKD",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Самарканд Международный аэропорт",
      en: "Samarkand International Airport",
			uz: "Samarqand Xalqaro Aeroporti",
    },
    isMain: true,
  },
  {
    code: "BHK",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Бухара Международный аэропорт",
      en: "Bukhara International Airport",
			uz: "Buxoro Xalqaro Aeroporti",
    },
    isMain: true,
  },
  {
    code: "FEG",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Фергана Международный аэропорт",
      en: "Fergana International Airport",
			uz: "Farg'ona Xalqaro Aeroporti",
    },
    isMain: true,
  },
  {
    code: "NVI",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Навои Международный аэропорт",
      en: "Navoiy International Airport",
			uz: "Navoiy Xalqaro Aeroporti",
    },
    isMain: true,
  },
  {
    code: "UGC",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Ургенч Международный аэропорт",
      en: "Urgench International Airport",
			uz: "Urganch Xalqaro Aeroporti",
    },
    isMain: true,
  },
  {
    code: "NCU",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Нукус аэропорт",
      en: "Nukus Airport",
			uz: "Nukus Aeroporti",
    },
    isMain: true,
  },
  {
    code: "TMJ",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Термез аэропорт",
      en: "Termez Airport",
			uz: "Termiz Aeroporti",
    },
    isMain: true,
  },
  {
    code: "KSQ",
    countryCode: "UZ",
    countryName: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston",
    },
    name: {
      ru: "Аэропорт Карши",
      en: "Karshi Airport",
			uz: "Qarshi Aeroporti",
    },
    isMain: true,
  },
  {
    code: "DYU",
    countryCode: "TJ",
    countryName: {
      ru: "Таджикистан",
      en: "Tajikistan",
			uz: "Tojikiston",
    },
    name: {
      ru: "Душанбе",
      en: "Dushanbe",
			uz: "Dushanbe",
    },
    isMain: true,
  },
  {
    code: "LBD",
    countryCode: "TJ",
    countryName: {
      ru: "Таджикистан",
      en: "Tajikistan",
			uz: "Tojikiston",
    },
    name: {
      ru: "Худжанд",
      en: "Khudzhand",
			uz: "Xudjand",
    },
    isMain: true,
  },
  {
    code: "MOW",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Москва",
      en: "Moscow",
			uz: "Moskva",
    },
    isMain: true,
  },
  {
    code: "SVO",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Шереметьево",
      en: "Sheremetyevo International",
			uz: "Sheremetyevo Xalqaro Aeroporti",
    },
    isMain: false,
  },
  {
    code: "VKO",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Внуково",
      en: "Vnukovo International",
			uz: "Vnukovo Xalqaro Aeroporti",
    },
    isMain: false,
  },
  {
    code: "DME",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Домодедово",
      en: "Domodedovo",
			uz: "Domodedovo",
    },
    isMain: false,
  },
];

export const toInputDefaultAirports = [
  {
    code: "MOW",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Москва",
      en: "Moscow",
			uz: "Moskva",
    },
    isMain: true,
  },
  {
    code: "SVO",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Шереметьево",
      en: "Sheremetyevo International",
			uz: "Sheremetyevo Xalqaro Aeroporti",
    },
    isMain: false,
  },
  {
    code: "VKO",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Внуково",
      en: "Vnukovo International",
			uz: "Vnukovo Xalqaro Aeroporti",
    },
    isMain: false,
  },
  {
    code: "DME",
    countryCode: "RU",
    countryName: {
      ru: "Россия",
      en: "Russia",
			uz: "Rossiya",
    },
    name: {
      ru: "Домодедово",
      en: "Domodedovo",
			uz: "Domodedovo",
    },
    isMain: false,
  },
  {
    code: "DXB",
    countryCode: "AE",
    countryName: {
      ru: "Объединенные Арабские Эмираты",
      en: "United Arab Emirates",
			uz: "Birlashgan Arab Amirliklari",
    },
    name: {
      ru: "Дубай",
      en: "Dubai",
			uz: "Dubay",
    },
    isMain: true,
  },
  {
    code: "DYU",
    countryCode: "TJ",
    countryName: {
      ru: "Таджикистан",
      en: "Tajikistan",
			uz: "Tojikiston",
    },
    name: {
      ru: "Душанбе",
      en: "Dushanbe",
			uz: "Dushanbe",
    },
    isMain: true,
  },
  {
    code: "IST",
    countryCode: "TR",
    countryName: {
      ru: "Турция",
      en: "Turkey",
			uz: "Turkiya",
    },
    name: {
      ru: "Стамбул",
      en: "Istanbul",
			uz: "Istanbul",
    },
    isMain: true,
  },
  {
    code: "ALA",
    countryCode: "KZ",
    countryName: {
      ru: "Казахстан",
      en: "Kazakhstan",
			tj: "Qozog'iston",
    },
    name: {
      ru: "Алматы",
      en: "Almaty",
			uz: "Almaty"
    },
    isMain: true,
  },
  {
    code: "FRU",
    countryCode: "KG",
    countryName: {
      ru: "Кыргызстан",
      en: "Kyrgyz",
			uz: "Qirg'iziston",
    },
    name: {
      ru: "Бишкек",
      en: "Bishkek",
			uz: "Bishkek",
    },
    isMain: true,
  },
];