import axios from "axios";

import { apiUrl } from "../config";
import { getToken } from "./common";

export const ContentType = {
  Json: "application/json",
  FormData: "multipart/form-data",
  UrlEncoded: "application/x-www-form-urlencoded",
  Text: "text/plain",
};

export const instance = axios.create({ baseURL: apiUrl });

const mergeRequestParams = (params1, params2) => {
  const method = params1.method || (params2 && params2.method);

  return {
    ...instance.defaults,
    ...params1,
    ...(params2 || {}),
    headers: {
      ...((method && instance.defaults.headers[method.toLowerCase()]) || {}),
      ...(params1.headers || {}),
      ...((params2 && params2.headers) || {}),
    },
  };
};

const stringifyFormItem = (formItem) => {
  if (typeof formItem === "object" && formItem !== null) {
    return JSON.stringify(formItem);
  } else {
    return `${formItem}`;
  }
};

const createFormData = (input) => {
  return Object.keys(input || {}).reduce((formData, key) => {
    const property = input[key];
    const propertyContent = property instanceof Array ? property : [property];

    for (const formItem of propertyContent) {
      const isFileType = formItem instanceof Blob || formItem instanceof File;
      formData.append(key, isFileType ? formItem : stringifyFormItem(formItem));
    }

    return formData;
  }, new FormData());
}

export const request = async ({
  secure,
  path,
  type,
  query,
  format,
  body,
  ...params
}) => {
  const secureParams = (typeof secure === "boolean" ? secure : false) ? ({ headers: { Authorization: `Bearer ${getToken()}` } }) : {};
  const requestParams = mergeRequestParams(params, secureParams);
  const responseFormat = format || undefined;

  if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
    body = createFormData(body);
  }

  if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
    body = JSON.stringify(body);
  }

  return instance.request({
    ...requestParams,
    headers: {
      ...(requestParams.headers || {}),
      ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
    },
    params: query,
    responseType: responseFormat,
    data: body,
    url: path,
  });
};
