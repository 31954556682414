import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { RulesOfUse } from "../../components/info/RulesOfUseComponent";

import { setShowSearch } from "../../store/appConfigsSlice";

const RulesOfUsePage = () => {
	const dispatch = useDispatch();

	useEffect (() => {
		dispatch((setShowSearch(false)))
	}, []);

	return (
		<RulesOfUse />
	)
}

export default RulesOfUsePage;