import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  width: 800,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
  borderRight: "1px solid #999",

  [theme.upDown("lg", "xl")]: {
    width: 590,
  },

  [theme.upDown("md", "lg")]: {
    width: 680,
  },

  [theme.upDown("sm", "md")]: {
    width: 550,
  },

  [theme.down("sm")]: {
    width: "100%",
    borderRight: "none",
  }
}));
