export const apiUrl = process.env.REACT_APP_API_URL;
export const uploadsUrl = process.env.REACT_APP_UPLOADS_URL;

export const companyReqId = process.env.REACT_APP_COMPANY_REQUISITES_ID;

export const appDefaultLanguage = "ru";
export const serverTimeZone = "Asia/Dushanbe";

export const formatDateTime = "YYYY-MM-DD HH:mm:ss";
export const webTransferTimeFormat = "DD.MM.YYYY HH:mm";

export const friendlyCountriesCodes = ["RU", "AM", "BY", "АЦ", "KZ", "ЮО", "KG"];

export const phoneMask = [
  "+",
  /\d/,
  /\d/,
  /\d/,
  " ",
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];
