import { Link } from "react-router-dom";
import styled from 'styled-components';

export const Wrapper = styled("div")(({ theme }) => ({
	...theme.mixins.flexColumn,
	width: "100%",
	maxWidth: "1220px",
	margin: "10px auto",
	padding: "0 20px",

	[theme.down("sm")]: {
		maxWidth: "600px",
		padding: "0 20px",
	},

	[theme.down("lg")]: {
		maxWidth: "950px"
	}
}));

export const LinkItem = styled((props) => (
  <div style={{margin: "10px 0"}}>
    <Link {...props} />
  </div>
))(({ theme }) => ({
	fontSize: 15,
  lineHeight: "30px",
  transition: "color .3s ease-in-out",

  "&: hover": {
    color: "#01A0D4",
  }
}));

export const Title = styled("h1")(({theme}) => ({
	fontSize: "32px",
	fontWeight: 600,
	lineHeight: "normal",

	[theme.down("md")]: {
		textSize: "24px",
	},

	[theme.down("sm")]: {
		textSize: "20px",
	}
}));

export const Subtitle = styled("h2")(({theme}) => ({
	fontSize: "24px",
	fontWeight: 500,
	lineHeight: "normal",
	margin: "20px 0",

	"& .link": {
		color: theme.palette.footerBg,
		transition: "all .3s ease-in-out",

		"&:hover": {
			color: theme.palette.searchButtonBg,
		},
	},
}));

export const CardSection = styled("div")(({theme}) => ({
	display: "flex",
	flexWrap: "wrap",
	gap: "30px",

	margin: "20px 0",

	[theme.down("md")]: {
		justifyContent: "center",
	},
}));