import FilterAirlinesComponent from "../FilterAirlinesComponent";
import FilterAirportsComponent from "../FilterAirportsComponent";
import FilterArrivalTimeComponent from "../FilterArrivalTimeComponent";
import FilterBaggageComponent from "../FilterBaggageComponent";
import FilterDepartureTimeComponent from "../FilterDepartureTimeComponent";
import FilterDurationComponent from "../FilterDurationComponent";
import FilterPriceRangeComponent from "../FilterPriceRangeComponent";
import FilterSortingComponent from "../FilterSortingComponent";
import FilterTransfersComponent from "../FilterTransfersComponent";

import { Wrapper } from "./style";

const FilterBlockComponent = () => {
  return (
    <Wrapper>
      <FilterPriceRangeComponent />
      <FilterSortingComponent />
      <FilterTransfersComponent />
      <FilterBaggageComponent />
      <FilterAirlinesComponent />
      <FilterDepartureTimeComponent />
      <FilterArrivalTimeComponent />
      <FilterDurationComponent />
      <FilterAirportsComponent />
    </Wrapper>
  );
};

export default FilterBlockComponent;
