import { ContentType, request } from "../api";
import { baseParams } from "../common";

export const login = async (data = {}, params = {}) => request({
  path: "/login",
  method: "POST",
  body: { ...baseParams, ...data },
  type: ContentType.Json,
  format: "json",
  ...params,
});
