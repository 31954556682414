import _ from "lodash";
import moment from "moment";
import { webTransferTimeFormat } from "../config";
import { paramsToObject } from "./common";
import { BY_MAX_PRICE } from "../constants";

export const getDataForFilter = recommendations => {
  const templateRoutesData = getRoutesCity(_.first(recommendations));

  let routesData = _.cloneDeep(templateRoutesData);

  _.map(recommendations, recommendation => {
    const { routes } = recommendation;

    _.map(routes, route => {
      const { segments, index } = route;
      const segmentDeparture = _.first(segments).departure;
      const segmentArrival = _.last(segments).arrival;
      const departureAirport = segmentDeparture.airport;
      const arrivalAirport = segmentArrival.airport;
      const departureTime = segmentDeparture.time;
      const arrivalTime = segmentArrival.time;

      let departureAirportsArray = routesData[index].departureAirports;
      let arrivalAirportsArray = routesData[index].arrivalAirports;

      if (
        _.findIndex(departureAirportsArray, o => {
          return o === departureAirport;
        }) === -1
      ) {
        routesData[index].departureAirports = [...departureAirportsArray, departureAirport];
      }

      if (
        _.findIndex(arrivalAirportsArray, o => {
          return o === arrivalAirport;
        }) === -1
      ) {
        routesData[index].arrivalAirports = [...arrivalAirportsArray, arrivalAirport];
      }

      routesData[index].departureTime = {
        defaultRange: [...routesData[index].departureTime.defaultRange, moment(departureTime, webTransferTimeFormat)],
      };

      routesData[index].arrivalTime = {
        defaultRange: [...routesData[index].arrivalTime.defaultRange, moment(arrivalTime, webTransferTimeFormat)],
      };

      routesData[index].departureTime.defaultRange = [
        _.min(routesData[index].departureTime.defaultRange),
        _.max(routesData[index].departureTime.defaultRange),
      ];

      routesData[index].arrivalTime.defaultRange = [
        _.min(routesData[index].arrivalTime.defaultRange),
        _.max(routesData[index].arrivalTime.defaultRange),
      ];

      routesData[index].duration = {
        defaultRange: [...routesData[index].duration.defaultRange, route.duration],
      };

      routesData[index].duration.defaultRange = [
        _.min(routesData[index].duration.defaultRange),
        _.max(routesData[index].duration.defaultRange),
      ];
    });
  });

  return [templateRoutesData, routesData];
};

export const getPriceRange = (recommendations, currency) => {
  const sortingRecommendations = sortByMinPrice(recommendations, currency);

  return {
    min: Math.floor(_.first(sortingRecommendations).total_price[currency]),
    max: Math.ceil(_.last(sortingRecommendations).total_price[currency]),
  };
};

export const sortByMinPrice = (recommendations, currency) => {
  return _.sortBy(recommendations, currency, [
    function (rec) {
      return rec["total_price"][currency];
    },
  ]);
};

export const sortByMaxPrice = (recommendations, currency) => {
  return _.sortBy(recommendations, currency, [
    function (rec) {
      return rec["total_price"][currency];
    },
  ]).reverse();
};

export const decodeParamsFromUrl = priceRange => {
  const search = window.location.search;
  let searchParams = new URLSearchParams(search);
  searchParams = paramsToObject(searchParams.entries());

  let result = {};

  if (searchParams.withBaggage || searchParams.withoutBaggage) {
    result["baggage"] = {
      isActive: true,
      values: {
        withBaggage: searchParams.withBaggage !== "0",
        withoutBaggage: searchParams.withoutBaggage !== "0",
      },
    };
  }

  if (searchParams.withoutTransfers || searchParams.oneTransfer || searchParams.manyTransfers) {
    result["transfers"] = {
      isActive: true,
      values: {
        withoutTransfers: searchParams.withoutTransfers !== "0",
        oneTransfer: searchParams.oneTransfer !== "0",
        manyTransfers: searchParams.manyTransfers !== "0",
      },
    };
  }

  if (searchParams?.sorting === BY_MAX_PRICE) {
    result["sorting"] = {
      isActive: true,
      value: BY_MAX_PRICE,
    };
  }

  if (searchParams.airlines) {
    result["airlines"] = {
      isActive: true,
      values: searchParams.airlines.split(","),
    };
  }

  if (searchParams.price) {
    const prices = searchParams.price.split("-");
    if (prices.length === 2) {
      result["price"] = {
        isActive: true,
        values: {
          min: parseFloat(prices[0]) >= priceRange.min ? parseFloat(prices[0]) : priceRange.min,
          max: parseFloat(prices[1]) <= priceRange.max ? parseFloat(prices[1]) : priceRange.max,
        },
      };
    }
  } else {
    result["price"] = {
      isActive: false,
      values: { min: priceRange.min, max: priceRange.max },
    };
  }

  if (searchParams.flightNumbers) {
    result["flightNumbers"] = {
      isActive: true,
      values: searchParams.flightNumbers.split(","),
    };
  }

  if (searchParams.airports) {
    const routes = searchParams.airports.split(";");

    if (routes.length > 0) {
      let airports = {
        isActive: true,
        values: [],
      };

      routes.forEach(route => {
        const [key, value] = route.split("-");
        if (key) {
          const [routeIndex, type] = key.split("_");

          if (!!airports.values.find(route => route.routeIndex === routeIndex)) {
            airports.values = airports.values.map(airport => {
              if (airport.routeIndex === routeIndex) {
                return {
                  routeIndex: parseInt(routeIndex),
                  arrivals: type === "arrivals" ? value.split(",") : airport.arrivals,
                  departures: type === "departures" ? value.split(",") : airport.departures,
                };
              }

              return airport;
            });
          } else {
            airports.values.push({
              routeIndex: parseInt(routeIndex),
              arrivals: type === "arrivals" ? value.split(",") : [],
              departures: type === "departures" ? value.split(",") : [],
            });
          }
        }
      });

      result["airports"] = airports;
    }
  }

  if (searchParams.duration) {
    const duration = searchParams.duration.split(";");

    if (duration.length > 0) {
      let durations = {
        isActive: true,
        values: [],
      };

      duration.forEach(value => {
        const [routeIndex, values] = value.split("_");

        if (routeIndex) {
          const [min, max] = values.split("-");

          durations.values.push({
            routeIndex: parseInt(routeIndex),
            values: { min, max },
          });
        }
      });

      result["duration"] = durations;
    }
  }

  if (searchParams.departureTime) {
    const departureTime = searchParams.departureTime.split(";");

    if (departureTime.length > 0) {
      let departureResults = {
        isActive: true,
        values: [],
      };

      departureTime.forEach(value => {
        const [routeIndex, values] = value.split("_");

        if (routeIndex) {
          const [min, max] = values.split("-");

          departureResults.values.push({
            routeIndex: parseInt(routeIndex),
            values: { min, max },
          });
        }
      });

      result["departureTime"] = departureResults;
    }
  }

  if (searchParams.arrivalTime) {
    const arrivalTime = searchParams.arrivalTime.split(";");

    if (arrivalTime.length > 0) {
      let arrivalResuls = {
        isActive: true,
        values: [],
      };

      arrivalTime.forEach(value => {
        const [routeIndex, values] = value.split("-");

        if (routeIndex) {
          const [min, max] = values.split("-");

          arrivalResuls.values.push({
            routeIndex: parseInt(routeIndex),
            values: { min, max },
          });
        }
      });

      result["arrivalTime"] = arrivalResuls;
    }
  }

  if (searchParams.pricesTable) {
    const params = searchParams.pricesTable.split("-");

    if (params.length === 3) {
      result["pricesTable"] = {
        isActive: true,
        values: {
          type: params[0],
          supplier: params[1],
          price: params[2],
        },
      };
    }
  }

  return result;
};

const getRoutesCity = recommendation => {
  const { routes } = recommendation;

  return routes.map((route, index) => {
    const departureCityCode = _.first(route.segments).departure.city;
    const arrivalCityCode = _.last(route.segments).arrival.city;

    return {
      index,
      departureCityCode,
      arrivalCityCode,
      departureAirports: [],
      arrivalAirports: [],
      duration: {
        defaultRange: [],
      },
      departureTime: {
        defaultRange: [],
      },
      arrivalTime: {
        defaultRange: [],
      },
    };
  });
};
