import ru from "./ru";
import en from "./en";
import tj from "./tj";
import uz from "./uz";

const translations = {
  ru: { translation: ru },
  en: { translation: en },
  tj: { translation: tj },
  uz: { translation: uz },
};

export default translations;
