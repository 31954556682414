import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#dee3e6",
  flexGrow: 1,
  paddingBottom: 50,
}));

export const TitleComponent = styled("div")(({ theme }) => ({
  ...theme.paddings(15, 0),
  fontSize: 32,
  fontWeight: 600,
}));

export const NotFoundComponent = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  minHeight: 128,
  textAlign: "center",
  fontSize: 20,
}));
