import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const CountryItem = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  ...theme.paddings(7, 10),
  gap: 5,
  cursor: "pointer",
  color: "#151515",
  fontSize: 14,

  "&:hover": {
    backgroundColor: "#f2fbff",
  },
}));

// export const CountryItem = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 8px 16px;
//   font-size: 14px;
//   position: relative;
//   cursor: pointer;

//   &:hover {
//     background: ${ORANGE_COLOR}4D;
//   }

//   &:active {
//     background: ${ROMANTIC_COLOR};
//   }

//   &:nth-of-type(1) {
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
//   }

//   &:nth-last-of-type(1) {
//     border-bottom-left-radius: 5px;
//     border-bottom-right-radius: 5px;
//   }
// `;

export const CountryLabel = styled("span")(({ theme }) => ({
  color: "#333",
  fontSize: 13,
}));

export const CountryFlag = styled("div")(({ theme, backgroundImage }) => ({
  width: 24,
  height: 20,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "20px 24px",
  backgroundImage: `url(${backgroundImage})`
}));

export const SelectedCountryFlag = styled(CountryFlag)(({ theme }) => ({
  position: "absolute",
  bottom: 15,
  right: 15,
}));

// export const SelectedCountryFlag = styled(CountryFlag)`
//   position: absolute;
//   bottom: 22px;
//   right: 16px;

//   @media (max-width: 899px) {
//     right: 8px;
//     bottom: 15px;
//   }
// `;
