import { createSlice } from "@reduxjs/toolkit";
import { handleFilterParamsChange } from "../utils";
import { BY_MIN_PRICE } from "../constants";

const initialState = {
  recommendations: [],
  brandFares: {},
  brandRules: {},
  included: {},
  filterParams: {
    baggage: {
      withBaggage: true,
      withoutBaggage: true,
    },
    transfers: {
      withoutTransfers: true,
      oneTransfers: true,
      manyTransfers: true,
    },
    allTransfers: true,
    sortType: BY_MIN_PRICE,
    airlines: [],
    airports: false,
    duration: false,
    arrivalTime: false,
    departureTime: false,
    flightNumbers: false,
    routesData: [],
    priceRange: {},
    flightNumbersList: null,
    recId: null,
  },
  session: null,
  isLoad: false,
};

export const resultDataSlice = createSlice({
  name: "resultData",
  initialState,
  reducers: {
    setRecommendations: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setBrandFares: (state, action) => ({
      ...state,
      brandFares: {
        ...state.brandFares,
        [action.payload.recId]: action.payload.flights,
      },
    }),
    setBrandRules: (state, action) => ({
      ...state,
      brandRules: {
        ...state.brandRules,
        [action.payload.recId]: action.payload.data,
      },
    }),
    setBrandFareRecId: (state, action) => ({
      ...state,
      recommendations: state.recommendations.map(recommendation =>
        recommendation.rec_id === action.payload.recId
          ? { ...recommendation, brandRecId: action.payload.brandRecId }
          : recommendation
      ),
    }),
    setAdditionallyOptions: (state, action) => ({
      ...state,
      recommendations: state.recommendations.map(recommendation => {
        if (recommendation.rec_id === action.payload.recId) {
          return {
            ...recommendation,
            routes: recommendation.routes.map(item =>
              item.index === action.payload.routeIndex
                ? { ...item, [action.payload.type]: action.payload.status }
                : item
            ),
          };
        }

        return recommendation;
      }),
    }),
    setFilters: (state, action) => {
      handleFilterParamsChange(action.payload);

      return {
        ...state,
        filterParams: { ...state.filterParams, [action.payload.type]: action.payload.status },
      };
    },
  },
});

export const {
  setRecommendations,
  setBrandFares,
  setBrandRules,
  setBrandFareRecId,
  setAdditionallyOptions,
  setFilters,
} = resultDataSlice.actions;

export default resultDataSlice.reducer;
