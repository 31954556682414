import moment from "moment";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_OW, FLIGHT_TYPE_RT } from "../constants";
import { parserLangCode } from "./common";
import { getCities } from "../protocols";

const getPassengersCode = passengers => {
  let result = `${passengers.adt}`;

  if (passengers.chd > 0) {
    result = `${result}${passengers.chd}`;
  }

  if (passengers.ins > 0) {
    result = `${result}${passengers.chd === 0 ? 0 : ""}${passengers.ins}`;
  }

  if (passengers.inf > 0) {
    result = `${result}${passengers.chd === 0 ? 0 : ""}${passengers.ins === 0 ? 0 : ""}${passengers.inf}`;
  }

  return result;
};

export const encodeSearchId = (flightType, flightCabin, flightRoutes, flightPassengers) => {
  const cabin = flightCabin.charAt(0).toUpperCase();
  const passengersCode = getPassengersCode(flightPassengers);

  let newFlightType = FLIGHT_TYPE_OW;

  if (flightType === FLIGHT_TYPE_RT) {
    if (flightRoutes[1].date !== null) {
      newFlightType = FLIGHT_TYPE_RT;
    }
  } else if (flightType === FLIGHT_TYPE_CF) {
    newFlightType = flightType;
    if (flightRoutes.length === 1) {
      newFlightType = FLIGHT_TYPE_OW;
    }

    if (flightRoutes.length === 2) {
      if (
        flightRoutes[0].from.code === flightRoutes[1].to.code &&
        flightRoutes[0].to.code === flightRoutes[1].from.code
      ) {
        if (flightRoutes[1].date !== null) {
          newFlightType = FLIGHT_TYPE_RT;
        }
      }
    }
  }

  if (newFlightType === FLIGHT_TYPE_OW) {
    const from = flightRoutes[0].from.code;
    const to = flightRoutes[0].to.code;
    const date = moment(flightRoutes[0].date).format("DDMM");

    return `${newFlightType}-${from}${date}${to}-${cabin}${passengersCode}`;
  } else if (newFlightType === FLIGHT_TYPE_RT) {
    const from = flightRoutes[0].from.code;
    const to = flightRoutes[0].to.code;
    const date = moment(flightRoutes[0].date).format("DDMM");
    const date_to = moment(flightRoutes[1].date).format("DDMM");

    return `${newFlightType}-${from}${date}${to}${date_to}-${cabin}${passengersCode}`;
  } else {
    let searchId = `${newFlightType}-`;
    flightRoutes.forEach(value => {
      const from = value.from.code;
      const to = value.to.code;
      const date = moment(value.date).format("DDMM");
      searchId += `${from}${date}${to}-`;
    });

    return `${searchId}${cabin}${passengersCode}`;
  }
};

export const decodeSearchId = async (searchId, language) => {
  const data = searchId.split("-");

  if (data.length > 0) {
    const flightType = data[0];
    const searchParams = await getSearchParams(flightType, data, language);
    const passengersData = getPassengersData(searchParams.passengers);
    const formField = getFormField(flightType, searchParams);

    return {
      formField,
      passengersData,
      searchParams,
    };
  } else {
    throw new Error({
      errorCode: "Validate",
      errorDesc: "Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!",
    });
  }
};

export const getDateFromSearchId = date => {
  const today = moment();
  const currentYear = today.get("year");
  const selectedDate = `${currentYear}-${date.substr(2)}-${date.substr(0, 2)}`;
  const momentSelectedDate = moment(selectedDate);
  const format = "YYYY-MM-DD";

  if (
    momentSelectedDate.isSameOrAfter(today.format(format)) &&
    momentSelectedDate.isSameOrBefore(today.add(9, "M").format(format))
  ) {
    return selectedDate;
  }

  const nextYear = momentSelectedDate.add(1, "y");

  if (moment(nextYear).isSameOrAfter(today.add(9, "M").format(format))) {
    throw new Error({
      errorCode: "Validate",
      errorDesc: "Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!",
    });
  }

  return nextYear.format(format);
};

export const getCabinFromSearchId = cabinCode => {
  switch (cabinCode) {
    case "A":
      return "all";
    case "E":
      return "economy";
    case "B":
      return "business";
    case "F":
      return "first";
    default:
      return "all";
  }
};

const getLoadingDataFromSearchId = async (routes, language) => {
  const loadingData = [];
  const fullData = [];

  for (let i = 0; i < routes.length; i++) {
    const from = await getCityInfoByCode(routes[i].from, language);
    const to = await getCityInfoByCode(routes[i].to, language);
    loadingData.push(getLoadingRoute(from, to, moment(routes[i].date)));
    fullData.push({ from, to, date: routes[i].date });
  }

  return { loadingData, fullData };
};

export const getCityInfoByCode = async (code, language) => {
  const cities = await getCities(code, language, 50);

  if (cities?.length > 0) {
    const result = cities.filter(city => city.code === code);

    if (result?.length > 0) {
      return result[0];
    }
  } else {
    throw new Error({
      errorCode: "Validate",
      errorDesc: "Указанный город или аэропорт не найден. Проверьте правильность введенных данных и повторите попытку!",
    });
  }
};

const getLoadingRoute = (from, to, date) => ({
  from: from.name,
  to: to.name,
  date: date.locale(parserLangCode("ru")).format("DD MMM YYYY").replace(".", ""),
});

const getRouteFromSearchId = (flightType, route) => {
  if (flightType === FLIGHT_TYPE_RT) {
    return [
      {
        from: route.substr(0, 3),
        to: route.substr(7, 3),
        date: getDateFromSearchId(route.substr(3, 4)),
      },
      {
        from: route.substr(7, 3),
        to: route.substr(0, 3),
        date: getDateFromSearchId(route.substr(10)),
      },
    ];
  } else {
    return {
      from: route.substr(0, 3),
      to: route.substr(7),
      date: getDateFromSearchId(route.substr(3, 4)),
    };
  }
};

const getFullRouteInfoFromSearchId = (from, to, value) => ({
  date: moment(from.date).unix(),
  date_to: to ? moment(to.date).unix() : null,
  from: {
    code: from.from,
    value: value.from,
  },
  to: {
    code: from.to,
    value: value.to,
  },
});

const getFormField = (flightType, searchParams) => {
  const fields = {
    cabin: searchParams.cabin,
    flightType,
    routes: [],
  };

  if (flightType === FLIGHT_TYPE_OW) {
    fields.routes = [getFullRouteInfoFromSearchId(searchParams.routes[0], null, searchParams.loadingRoutes[0])];
  } else if (flightType === FLIGHT_TYPE_RT) {
    fields.routes = [
      getFullRouteInfoFromSearchId(searchParams.routes[0], searchParams.routes[1], searchParams.loadingRoutes[0]),
    ];
  } else if (flightType === FLIGHT_TYPE_CF) {
    const routes = [];

    for (let i = 0; i < searchParams.routes.length; i++) {
      routes.push(getFullRouteInfoFromSearchId(searchParams.routes[i], null, searchParams.loadingRoutes[i]));
    }

    fields.routes = routes;
  }

  return fields;
};

const getPassengersData = passengers => {
  const passengersCount = passengers.adt + passengers.chd + passengers.ins + passengers.inf;
  return {
    isMaximumInf: passengers.inf >= passengers.adt,
    isMaximumPassengers: passengersCount >= 9,
    passengers,
    passengersCount,
  };
};

export const getPassengersFromSearchId = passengers => {
  const adt = parseInt(passengers.substr(1, 1)) || 1;
  const chd = parseInt(passengers.substr(2, 1)) || 0;
  const ins = parseInt(passengers.substr(3, 1)) || 0;
  const inf = parseInt(passengers.substr(4, 1)) || 0;

  if (adt > 9 || adt + chd + ins + inf > 9 || adt < inf) {
    throw new Error({
      errorCode: "Validate",
      errorDesc: "Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!",
    });
  }

  return { adt, chd, ins, inf };
};

const getSearchParams = async (flightType, data, language) => {
  const params = {
    cabin: "",
    flightType,
    loadingRoutes: [],
    passengers: {},
    routes: [],
  };

  if (data.length < 2) {
    throw new Error({
      errorCode: "Validate",
      errorDesc: "Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!",
    });
  }

  if (!data[2]) {
    data[2] = "A1000";
  }

  if (flightType === FLIGHT_TYPE_OW) {
    params.routes = [getRouteFromSearchId(flightType, data[1])];
    params.cabin = getCabinFromSearchId(data[2].substr(0, 1));
    const { loadingData, fullData } = await getLoadingDataFromSearchId(params.routes, language);
    params.loadingRoutes = loadingData;
    params.passengers = getPassengersFromSearchId(data[2]);
    params.searchRoutes = fullData;
  } else if (flightType === FLIGHT_TYPE_RT) {
    params.routes = [...getRouteFromSearchId(flightType, data[1])];
    params.cabin = getCabinFromSearchId(data[2].substr(0, 1));
    const { loadingData, fullData } = await getLoadingDataFromSearchId(params.routes, language);
    params.loadingRoutes = loadingData;
    params.passengers = getPassengersFromSearchId(data[2]);
    params.searchRoutes = fullData;
  } else if (flightType === FLIGHT_TYPE_CF) {
    const routes = [];
    for (let i = 1; i < data.length - 1; i++) {
      routes.push(getRouteFromSearchId(flightType, data[i]));
    }
    params.routes = routes;
    params.cabin = getCabinFromSearchId(data[data.length - 1].substr(0, 1));
    const { loadingData, fullData } = await getLoadingDataFromSearchId(routes, language);
    params.loadingRoutes = loadingData;
    params.passengers = getPassengersFromSearchId(data[data.length - 1]);
    params.searchRoutes = fullData;
  } else {
    throw new Error({
      errorCode: "Validate",
      errorDesc: "Не правильно указанные данные. Проверьте правильность введенных данных и повторите попытку!",
    });
  }

  return params;
};
