import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, $isShowInSmallScreen }) => ({
  ...($isShowInSmallScreen ? { display: "none" } : { display: "flex" }),
  flexDirection: "column",
  gap: 15,

  [theme.down("lg")]: {
    ...($isShowInSmallScreen ? { display: "flex" } : { display: "none" }),
  },
}));

export const CancelButton = styled("button")(({ theme, $isLoading }) => ({
  border: "none",
  borderRadius: 0,
  backgroundColor: "#999",
  color: "#fff",
  fontSize: 20,
  transition: "all .2s ease",
  height: 46,
  width: "100%",
  cursor: "pointer",

  ...($isLoading
    ? {}
    : {
        "&:hover": {
          backgroundColor: "#d3d3d3",
          color: "#545454",
        },
      }),
}));
