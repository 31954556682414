import PropTypes from "prop-types";

import { Wrapper } from "./style";

const Container = ({ children, fixed, flex, ...rest }) => (
  <Wrapper fixed={fixed} flex={flex} {...rest}>
    {children}
  </Wrapper>
);

export default Container;

Container.defaultProps = {
  fixed: true,
  flex: false,
};

Container.propTypes = {
  fixed: PropTypes.bool,
  flex: PropTypes.bool,
};
