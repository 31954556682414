export const defaultCountries = [
  {
    code: "RU",
    country: {
      ru: "Российская Федерация (Россия)",
      en: "Russian Federation",
			uz: "Rossiya Federatsiyasi (Rossiya)",
    },
  },
  {
    code: "KZ",
    country: {
      ru: "Казахстан",
      en: "Kazakhstan",
			uz: "Qozog'iston",
    },
  },
  {
    code: "TR",
    country: {
      ru: "Турция",
      en: "Turkey",
			uz: "Turkiya",
    },
  },
  {
    code: "CN",
    country: {
      ru: "Китай",
      en: "China",
			uz: "Xitoy",
    },
  },
  {
    code: "TJ",
    country: {
      ru: "Республика Таджикистан",
      en: "Tajikistan",
			uz: "Tojikiston",
    },
  },
  {
    code: "UZ",
    country: {
      ru: "Республика Узбекистан",
      en: "Uzbekistan",
			uz: "O'zbekiston Respublikasi",
    },
  },
  {
    code: "KG",
    country: {
      ru: "Кыргызская Республика",
      en: "Kyrgyzstan",
			uz: "Qirg'iziston",
    },
  },
];
