import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { RouterProvider } from "react-router-dom";
import 'moment/locale/ru';

import { store } from "./store";
import theme from "./theme";

import routes from "./routes";

import App from "./App";

import "./index.css";
import { ConfigurationProvider } from "./providers";

import "./i18next.config";
import { AuthProvider } from "./context/auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConfigurationProvider>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RouterProvider router={routes}>
            <App />
          </RouterProvider>
        </AuthProvider>
      </ThemeProvider>
    </ConfigurationProvider>
  </Provider>
);
