import { useState } from "react";

import i18next from "i18next";

import { defaultCountries } from "../../../config";

import { getCountries } from "../../../protocols";

import { InputComponent, DropdownComponent } from "../../ui";
import { CountryFlag, CountryItem, CountryLabel, SelectedCountryFlag, Wrapper } from "./style";

const BookingCitizenshipSelectComponent = ({
  touched,
  error,
  success,
  onChange,
  onBlur,
  clearFieldError,
}) => {
  const { language, t } = i18next;
  const [value, setValue] = useState("");
  const [citizenship, setCitizenship] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [countries, setCountries] = useState(defaultCountries);

  const handleOnSelect = country => {
    setCitizenship(country);
    setValue(country.country[language]);
    onChange(country.code);
    setShowDropdown(false);
  };

  const handleChangeInput = newValue => {
    setValue(newValue);

    if (newValue.length > 1) {
      getCountries(newValue, language).then(response => setCountries(response?.data?.data));
    } else {
      setCountries(defaultCountries);
    }

    if (newValue !== citizenship?.country[language]) {
      setCitizenship(null);
      onChange("");
    }
  };

  const renderCountryItems = () => {
    return countries.map((country, index) => {
      return (
        <CountryItem key={index} onClick={() => handleOnSelect(country)}>
          <CountryFlag backgroundImage={`/flags/${country.code}.png`} />
          <CountryLabel>{country.country[language]}</CountryLabel>
        </CountryItem>
      );
    });
  };

  return (
    <Wrapper>
      <InputComponent
        value={value}
        onChange={handleChangeInput}
        label={t("citizenship")}
        placeholder={t("citizenship")}
        touched={touched}
        error={!showDropdown ? error : null}
        onBlur={onBlur}
        onClick={() => setShowDropdown(true)}
        onFocus={clearFieldError}
        success={success}
        required
      />

      {citizenship && <SelectedCountryFlag backgroundImage={`/flags/${citizenship.code}.png`} />}

      {showDropdown && (
        <DropdownComponent show={showDropdown} onClickOutside={() => setShowDropdown(false)} noPadding>
          {renderCountryItems()}
        </DropdownComponent>
      )}
    </Wrapper>
  );
};

export default BookingCitizenshipSelectComponent;
