import PropTypes from "prop-types";

import SearchFormComponent from "../SearchFormComponent";

import { Wrapper, WrapperContainer } from "./style";
import { useSelector } from "react-redux";

const SearchComponent = ({ variant }) => {
  const { showSearch, searchBlockCompact } = useSelector(state => state.appConfigs);

  if (!showSearch) return null;

  if (variant === "compact") {
    return "compact";
  }

  return (
    <Wrapper variant={searchBlockCompact ? "compact" : "full"}>
      <WrapperContainer>
        <SearchFormComponent variant={variant} />
      </WrapperContainer>
    </Wrapper>
  );
};

export default SearchComponent;

SearchComponent.defaultProps = {
  variant: "full",
};

SearchComponent.propTypes = {
  variant: PropTypes.oneOf(["full", "compact"]),
};