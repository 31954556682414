import { useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/auth";
import { getTransactions } from "../../protocols";
import { setShowSearch } from "../../store/appConfigsSlice";

import { Transactions } from "../../components";

const limit = 10;

const TransactionsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const [transactions, setTransactions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const request = useRef(async (params) => {
    try {
      const result = await getTransactions({ ...params, limit });
      setTransactions(result?.data);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    dispatch(setShowSearch(false));
    request.current();
  }, []);

  if (!isAuth) {
    navigate("/");
    return null;
  }

  return (
    <Transactions
      isLoading={isLoading}
      transactions={transactions?.data}
      request={request.current}
      page={transactions?.current_page}
      lastPage={transactions?.last_page}
    />
  );
};

export default withTranslation()(TransactionsPage);
