import { useDispatch, useSelector } from "react-redux";

import i18next from "i18next";

import { setFilterParams } from "../../../store/resultFiltersSlice";

import { normalizePrice } from "../../../utils";

import FiltersRangeSlider from "../../ui/FiltersRangeSlider";
import FilterGroupComponent from "../FilterGroupComponent";

const FilterPriceRangeComponent = () => {
  const { t } = i18next;
  const dispatch = useDispatch();

  const {
    filterParams: {
      price: { values },
    },
    filtersData: { priceRange },
  } = useSelector(state => state.resultFilters);
  const { currency } = useSelector(state => state.appConfigs);

  const handleChange = value => {
    if (value[0] !== priceRange.min || value[1] !== priceRange.max) {
      dispatch(
        setFilterParams({
          type: "price",
          values: {
            isActive: true,
            values: value,
          },
        })
      );
    } else {
      dispatch(
        setFilterParams({
          type: "price",
          values: {
            isActive: false,
            values: value,
          },
        })
      );
    }
  };

  return (
    <FilterGroupComponent title={t("price")} isOpenable={false}>
      <FiltersRangeSlider
        formatLabel={number => `${normalizePrice(number)} ${currency}`}
        value={values}
        min={priceRange.min}
        max={priceRange.max}
        onChange={handleChange}
      />
    </FilterGroupComponent>
  );
};

export default FilterPriceRangeComponent;
