import classNames from "classnames";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";

import { setFilterParams } from "../../../store/resultFiltersSlice";

import FilterGroupComponent from "../FilterGroupComponent";

import { BaggageButton, ButtonsBlock } from "./style";

const FilterBaggageComponent = () => {
  const { t } = i18next;
  const {
    filterParams: {
      baggage: {
        values: { withBaggage, withoutBaggage },
      },
    },
  } = useSelector(state => state.resultFilters);
  const dispatch = useDispatch();

  const handleChangeWithBaggage = () => {
    if (withBaggage) {
      handleChange({ withBaggage: false, withoutBaggage });
    } else {
      handleChange({ withBaggage: true, withoutBaggage: false });
    }
  };

  const handleChangeWithoutBaggage = () => {
    if (withoutBaggage) {
      handleChange({ withoutBaggage: false, withBaggage });
    } else {
      handleChange({ withoutBaggage: true, withBaggage: false });
    }
  };

  const handleChange = filters => {
    dispatch(
      setFilterParams({
        type: "baggage",
        values: {
          isActive: filters.withBaggage || filters.withoutBaggage,
          values: filters,
        },
      })
    );
  };

  return (
    <FilterGroupComponent isOpenable={false}>
      <ButtonsBlock>
        <BaggageButton className={classNames({ active: withBaggage })} onClick={handleChangeWithBaggage}>
          {t("with_luggage")}
        </BaggageButton>
        <BaggageButton className={classNames({ active: withoutBaggage })} onClick={handleChangeWithoutBaggage}>
          {t("without_luggage")}
        </BaggageButton>
      </ButtonsBlock>
    </FilterGroupComponent>
  );
};

export default FilterBaggageComponent;
