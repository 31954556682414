const ru = {
  location_map: "Наше местоположение на карте",
  location: "Наше местоположение на карте",
  agreement_text:
    "Авторизуясь на сайте, Вы соглашаетесь с Политикой конфиденциальности и правилами сбора и обработки персональных данных",
  login_title_text: "Вход в личный кабинет",
  login_text: "Логин",
  password_text: "Пароль",
  forgot_password_text: "Забыли пароль?",
  registration_text: "Регистрация",
  enter_text: "Войти",
  invalid_password: "Минимум 6 символов",
  invalid_email: "Неверный email",
  required: "Обязательное поле",
  travel_time: "Время в пути",
  flight: "Рейс",
  terminal: "Терминал",
  baggage: "Багаж",
  hand_luggage: "Ручная кладь",
  refund_ticket: "Возврат билета",
  change: "Обмен билета",
  no_refund: "Нет возврата",
  yes_refund: "Есть возврат",
  no_change: "Нет обмена",
  yes_change: "Есть обмен",
  yes_baggage: "Есть багаж",
  no_baggage: "Нет багажа",
  yes_hand_luggage: "Есть ручная кладь",
  no_hand_luggage: "Нет ручной клади",
  show_rules: "Показать правила",
  total: "Итого",
  passengers: "Пассажиры",
  select_from_list: "Выбрать из списка",
  name_as_in_document: "Имя как в документе",
  middlename_as_in_document: "Отчество как в документе",
  surname_as_in_document: "Фамилия как в документе",
  date_of_birth: "Дата рождения",
  gender: "Пол",
  citizenship: "Гражданство",
  age: "Возраст",
  document: "Документ",
  payer: "Покупатель",
  for_payer_mess:
    "На почту отправим информацию о бронировании. Телефон нужен для срочных оповещений при измении в расписании",
  series_and_number: "Серия и номер",
  valid_until: "Годен до",
  airline_bonus_card: "Бонусная карта авиакомпании",
  number_bonus_card: "Номер бонусной карты",
  phone_number: "Номер телефона",
  email: "Email",
  workH: "Время работы",
  how_to_read_rules: "Как читать правила",
  tariff_rules: "Правила тарифа",
  book_and_proceed_to_payment: "Забронировать и перейти к оплате",
  agreement_book_text: "Нажимая “Забронировать и перейти к оплате”  вы соглашаетесь с",
  agreement_book_link: "условиями тарифа авиакомпании",
  everywhere_local_time: "Везде указано местное время вылета и прибытия ",
  duration: "продолжительность",
  in: "в",
  no_data_contact_technical_support: "Данных нет. Обратитесь в техническую поддержку.",
  back_to_orders: "Назад к заказам",
  incorrect_date_from_adt: "Неверная дата для взрослого",
  incorrect_date_from_chd: "Неверная дата для ребенка",
  incorrect_date_from_inf: "Неверная дата для младенца",
  incorrect_date: "Неверная дата",
  check_date_and_change_doc: "Проверьте дату рождения или измените тип документа",
  warning_inf_date: "Авиакомпания может запросить справку о  допуске новорожденного к полету",
  ps_should_be_10_digits: "В паспорте РФ должно быть 10 цифр",
  psp_should_be_9_digits: "В загран. паспорте РФ должно быть 9 цифр",
  incorrect_doc_number: "Неверный номер документа",
  hint_for_ps_doc: "Укажите серию и номер паспорта, например 1234567890",
  hint_for_psp_doc: "Укажите номер паспорта, например 123456789",
  hint_for_sr_doc: "Укажите номер свидетельства о рождении,  например IXЯЯ123456",
  hint_for_np_doc: "Укажите серию и номер документа",
  male: "Мужской",
  female: "Женский",
  russian_passport: "Паспорт РФ",
  birth_certificate: "Свид-во о рождении РФ",
  international_passport: "Загран паспорт РФ",
  foreign_document: "Иностранный документ",
  place_one: "место",
  place_more: "места",
  place_many: "мест",
  customer_support: "Клиентская поддержка",
  financial_matters: "Финансовые вопросы",
  agreement_footer_text: "Использование материалов запрещено без письменного согласия",
  flight_schedule: "Расписание рейсов",
  offer: "Оферта",
  question_answer: "Вопрос-ответ",
  jobs: "Вакансии",
  facebook: "Facebook",
  about_us: "О нас",
  currency: "Валюта",
  language: "Язык",
  search: "Поиск",
  personal_info: "Персональные данные",
  personal_area: "Личный кабинет",
  not_found_page: "Страница, которую Вы хотели открыть, не найдена",
  to_main: "На главную",
  log_out: "Выйти",
  session: "Session",
  error_500_text:
    "Кажется, что-то пошло не так, но мы уже знаем о проблеме и работаем над ее устранением. Приносим свои извинения за неудобства.",
  lang_ru: "Русский",
  lang_tj: "Таджикский",
  lang_en: "Английский",
  balance: "Баланс",
  otb: "Баланс",
  own_funds: "Собственные средства",
  credit_line: "Кредитная линия",
  no: "Нет",
  for_route: "Для маршрута",
  to_result_search: "К результатам поиска",
  only_a_z_space: "Только латинские символы и пробел",
  only_a_z: "Только латинские символы",
  invalid_phone: "Неверный номер телефона",
  min_2_symbol: "Минимум 2 символа",
  select_value_from_list: "Выбрать значение из списка",
  check_date: "Проверьте дату",
  incorrect_number: "Неверный номер карты",
  incorrect_cvc: "Неверный код",
  incorrect_card_holder: "Неверное имя держателя карты",
  incorrect_card_month: "Неверный месяц",
  incorrect_card_year: "Неверный год",
  code: "Код",
  message: "Сообщение",
  close: "Закрыть",
  error_message_part_1:
    "Кажется, что-то пошло не так. Чтобы мы смогли разобраться в причинах возникшей проблемы, пожалуйста, обратитесь в техподдержку",
  error_message_part_2: "и сообщите номер сессии",
  error_message_part_3: "После закрытия сообщения, Вы будете  перенаправлены на главную страницу сервиса.",
  choose: "Выбрать",
  collapse: "Свернуть",
  looking_tickets: "Ищем билеты",
  wait_please: "Пожалуйста, подождите",
  we_issue_tickets: "Мы выписываем билеты, это не займет много времени",
  created: "Создан",
  left: "Осталось",
  less_than_minute: "менее минуты",
  help: "Помощь",
  exchange_and_return_rules: "Обмен и возврат: правила",
  after_flight: "После полёта",
  go_to_section: "Перейти в раздел",
  next: "Дальше",
  earlier: "Раньше",
  not_book_tickets: "У вас нет забронированных авиабилетов",
  route_from: "Откуда",
  route_to: "Куда",
  date_from: "Туда",
  date_to: "Обратно",
  find: "Найти",
  add_route: "Добавить перелет",
  one_way: "В одну сторону",
  two_way: "Туда и обратно",
  multi_way: "Сложный маршрут",
  economy: "Эконом",
  business: "Бизнес",
  first: "Первый",
  all_class: "Без привязки к классу",
  adt_main_label: "Взрослый",
  inf_main_label: "Младенец",
  chd_main_label: "Ребенок",
  adt_second_label: "от 12 лет и старше",
  chd_second_label: "2 - 12 лет",
  inf_second_label: "до 2 лет (без места)",
  inf_with_seat_second_label: "c местом",
  passenger_1: "Один пассажир",
  passenger_2: "Два пассажира",
  passenger_3: "Три пассажира",
  passenger_4: "Четыре пассажира",
  passenger_5: "Пять пассажиров",
  passenger_6: "Шесть пассажиров",
  agents: "Агентам",
  passenger_7: "Семь пассажиров",
  passenger_8: "Восемь пассажиров",
  passenger_9: "Девять пассажиров",
  only_there: "Туда",
  roundtrip: "Туда-обратно",
  your_profile: "Ваш профиль",
  client_name: "Имя клиента",
  client_code: "Код клиента",
  partner_code: "Код партнера",
  partner_name: "Имя партнера",
  partner_currency: "Валюта партнера",
  current_balance: "Текущий баланс",
  debt: "Задолженность",
  credit_limit: "Кредитный лимит",
  deposit: "Депозит",
  change_password: "Изменить пароль",
  old_password: "Введите текущий пароль",
  new_password: "Новый пароль",
  confirmation_password: "Подтвердить пароль",
  save: "Сохранить",
  passwords_must_match: "Пароли должны совпадать",
  password_changed_successfully: "Пароль успешно изменен",
  departure_title: "Вылет",
  there_title: "Туда",
  back_title: "Обратно",
  validates_flights: "валидирует перелёты",
  pay_for_your_reservation: "на оплату брони",
  hour_short: "ч",
  day_short: "дн",
  minutes_short: "м",
  minutes_short_3: "мин",
  on_way: "В пути",
  no_transfers: "Без пересадок",
  class: "Класс",
  tariff_options: "Опции тарифа",
  with_luggage: "С багажом",
  without_luggage: "Без багажа",
  no_timelimit: "Нет сведений о тайм лимите оплаты",
	legal_information: "Правовая информация",
	rules_of_use: "Правила использования",
	privacy_policy: "Политика конфиденциальности",
	payment: "Оплата",
  provider: "Поставщик",
  price: "Цена",
  detail: "Подробнее",
  label_price_by_min: "Цена (по возрастанию)",
  label_price_by_max: "Цена (по убыванию)",
  transfers: "Пересадки",
  all_flights: "Все рейсы",
  without_transfers: "Без пересадок",
  one_transfer: "1 пересадка",
  many_transfer: "2 пересадки и более",
  not_found_recomm_first_text: "Мы не смогли найти билеты, соответствующие  Вашему запросу",
  not_found_recomm_second_text: "Пожалуйста, попробуйте еще раз с другими  датами или аэропортом",
  not_found_recomm_by_filters: "Пожалуйста, измените условия фильтров",
  flight_details: "Детали рейса",
  change_tariff: "Сменить тариф",
  tariffs: "Тарифы",
  teh_stop: "Техническая остановка",
  transfer: "Пересадка",
  local_time_message: "Время вылета и прилета местное",
  route_tariffs_message: "Тариф действует на весь маршрут",
  airlines: "Авиакомпании",
  airports: "Аэропорты",
  departure: "Отправление",
  arrival: "Прибытие",
  departure_time: "Время вылета",
  arrival_time: "Время прилета",
  flight_number: "Номер рейса",
  multiple_numbers_separated_by_spaces: "Несколько номеров через пробел",
  lowcost: "lowcost",
  transfer_1: "пересадка",
  transfer_2_4: "пересадки",
  transfer_5: "пересадок",
  free_seats_0: "нет мест",
  free_seats_1: "место",
  free_seats_2_4: "места",
  free_seats_5: "мест",
  one_adt: "Взрослый",
  more_adt: "Взрослых",
  many_adt: "Взрослых",
  one_chd: "Ребенок",
  more_chd: "Ребенка",
  many_chd: "Детей",
  one_inf: "Младенец",
  more_inf: "Младенца",
  many_inf: "Младенцев",
  flight_one: "перелет",
  flights_more: "перелета",
  flights_many: "перелетов",
  found_one: "Найден",
  found_more: "Найдено",
  flight_overview: "Обзор перелёта",
  to_top: "Наверх",
  book: "Бронирование",
  buyer: "Покупатель",
  pnr: "Код бронирования / PNR",
  online_registration_locator: "Локатор для онлайн регистрации",
  route_information: "Информация о маршруте",
  order: "Заказ",
  flights_tickets: "Авиабилеты",
  void_text: "Вы можете аннулировать заказ в любой момент",
  void_order: "Аннулировать заказ",
  support_chat: "Чат с поддержкой",
  payment_method: "Способ оплаты",
  payment_with_balance: "Оплата с баланса",
  payment_with_card: "Оплата картой",
  info_account: "Инфо-счет",
  get_tickets: "Выписать билеты",
  left_before_the_time_limit: "Осталось времени",
  expires: "Истекает",
  payment_timed_out: "Время оплаты истекло",
  time_expired: "Время истекло",
  order_created: "Заказ создан",
  return_ticket: "Вернуть билет",
  exchange_ticket: "Обменять билет",
  download_itinerary_receipt: "Скачать маршрутную квитанцию",
  order_cost: "Стоимость заказа",
  invoice_for_payment: "Счет на оплату",
  void_in_process: "Идет отмена заказа",
  initial: "Создан заказ",
  in_progress: "Билет забронирован",
  success: "Успешная оплата, билеты выписаны",
  partially_success: "Частично успешный заказ",
  booking_fail: "Ошибка при бронировании",
  order_fail: "Ошибка при создании заказа",
  pay_fail: "Ошибка при оплате",
  order_cancel_from_client: "Заказ отменен клиентом",
  order_cancel_from_admin: "Заказ отменен администратором",
  order_cancel_by_job: "Заказ отменен по таймлимиту",
  order_cancel_error: "Ошибка при отмене заказа",
  application_refund: "Создана заявка на возврат средств",
  partial_refund: "Частичный возврат средств по заказу",
  order_cancel_by_gds: "Заказ отменен по таймлимиту",
  refund: "Полный возврат средств по заказу",
  ticketing_done: "Успешная оплата, билеты выписаны",
  pay_success: "Успешная оплата, билеты в процессе выписки",
  booking_done: "Билет забронирован",
  exchange: "Обмен билета",
  void: "Заказ аннулирован",
  ticketing_fail: "Ошибка при выписке билетов",
  year_one: "год",
  year_more: "года",
  years: "лет",
  day_one: "день",
  day_more: "дня",
  days: "дней",
  hour_one: "час",
  hour_more: "часа",
  hours: "часов",
  minute_one: "минута",
  minute_more: "минуты",
  minutes: "минут",
  second_one: "секунда",
  second_more: "секунды",
  seconds: "секунд",
  passenger_list: "Список пассажиров",
  your_name: "Ваше имя",
  search_order: "Поиск заказа",
  locator: "Локатор",
  cancel: "Отмена",
  passenger_name: "Фамилия пассажира",
  do_not_find_order_message: "Заказ не найден. Пожалуйста, измените параметры поиска",
  orders: "Заказы",
  contract_settings: "Настройки контракта",
  main: "Основные",
  users: "Пользователи",
  affiliated_companies: "Дочерние компании",
  all_orders_label: "Все заказы",
  book_done_label: "Забронирован",
  pay_done_label: "Оплачен",
  ticket_done_label: "Выписан",
  cancel_done_label: "Отменен",
  wait_refund_label: "Ожидает возврата",
  refund_done_label: "Возвращен",
  partial_refund_done_label: "Частично возвращен",
  void_done_label: "Аннулирован",
  error_order_label: "Ошибка заказа",
  date_create_from: "Дата создания с",
  order_status_label: "Статус заказа",
  search_title: "Введите фамилию, локатор или город",
  date_create_to: "Дата создания по",
  clear: "Очистить",
  passenger_success_save: "Пассажир успешно сохранен",
  payment_with_dpt: "Оплатить с баланса",
  payment_with_unt: 'Оплатить через "Uniteller"',
  payment_with_spt: 'Оплатить с "Корти милли"',
  include_your_fees: "включает Ваш сбор",
  before: "до",
  your_fees: "Ваш сбор",
  month_one: "месяц",
  months_more: "месяца",
  months: "месяцев",
  all_passengers: "Все пассажиры",
  passenger_success_remove: "Пассажир успешно удален",
  companies: "Компании",
  no_companies: "Нет компаний",
  no_matches_found: "Совпадения не найдены",
  no_passengers: "У Вас пока нет сохраненных пассажиров",
  enter_passenger_name: "Введите имя или фамилию пассажира",
  add_passenger: "Добавить пассажира",
  your_partner_fees: "Ваш сбор",
  type_of_fee: "Тип сбора",
  value: "Значение",
  percent: "Процент",
  saved_successfully: "Успешно сохранено",
  error_saving: "Ошибка при сохранении",
  percent_from_total: "Процент от тотала",
  percent_from_fare: "Процент от тарифа",
  fix_flat: "Фиксированный сбор",
  fix_flat_and_percent_from_total: "Фиксированный сбор и процент от тотала",
  fix_flat_and_percent_from_fare: "Фиксированный сбор и процент от тарифа",
  reset: "Сбросить",
  invalid_value: "Неверное значение",
  no_orders_found: "Заказы не найдены",
  personal_data: "Личные данные",
  upload: "Загрузить",
  your_logo: "Ваш логотип",
  image_uploaded_successfully: "Изображение успешно загружено",
  upload_logo: "Загрузите логотип",
  image_too_large: "Слишком большой объем изображения",
  image_resolution_too_high: "Слишком большое разрешение изображения",
  image_resolution_too_small: "Слишком маленькое разрешение изображения",
  rule_for_upload_img_1: "Формат изображения .jpg, .jpeg, .png, .gif",
  rule_for_upload_img_2: "Разрешение от 300х100px и до 2000x2000px.",
  rule_for_upload_img_3: "Размер файла не более 4 MB.",
  amount: "Сумма",
  rules_text_1: "Обратите внимание на следующие фразы в разделе",
  rules_text_2: "билет не подлежит возврату",
  rules_text_3: "билет не подлежит возврату в случае неявки на рейс",
  rules_text_4: "Правила обмена описаны в разделе с подзаголовком",
  line: "Строка",
  rules_text_5: "означает, что изменения не разрешены, поэтому поменять даты полета невозможно",
  passenger_data: "Данные пассажира",
  comment: "Комментарий",
  contact_details: "Контактные данные",
  contact_information: "Контакты",
  remove: "Удалить",
  add_document: "Добавить документ",
  return_avia_rules: "Как обменять или вернуть",
  booking_avia_rules: "Как заказать билет",
  created_at: "Дата создания",
  updated_at: "Дата обновления",
  phone: "Телефон",
  create_user: "Создать пользователя",
  add: "Добавить",
  edit: "Редактировать",
  confirm: "Подтвердить",
  successfully_deleted: "Успешно удалено",
  error_deleting: "Ошибка при удалении",
  mess_want_to_delete: "Вы действительно хотите удалить",
  record_one: "запись",
  record_more: "записи",
  record_many: "записей",
  user_type: "Тип клиента",
  admin_role: "Администратор",
  manager_role: "Менеджер",
  user_agent_role: "Агент",
  user_role: "Пользователь",
  security_guarantees_unitaller: "Гарантии безопасности",
  activation_title: "Активация пользователя",
  confirmation_activation_password: "Подтвердите пароль",
  enter_activation_password: "Введите пароль",
  activation: "Активация",
  login: "Авторизация",
  error_activating_user: "Ошибка при активации пользователя",
  error: "Ошибка",
  to_login_form: "На страницу авторизации",
  expired_date: "Истек срок действия",
  refund_button_title: "Возврат",
  itinerary_receipt: "Маршрутная квитанция",
  action: "Действие",
  to_pay: "Оплатить",
  order_cancel_error_mess: "Ошибка при отмене заказа",
  order_cancel_successfully_mess: "Успешная отмена",
  partner: "Партнер",
  inn: "ИНН",
  kpp: "КПП",
  logo: "Логотип",
  create_partner: "Создать партнера",
  status: "Статус",
  add_your_details: "Добавьте Ваши данные",
  return_request_created: "Создана заявка на возврат",
  error_creating_refund_request: "Ошибка при создании заявки на возврат",
  activation_status: "Статус активации",
  placeholder_date_picker: "дд.мм.гггг",
  created_company_successfully: "Компания создана",
  error_created: "Ошибка при создании",
  deposit_statement: "Запрос на выписку через депозит",
  transactions: "Транзакции",
  weight_unit: "кг",
  credit_funds: "Кредитные средства",
  overrun: "Перерасход",
  transaction_time: "Время транзакции",
  before_balance_change: "До изменения баланса",
  allow_balance_payment: "Разрешить оплату с баланса",
  approved_balance_request_successfully: "Заявка на выписку с баланса подтверждена",
  error_approved_balance_request_successfully: "Ошибка при подтверждении заявки на выписку с баланса",
  balance_payment_request: "Заявка на выписку с баланса",
  balance_payment_approved: "Заявка на выписку с баланса подтверждена",
  payment_with_uzc: 'Оплатить с "Uzcard"',
  allow_payment: "Разрешить оплату",
  statement_request: "Запрос на выписку",
  client: "Клиент",
  was_changed_to: "был изменен на",
  deposit_statement_request_created: "Создана заявка на выписку с депозита",
  registration: "Регистрация",
  error_creating_deposit_statement_request: "Ошибка при создании заявки на выписку с депозита",
  deposit_statement_request_confirmed: "Заявка на выписку с депозита подтверждена",
  error_confirming_deposit_statement_request: "Ошибка при подтверждении заявки на выписку с депозита",
  fare: "Тариф",
  fees: "Сборы",
  partner_fees: "Сборы партнера",
  taxes: "Налоги",
  ticket: "Билет",
  pay_for_the_order: "Оплатить заказ",
  operating_company: "Оперирующая компания",
  download_charter_certificate: "Скачать чартерный сертификат",
  certificate_issued: "Выписан чартерный сертификат",
  charter: "Чартер",
  service: "Сервис",
  income: "Приход",
  spending: "Расход",
  remainder: "Остаток",
  description: "Описание",
  contract: "Договор",
  column_selection: "Выбор столбцов",
  your_commission: "Ваша комиссия",
  no_result: "Нет результатов",
  date: "Дата",
  movement_balance: "Движение средств на балансе",
  current_balance_sheet: "Текущее состояние баланса",
  indebtedness: "Задоложенность",
  date_from_d: "Дата с",
  date_to_d: "Дата по",
  order_num: "Номер заказа",
  booking_num: "Номер брони",
  search_operations: "Поиск операций",
  upload_report: "Выгрузить отчёт",
  payment_with_vsl: 'Оплатить с "Корти милли"',
  popular_destinations: "Популярные  направления",
  more_options: "Ещё варианты",
  hide_list: "Скрыть лист",
  straight: "Прямые",
  with_transfer: "С пересадками",
  finances: "Финансы",
  contacts: "Контакты",
  all_classes: "Все классы",
  difficult_routes: "Cоставить сложный маршрут",
  search_history: "История поиска",
  go_to_page: "Перейти",
  find_tickets: "найти билеты",
  tours_discount: "Выгодные туры и скидки",
  last_news: "Последние новости",
  more_details: "Подробнее",
  about_company: "О компании",
  wannatalk: "Хотите пообщаться?",
  customer_rew: "Отзывы клиентов",
  my_profile: "Мой профиль",
  contact_us: "Свяжитесь с нами",
  last_name: "Фамилия",
  first_name: "Имя",
  father_name: "Отчество",
  enter_login: "Введите ваш логин",
  enter_name: "Введите свое имя",
  enter_surname: "Введите свою фамилию",
  enter_fathname: "Введите свое отчеcтво",
	aeroflot: "Аэрофлот",
	turkish: "Турецкие Авиалинии",
	ural: "Уральские Авиалинии",
	korea: "Корейские Авиалинии",
	qanot: "Qanot Sharq",
	belavia: "Белавиа",
	apgAS: "APG Air Solution",
	pobeda: "Победа",
	airastana: "Air Astana",
	flyaristan: "Fly Aristan",
	s7: "Сибирьские Авиалинии",
  enter_password: "Введите ваш пароль",
  flight_search: "Поиск авиабилетов",
  buy_air_tickets: "Купите билеты не выходя из дома",
  log_in: "Войти",
	welcome_back: "С возвращением!",
  return_to_simple_route: "Вернуться к простому маршруту",
  delete: "Удалить",
  max_num_flights: "Вы добавили максимальное количество перелётов",
  select_dep_date: "Выберите дату вылета",
  select_return_date: "Выберите дату возвращения",
  select_class: " Выберите класс",
  login_as_user: "Войдите как пользователь",
  enter_recieved_code: "Введите полученный код",
  come_up_your_pass: "Придумайте свой пароль",
  enter_valid_mail: "Введите валидный email",
  must_field_characters: "Поле должно содержать минимум 6 символов",
  register_to_save_data: "Зарегистрируйтесь чтобы сохранить свои данные",
  ins_main_label: " Младенец",
  ins_second_label: " до 2 лет (с местом)",
  newsletter_subscription: "Подписка на рассылку",
  newsletter_subscription_text: "Подпишитесь, чтобы быть в курсе всех важных новостей и выгодных предложений!",
  subscribe: "Подписаться",
  successfully_subscribed: "Вы успешно подписались!",
  subscription_error: "Ошибка при подписке, повторите попытку позже!",
  all_news: "Все новости",
  tel: "Тел",
  enter_email: "Введите свой email",
  menu: "Меню",
  settings: " Настройки",
  deposit_method: "Выберите метод пополнения",
  recharging_amount: "Сумма пополнения",
  recharge: " Пополнить",
  recharging_balance: "Пополнить баланс",
  done: " Готово",
  not_found_content: " Не найдено",
  not_found_content_text: " К сожалению, данный контент не доступен на выбранном языке",
  airport_code: "Код аэропорта",
  from_city: "Из г.",
  to_city: "В город",
  weekdays: "Дни недели",
  weekends: "Выходные",
  weekends1: "1 и 2 - Января",
  weekends2: "8 и 21 - Марта",
  weekends3: "9 - Мая",
  weekends4: "1 - Сентября",
  weekends5: "1 - Октября",
  weekends6: "8 и 31 - Декабря",
  weekends7: "А также во время праздников Ид",
  airline: "Авиакомпания",
  min_number: "Сумма пополнения должна быть больше",
  only_numbers: " Только числа",
  must_register: " Для того чтобы купить билеты на нашем сайте, вы должны быть авторизированными",
  daily: "Ежедневно",
  fly_tagline: "С нами летать легко",
  sorting: "Сортировка",
  i_read_fares: "Я ознакомился с",
  i_accept_fares: "и соглашаюсь с ними",
  prices_table: "Таблица цен",
  continue: "Продолжить",
  reset_error_title: "Ошибка",
  reset_error_content: "Извините, ошибка произошла во время выполнение запроса",
  reset_success_title: "Вы успешно изменили свой пароль!",
  page_my_transactions: "Отчетность о совершенных платежах",
  reset_success_content: 'Пожалуйста, нажмите кнопку "Войти", чтобы совершить вход с новым паролем',
  forgot_success_title: "Успешно!",
  forgot_success_content: "Ссылка на сброс пароля была отправлена на вашу почту",
  forgot_error_title: "Ошибка",
  forgot_error_content: "Извините, но Вы ввели неправильный E-Mail",
  password_confirm: "Подтверждение пароля",
  password_confirm_error: "Пароли не совпадают",
  enter_password_confirm: "Подтвердите пароль",
  reset_password: "Сбросьте свой пароль",
  enter_new_password: "Введите новый пароль",
  forgot_password: "Забыли пароль?",
  forgot_password_summary: "Введите свою электроную почту для сброса пароля",
  popup_event_text: "Получите бонус в размере 100 сомони на баланс своего личного кабинета после регистрации",
  popup_description: "Зарегистрируйся на сайте и покупай авиабилеты онлайн, не выходя из дома!",
  payment_milly: 'Оплата по "Корти милли"',
  from_airport: "Из аэропорта",
  not_enough_balance: "Недостаточно средств для оплаты",
  questions_and_answers: "Вопросы и ответы",
  payment_methods: "Способы оплаты",
  our_partners: "Наши партнеры",
  confirm_actions: "Подтвердите ваше действие на сайте",
  payer_contacts: "Контакты покупателя",
  buyer_email: "Email покупателя",
  enter_buyer_email: "Введите email покупателя",
  locator_order_number_or_booking_number: "Локатор, номер заказа или номер бронирования",
  enter_locator_order_number_or_booking_number: "Введите локатор, номер заказа или номер бронирования",
  activate: "Активировать",
  register: "Регистрируйся",
	deals: "Акции",
  pre_cashback: "Покупай авиабилеты и получай фиксированный",
  cashback_text: "Кэшбэк",
	cashback0: "Прямой контракт. Цены от авиакомпаний.",
	cashback_from: "Кэшбэк от авиакомпаний партнеров проекта применяется на рейсы следующие из аэропортов Узбекистана. Кэшбэк считается от тарифа.",
	cashback_from_in: "Кэшбэк от авиакомпаний партнеров проекта применяется на рейсы следующие из/в аэропорты Узбекистана. Кэшбэк считается от тарифа.",
	tarif: "от тарифа",
  post_cashback: "на баланс личного кабинета",
	cashback_message: "Чтобы получить кэшбэк, вам нужно зарегистрироваться на сайте и приобрести билеты через наш сервис. ",
	cashback_message_auth: "Вам доступны следующие предложения по кэшбэку:",
  page_home: "Онлайн покупка билетов, без посещения касс.",
  page_faq: "Ответы на популярные вопросы",
  page_login: "Соверши вход в свой личный кабинет",
  page_registration: "Регистрируйся и покупай авиабилеты",
  page_results: "Результаты по интересующему маршруту",
  page_booking: "Бронирование заданного билета",
  page_order: "Информация о совершенном заказе",
  page_orders_list: "Информация о совершенных заказах",
  page_my_profile: "Личная информация",
  page_add_balance: "Пополнение баланса личного кабинета",
  page_popular_destinations: "Расписание популярных вылетов",
  page_news: "Популярные новости авиации и Fly TJ",
  page_forgot_password: "Покупка авиабилетов Онлайн не выходя из дома!",
  page_reset_password: "Покупка авиабилетов Онлайн не выходя из дома!",
  useful_info: "Полезная информация",
  copy_link: "Скопировать ссылку",
  copy_order_link_text: "Скопируйте ссылку, чтобы поделиться доступом к данному заказу",
  link_copied_to_clipboard: "Ссылка скопирована в буфер обмена",
  unsubscribe_success: "Вы успешно отписались от рассылки на новость!",
  unsubscribe_error: "Произошла ошибка во время обработки",
  activation_code_send_to_email: "Код для подтверждения будет отправлен вам на указанную почту",
  choose_gender: "Выберите пол",
  old_information: "Информация устарела!",
  refresh: "Обновить",
  refresh_page: "Цены на авиабилеты обновляются несколько раз в день. Обновите страницу для проверки актуальных цен.",
  will_take_2_minutes: "Это может занять некоторое время",
  error_additional_options_text:
    "Возникла ошибка при включении дополнительных опций к бронированию. Продолжить без дополнительных опций?",
  edit_personal_data: "изменить свои личные данные",
  minimum_amount: "Минимальная сумма",
  additional_options: "Дополнительные услуги",
  additional_options_not_found: "Дополнительные услуги не найдены!",
  profile_changed: "Профиль успешно изменен",
  welcome: "Добро пожаловать",
  register_on_somon_air: "Регистрация на Somon Air",
  min_6_symbol: "Минимум 6 символов",
  your_id: "Ваш ID",
  cheapest_without_baggage: "Самый дешевый без багажа",
  cheapest_with_baggage: "Самый дешевый с багажом",
  most_convenient_transfes: "Самая удобная пересадка",
  fly_ru_tagline: "Почувствуй мир!",
  fly_ru_subtagline: "Ваш доступ ко всем авиакомпаниям мира",
  fly_ru_round_the_clock: "Круглосуточно",
  refund_order: "Заявление о возврате",
  refund_reason: "Причина возврата",
  order_payment_method: "Метод оплаты заказа",
  payer_user_id: "ID пользователя оплатившего заказ",
  four_last_digits_of_card: "4 последних цифры карты оплаты",
  user_id: "ID пользователя",
  buyer_phone: "Телефон покупателя",
  requisite_of_account_to_return: "Реквизиты счета на который будет осуществлен возврат средств",
  requisite_of_account: "Реквизиты счета",
  passenger_name_and_surname: "Фамилия и имя пассажира",
  passport_serial_and_number: "Серия и номер паспорта",
  passport_copy: "Копия паспорта",
  send: "Отправить",
  payment_from_client_balance: "Оплата с баланса клиента",
  payment_from_card: "Оплата с помощью карты",
  vasl_tagline: "Удобство в приоритете!",
  technical_support: "Техническая Поддержка",
  success_refund_order_request: "Заявление на возврат средств успешно отправлено!",
  error_refund_order_request: "Ошибка при отправке заявления на возврат средств!",
  find_options: "найти билеты",
  buy_tickets_to_all_destinations: "и покупай авиабилеты по всем направлениям!",
  filters: "Фильтры",
  night_flight: "Ночная пересадка",
  change_airport: "Смена аэропорта",
  and: "и",
  from: "от",
  refund_order_success: "Успешно отправлено заявление о возврате",
  refund_order_error: "Ошибка при отправке заявления о возврате",
  buy_tour: "Купить тур",
  payment_alert: "Уважаемый пользователь, ваш заказ оплачен. Время выписки 1 минута.",
  customer_office_address: "Адрес офиса для клиентов",
  inner_document: "Внутренний документ",
  document_copy: " Копия документа",
  maximum_amount: "Максимальная сумма",
  search_limits_exceeded:
    "Уважаемый пользователь, Вы превысили количество поисков, пожалуйста пройдите авторизацию/регистрацию чтобы дальше продолжить свою работу",
  flight_without_seats: "К сожалению, закончились места на данный рейс",
  document_type: "Тип документа",
  document_number: "Номер документа",
  expiration_date: "Срок действия",
  any_cabin: "Любой класс",
  my: "Мой",
  site: "Сайт",
  show_more: "Показать больше",
  route: "Маршрут",
  pay_before: "Оплатить до",
};

export default ru;
