export const FLIGHT_TYPE_OW = "OW";
export const FLIGHT_TYPE_RT = "RT";
export const FLIGHT_TYPE_CF = "CF";

export const cabins = [
  { cabin: "all", label: "Любой класс" },
  { cabin: "economy", label: "Эконом" },
  { cabin: "comfort", label: "Комфорт" },
  { cabin: "business", label: "Бизнесс" },
  { cabin: "first", label: "Первый" },
];
