import { LinkItem, RulesOfUseContent, RulesOfUseContentList, RulesOfUseItem, RulesOfUseItemTitle, RulesOfUseTitle, Wrapper } from "./style";

export const RulesOfUse = () => {
	return (
		<Wrapper>
			<RulesOfUseTitle>
				УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА
			</RulesOfUseTitle>

			<RulesOfUseItem>
				<RulesOfUseItemTitle>
					ОБЩИЕ ПОЛОЖЕНИЯ
				</RulesOfUseItemTitle>

				<RulesOfUseContent>
				Настоящий сайт принадлежит ООО «AMMY TRAVEL» (далее - Администрация сайта). Пожалуйста, внимательно прочитайте правила использования материалов сайта. Данные правила распространяются на всех пользователей сайта. Пользуясь материалами сайта, Вы подтверждаете, что прочитали, поняли и согласны соблюдать настоящие Правила.
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					Администрация сайта оставляет за собой право по своему личному усмотрению изменять и (или) дополнять настоящие Правила в любое время без предварительного и (или) последующего уведомления.
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					Ваше дальнейшее использование сайта после любых подобных изменений означает Ваше согласие с такими изменениями и (или) дополнениями. Если Вы не согласны соблюдать настоящие Правила, не используйте данный сайт. В Вашей личной ответственности остается регулярный просмотр данной страницы для ознакомления с действующей редакцией Правил.
				</RulesOfUseContent>
			</RulesOfUseItem>
			
			<RulesOfUseItem>
				<RulesOfUseItemTitle>
					1. ПРАВИЛА ИСПОЛЬЗОВАНИЯ СОДЕРЖИМОГО САЙТА
				</RulesOfUseItemTitle>

				<RulesOfUseContent>
					1.1. Использование любых объектов, размещенных на сайте, в том числе элементы дизайна, текст, графические изображения, иллюстрации, скрипты, программы (далее - контент), к которому Вы получили доступ исключительно для Вашего личного некоммерческого использования, допускается при условии сохранения всех знаков авторства (копирайтов) или других уведомлений об авторстве, сохранения имени автора в неизменном виде, сохранении произведения в неизменном виде.
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					1.2. При копировании материалов с нашего сайта и их размещении на других сайтах мы требуем соблюдения всего одного условия - каждый материал должен сопровождаться активной гиперссылкой на наш сайт.
				</RulesOfUseContent>

				<RulesOfUseContentList>
					<li>
						ссылка может вести на домен bookhara.uz или на ту страницу, с которой Вы скопировали наши материалы (на Ваше усмотрение);
					</li>
					<li>
						на каждой странице Вашего сайта, где размещены наши материалы, должна стоять активная ссылка на наш сайт;
					</li>
					<li>
						гиперссылки не должны быть запрещены к индексации поисковыми системами (с помощью "noindex", "nofollow" или любыми другими способами);
					</li>
				</RulesOfUseContentList>
				
				<RulesOfUseContent>
					В случае нарушения данных правил, Администрация оставляет за собой право на следующие меры:
				</RulesOfUseContent>

				<RulesOfUseContentList>
					<li>
						письменная жалоба владельцу хостинга (сервера), на котором расположен сайт-нарушитель, с просьбой воздействовать на нарушителя, вплоть до расторжения договора хостинга (в соответствии с правилами всех хостинг-провайдеров, на сайтах запрещена публикация любых материалов, нарушающих авторское право их владельцев);
					</li>
					<li>
						письменные жалобы в Администрации поисковых систем Яндекс и Google, что в соотвествии с их правилами может повлечь удаление или отключение доступа к материалу, заявленному в качестве объекта нарушения.
					</li>
				</RulesOfUseContentList>
				
				<RulesOfUseContent>
					1.3. Сайт содержит (или может содержать) ссылки на другие вебсайты (сайты третьих лиц) так же, как и музыку, звуки, информацию и другой контент, принадлежащий или исходящий от третьих лиц (контент третьих лиц).
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					1.4. Указанные третьи лица и их контент не проверяются Администрацией Сайта на соответствие тем или иным требованиям (достоверности, полноты, добросовестности и т.п.). Администрация сайта не несет ответственность за любую информацию, размещенную на сайтах третьих лиц, к которым вы получили доступ через сайт или через контент третьих лиц, включая, в том числе, любые мнения или утверждения, выраженные на сайтах третьих лиц или в их контенте.
				</RulesOfUseContent>
			</RulesOfUseItem>
			
			<RulesOfUseItem>
				<RulesOfUseItemTitle>
					2. ИСПОЛЬЗОВАНИЕ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
				</RulesOfUseItemTitle>

				<RulesOfUseContent>
					Компания не будет собирать какой-либо личной информации о Вас при использовании Вами сайта, если Вы сами добровольно не предоставите такую информацию путем заполнения формы заявки или формы связи. Компания обязуется использовать предоставленную таким образом информацию только для целей, по которым она была Вами предоставлена. Компания хранит такую информацию, включая соответствующую переписку, в своем архиве.
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					Подробнее о Политике конфиденциальности можно <LinkItem to="/privacy-policy-info">прочесть тут</LinkItem>
				</RulesOfUseContent>
			</RulesOfUseItem>
			
			<RulesOfUseItem>
				<RulesOfUseItemTitle>
					3. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ
				</RulesOfUseItemTitle>

				<RulesOfUseContent>
					3.1. Ни при каких обстоятельствах Администрация сайта или ее представители не несут ответственность перед Вами или перед любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, вызванный в связи с использованием сайта, содержимого сайта или иных материалов, к которым Вы или иные лица получили доступ с помощью сайта, даже если Администрация сайта предупреждала или указывала на возможность такого вреда.
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					3.2. Администрация сайта или ее представители не несут ответственность за достоверность информации о Партнерах, опубликованной на страницах сайта. Справочный профайл Поставщика создается на основании информации, предоставленной самим Поставщиком.
				</RulesOfUseContent>
			</RulesOfUseItem>
			
			<RulesOfUseItem>
				<RulesOfUseItemTitle>
					4. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
				</RulesOfUseItemTitle>

				<RulesOfUseContent>
					4.1. Настоящие Правила составляют соглашение между Вами и Администрацией относительно порядка использования сайта и его сервисов и заменяют собой все предыдущие соглашения между Вами и Администрацией.
				</RulesOfUseContent>
				
				<RulesOfUseContent>
					4.2. Если по тем или иным причинам одна или несколько норм настоящих Правил является недействительной или не имеющей юридической силы, это не оказывает влияния на действительность или применимость остальных норм.
				</RulesOfUseContent>
			</RulesOfUseItem>
			
			<RulesOfUseItem>
				<RulesOfUseItemTitle>
					5. ОБРАТНАЯ СВЯЗЬ
				</RulesOfUseItemTitle>

				<RulesOfUseContent>
					Если у Вас возникают какие-либо вопросы по Условиям пользования сайтом, или Вы хотите сообщить об их нарушении или другом незаконном использовании интеллектуальной собственности ООО «AMMY TRAVEL» , пожалуйста, свяжитесь с нами по адресу <LinkItem to="mailto:info@biletbor.me">info@biletbor.me</LinkItem>
				</RulesOfUseContent>
			</RulesOfUseItem>
		</Wrapper>
	)
};