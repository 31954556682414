import { useState } from "react";

import i18next from "i18next";
import moment from "moment";
import { IoSettingsOutline } from "react-icons/io5";
import { TbUser } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

import defaultCurrencies from "../../../config/defaultCurrencies";
import defaultLanguages from "../../../config/defaultLanguages";

import { setAppConfigs } from "../../../store/appConfigsSlice";

import { useAuth } from "../../../context/auth";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { DropdownComponent } from "../../ui";

import HeaderSearchOrderComponent from "../HeaderSearchOrderComponent";

import {
	GroupTitle,
	IconButton,
	Item,
	ItemLink,
	Items,
	SettingsBlock,
	// Text,
	TextLanguage,
	UserBalance,
	UserName,
	// Checkbox,
	// CheckboxBlock,
	UserSection,
	Wrapper,
} from "./style";

const HeaderNavigationComponent = () => {
  const { changeLanguage, language, t } = i18next;

  const { width } = useWindowDimensions();

  const { availableCurrencies, availableLanguages, currency } = useSelector(state => state.appConfigs);
  const dispatch = useDispatch();
  const { isAuth, onLogout, user } = useAuth();

  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);

  const handleSelectCurrency = currency => {
    localStorage.setItem("currency", currency);
    dispatch(setAppConfigs({ currency }));
    setShowSettingsDropdown(false);
  };

  const handleSelectLanguage = language => {
    switchLang(language);
    setShowSettingsDropdown(false);
  };

  const currencies = () => {
    return availableCurrencies.map(value => {
      return (
        <Item key={`currency-${value}`} onClick={() => handleSelectCurrency(value)}>
          <TextLanguage className={value === currency && "active"}>{defaultCurrencies[language][value]}</TextLanguage>
        </Item>
      );
    });
  };

  const renderLanguages = () => {
    return availableLanguages.map(value => {
      return (
        <Item key={`languages-${value}`} onClick={() => handleSelectLanguage(value)}>
          <TextLanguage className={value === language && "active"}>{defaultLanguages[language][value]}</TextLanguage>
        </Item>
      );
    });
  };

  const switchLang = lang => {
    changeLanguage(lang);
    moment.updateLocale(lang === "tj" ? "tg" : lang, { week: { dow: 1 } })
    localStorage.setItem("language", lang);
    document.documentElement.setAttribute("lang", lang);
  };

  const handleLogout = () => {
    onLogout();
    setShowUserDropdown(false);
  };

  return (
    <Wrapper>
      {!isAuth && (
        <HeaderSearchOrderComponent />
      )}

      <SettingsBlock>
        <IconButton onClick={() => setShowSettingsDropdown(true)}>
          <IoSettingsOutline />
        </IconButton>

        {showSettingsDropdown && (
          <DropdownComponent
            show={showSettingsDropdown}
            onClickOutside={() => setShowSettingsDropdown(false)}
            width={150}
          >
            <GroupTitle>{t("currency")}</GroupTitle>
            <Items>{currencies()}</Items>

            <GroupTitle>{t("language")}</GroupTitle>
            <Items>{renderLanguages()}</Items>
          </DropdownComponent>
        )}
      </SettingsBlock>
      

      {isAuth && width > 500 ? (
        <SettingsBlock>
          <UserSection onClick={() => setShowUserDropdown(true)}>
            <UserBalance>{t("balance")}: {user.balance} {user.currency}</UserBalance>
            <UserName>{user?.first_name} {user?.last_name}</UserName>
          </UserSection>

          {showUserDropdown && (
            <DropdownComponent
              show={showUserDropdown}
              onClickOutside={() => setShowUserDropdown(false)}
              width={isAuth ? 250 : 150}
            >
              <Items>
                <ItemLink to="/orders" onClick={() => setShowUserDropdown(false)}>
                  {t("orders")}
                </ItemLink>

                <ItemLink to="/transactions" onClick={() => setShowUserDropdown(false)}>
                  {t("transactions")}
                </ItemLink>

                <ItemLink to="/user/balance" onClick={() => setShowUserDropdown(false)}>
                  {t("recharging_balance")}
                </ItemLink>
								
								{/* <ItemLink to="/cashback-info" onClick={() => setShowUserDropdown(false)}>
									{t("cashback_text")}
								</ItemLink> */}

                <Item onClick={handleLogout}>
                  {t("log_out")}
                </Item>
              </Items>
            </DropdownComponent>
          )}
        </SettingsBlock>
       ):(
        <SettingsBlock>
          <IconButton onClick={() => setShowUserDropdown(true)}>
            <TbUser />
          </IconButton>

          {showUserDropdown && (
            <DropdownComponent
              show={showUserDropdown}
              onClickOutside={() => setShowUserDropdown(false)}
              width={isAuth ? 300 : 150}
            >
              {isAuth ? (
                <Items>
                  <GroupTitle>{user?.first_name} {user?.last_name}</GroupTitle>
                  <GroupTitle>{user?.login}</GroupTitle>
                  <GroupTitle>{t("balance")}: {user.balance} {user.currency}</GroupTitle>

                  <ItemLink to="/orders" onClick={() => setShowUserDropdown(false)}>
                    {t("orders")}
                  </ItemLink>

                  <ItemLink to="/user/balance" onClick={() => setShowUserDropdown(false)}>
                    {t("recharging_balance")}
                  </ItemLink>

                  <Item onClick={handleLogout}>
                    {t("log_out")}
                  </Item>
                </Items>
              ) : (
                <Items>
                  <ItemLink to="/auth" onClick={() => setShowUserDropdown(false)}>
                    {t("log_in")}
                  </ItemLink>

                  <ItemLink to="/registration" onClick={() => setShowUserDropdown(false)}>
                    {t("registration")}
                  </ItemLink>
                </Items>
              )}
            </DropdownComponent>
          )}
        </SettingsBlock>
       )}
    </Wrapper>
  );
};

export default HeaderNavigationComponent;
