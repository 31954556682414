import { appDefaultLanguage } from "../../config";
import { ContentType, request } from "../api";
import { baseParams } from "../common";

export const getCountries = async (value, language = appDefaultLanguage, limit = 7, params = {}) => request({
  path: "/countries",
  method: "POST",
  body: { ...baseParams, value, language, limit },
  type: ContentType.Json,
  format: "json",
  ...params,
});
