import metaDescriptions from "./metaDescriptions";
import metaKeywords from "./metaKeywords";
import metaOgObject from "./metaOgObject";
import metaTitles from "./metaTitles";
import titles from "./titles";

const seo = {
  metaDescriptions,
  metaKeywords,
  metaOgObject,
  metaTitles,
  titles,
};

export default seo;
