import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  paddingBottom: 10,
}));

export const DirectionButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  border: "none",
  backgroundColor: "transparent",
  color: "#fff",
  position: "relative",
  paddingLeft: 32,
  cursor: "pointer",
}));

export const DirectionButtonIcon = styled("span")(({ theme }) => ({
  position: "absolute",
  left: -5,
  top: -8,
}));

export const DirectionButtonText = styled("span")(({ theme }) => ({
  fontSize: 14,
  lineHeight: "20px",
  borderBottom: "1px dotted #fff",
}));
