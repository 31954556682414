import { ContentType, request } from "../api";
import { baseParams } from "../common";
import { formattedRoutesData } from "./searchFlights";

export const getChangeBaggage = data => {
  const currentData = { ...data };
  currentData.routes = formattedRoutesData(currentData.routes);

  return currentData;
};

export const prebook = async (data = {}, params = {}) => {
  const response = await request({
    path: "/prebook",
    method: "POST",
    body: { ...baseParams, ...data },
    type: ContentType.Json,
    format: "json",
    ...params,
  });

  return getChangeBaggage(response?.data);
};
