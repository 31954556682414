import { ContainerComponent } from "../../ui";
import FooterTopComponent from "../FooterTopComponent";
import { Wrapper } from "./style";

const FooterComponent = () => {
  return (
    <Wrapper>
      <ContainerComponent>
        <FooterTopComponent />
      </ContainerComponent>
    </Wrapper>
  );
};

export default FooterComponent;
