import _ from "lodash";
import i18next from "i18next";

import {
  APPLICATION_REFUND_CODE,
  BALANCE_PAYMENT_APPROVED_CODE,
  BALANCE_PAYMENT_REQUEST_CODE,
  BOOKING_DONE_CODE,
  BOOKING_FAIL_CODE,
  BOOK_STATUS_CODES,
  BOOK_STATUS_COLOR,
  CERTIFICATE_ISSUED_CODE,
  INITIAL_CODE,
  IN_PROGRESS_BOOK_CODE,
  ORDER_FAIL_CODE,
  PARTIAL_REFUND_CODE,
  PAY_FAIL_CODE,
  PAY_SUCCESS_CODE,
  REFUND_CODE,
  TICKETING_DONE_CODE,
  TICKETING_FAIL_CODE,
  VOID_CODE,
} from "../constants";

import { noun } from "./common";

export const checkInAccessToPaymentStatus = (bookStatus, orderStatus) =>
  (bookStatus === BOOKING_DONE_CODE ||
    bookStatus === INITIAL_CODE ||
    bookStatus === null ||
    bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
    bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
  orderStatus !== ORDER_FAIL_CODE;

export const getBookStatus = (bookingStatus, orderStatus, t) => {
  if (bookingStatus === null && orderStatus !== ORDER_FAIL_CODE) {
    bookingStatus = IN_PROGRESS_BOOK_CODE;
  }

  if (bookingStatus === null && orderStatus === ORDER_FAIL_CODE) {
    bookingStatus = ORDER_FAIL_CODE;
  }

  const bookStatus = _.find(BOOK_STATUS_CODES, { value: bookingStatus });

  return t(`${bookStatus?.code}`);
};

export const getPassTypeCountString = (count, type) => {
  const { t } = i18next;

  switch (type) {
    case "adt":
      return noun(count, t("one_adt"), t("more_adt"), t("many_adt"));
    case "chd":
      return noun(count, t("one_chd"), t("more_chd"), t("many_chd"));
    case "ins":
    case "inf":
      return noun(count, t("one_inf"), t("more_inf"), t("many_inf"));
    default:
      return count;
  }
};

export const checkIsAllowedMK = bookStatus =>
  bookStatus === BOOKING_DONE_CODE ||
  bookStatus === PARTIAL_REFUND_CODE ||
  bookStatus === APPLICATION_REFUND_CODE ||
  bookStatus === PAY_SUCCESS_CODE ||
  bookStatus === PAY_FAIL_CODE ||
  bookStatus === TICKETING_FAIL_CODE ||
  bookStatus === VOID_CODE ||
  bookStatus === REFUND_CODE ||
  bookStatus === TICKETING_DONE_CODE ||
  bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
  bookStatus === BALANCE_PAYMENT_APPROVED_CODE ||
  bookStatus === CERTIFICATE_ISSUED_CODE;

export const checkIsAllowedCancel = (bookStatus, orderStatus) =>
  (bookStatus === BOOKING_DONE_CODE ||
    bookStatus === INITIAL_CODE ||
    bookStatus === null ||
    bookStatus === BOOKING_FAIL_CODE ||
    bookStatus === PAY_FAIL_CODE ||
    bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
    bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
  orderStatus !== ORDER_FAIL_CODE;

export const getColorStatus = status => {
  if (status === null) {
      status = IN_PROGRESS_BOOK_CODE;
  }

  const bookStatus = _.find(BOOK_STATUS_COLOR, { value: status });

  return bookStatus.color;
};
