const metaDescriptions = {
  ru: {
    homePage: "Добро пожаловать на сайт AlifAviaUz: это лучшее место для бронирования авиабилетов без лишних хлопот. Изучите нашу удобную платформу для планирования путешествий, получения выгодных предложений и индивидуальных рекомендаций. Присоединяйтесь к миллионам довольных путешественников и начните свое следующее приключение с AlifAviaUz уже сегодня!",
		cashbackPage: "Откройте для себя эксклюзивные возможности экономии с помощью программы AlifAviaUz Cash Back Rewards! Зарабатывайте ценные деньги на каждом бронировании авиабилетов, чтобы подпитывать свои будущие приключения. Присоединяйтесь прямо сейчас и превратите свои путешествия в экономию!",
  },
  en: {
    homePage: "Welcome to AlifAviaUz: Your premier destination for hassle-free flight bookings. Explore our user-friendly platform for seamless travel planning, unbeatable deals, and personalized recommendations. Join millions of satisfied travelers and start your next adventure with AlifAviaUz today!",
		cashbackPage: "Unlock exclusive savings with AlifAviaUz Cash Back Rewards! Earn valuable cash back on every flight booking to fuel your future adventures. Join now and turn your travels into savings!",
  },
  uz: {
    homePage: "AlifAviaUz veb-saytiga xush kelibsiz: bu hech qanday muammosiz aviachiptalarni bron qilish uchun eng yaxshi joy. Sayohatni rejalashtirish, ajoyib takliflar va moslashtirilgan tavsiyalar uchun foydalanish uchun qulay platformamiz bilan tanishing. Millionlab mamnun sayohatchilar qatoriga qo'shiling va bugun AlifAviaUz bilan keyingi sarguzashtingizni boshlang!",
		cashbackPage: "AlifAviaUz Cash Back Rewards dasturi bilan eksklyuziv tejash imkoniyatlarini kashf eting! Kelajakdagi sarguzashtlaringizni kuchaytirish uchun har bir reysni bron qilishda qimmatbaho pul ishlang. Hoziroq qoʻshiling va sayohatlaringizni tejashga aylantiring!",
  },
};

export default metaDescriptions;
