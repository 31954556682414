import styled from "styled-components";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_RT } from "../../../constants";

export const Wrapper = styled("div")(({ theme, type, flightType }) => ({
  ...(type === "from" ? { borderRadius: "4px 0 0 4px" } : {}),
  backgroundColor: theme.palette.white,
  width: flightType !== FLIGHT_TYPE_CF ? 350 : 591,
  height: 60,
  position: "relative",
  transition: "background-color .3s ease",

  [theme.down("xl")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 260 : 461,
  },

  [theme.down("lg")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 220 : 381,
  },

  [theme.down("md")]: {
    ...(type === "to" && flightType === FLIGHT_TYPE_CF ? { borderRadius: "0 4px 4px 0" } : {}),
    width: flightType !== FLIGHT_TYPE_CF ? 374.5 : 280,
  },

  [theme.down("sm")]: {
    width: "100%",
    borderRadius: 4,
  },

  "&.notFilled": {
    backgroundColor: "#f5d1d1",
    transition: "background-color .3s ease",
  },
}));

export const AirportInput = styled("input")(({ theme, type, flightType }) => ({
  ...(type === "from" ? { borderRadius: "4px 0 0 4px" } : {}),
  ...theme.paddings(0, 75, 0, 50),
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  border: "2px solid transparent",
  fontSize: 14,
  color: "#333",
  background: "none",

  [theme.down("md")]: {
    ...(type === "to" && flightType !== FLIGHT_TYPE_CF ? { borderRadius: "0 4px 4px 0" } : {}),
  },

  [theme.down("sm")]: {
    borderRadius: 4,
  },

  "&:focus, &:active": {
    outline: "none",
    borderColor: "#ff8100",
  },
}));

export const InputIconBlock = styled("span")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  left: 25,
  top: 20,
  zIndex: 5,
  color: "#b7b7b7",
  fontSize: 20,
}));

export const FlagImage = styled("img")(({ theme }) => ({
  width: 22,
  height: 22,
}));

export const InputPlaceholder = styled("div")(({ theme }) => ({
  ...theme.paddings(20, 15, 0, 54),
  height: 60,
  width: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  fontSize: 14,
  transition: "all .2s ease",
  color: "#b7b7b7",

  "&.not-empty": {
    ...theme.paddings(4, 15, 0, 50),
    fontSize: 11,
  },
}));

export const InputAirportCodeAndClearBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  padding: 5,
  gap: 5,
  position: "absolute",
  right: 15,
  top: "calc(50% - 14px)",
  backgroundColor: theme.palette.white,

  "& > span": {
    fontSize: 14,
    color: "#b7b7b7",
    lineHeight: 1,
  },

  "& > svg": {
    fontSize: 18,
    color: "#b7b7b7",
    cursor: "pointer",
  },
}));
