import styled from "styled-components";

export const Wrapper = styled("div")(({theme}) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	maxWidth: 900,
	margin: "30px auto",

	"& > div": {
		fontSize: "15px",
		lineHeight: "24px",
	},

	[theme.down("md")]: {
		maxWidth: 700,
	},

	[theme.down("sm")]: {
		maxWidth: 500,
	},

	[theme.down("xs")]: {
		width: "100%",
		padding: "0px 20px",
	}
}));

export const PaymentInfoTitle = styled("h1")(({theme}) => ({
  marginBottom: 20,
  textAlign:"center",
  textTransform: "uppercase",
  fontSize: 24,
  fontWeight: 700,
}));

export const About = styled("div")(({theme}) => ({
  marginTop: 12,
}))

export const AboutMain = styled("p")(({theme})=>({
  fontSize: "15px",
  lineHeight: "24px",
}))

export const AboutSecondary = styled("p")(({theme})=>({
  marginTop: "16px",

  fontSize: "15px",
  lineHeight: "24px",
}))

export const PaymentVariants = styled("div")(({theme}) => ({
  marginTop: 32,
}));

export const PaymentVariantsTitle = styled("h4")(({theme}) => ({
  fontSize: 17,
  fontWeight: 700,
}));

export const PaymentVariantsList = styled("ul")(({theme}) => ({
  marginTop: 16,
  paddingLeft: 32,
    
    "&:first-child(1)": {
      paddingTop: 0,
    },
}));

export const Variants = styled("li")(({theme}) => ({
  paddingTop: 5,
}));

export const Security = styled("div")(({theme})=>({
  marginTop: 32,
}))

export const SecurityTitle = styled("h4")(({theme}) => ({
  fontSize: 17,
  fontWeight: 700,
}))

export const SecurityContent = styled("p")(({theme})=>({
  marginTop: 16,
}));

export const SecuritySubContent = styled("p")(({theme})=>({
  marginTop: 16,
}));

export const Concern = styled("div")(({theme})=>({
  marginTop: 32,
}));

export const ConcernTitle = styled("h4")(({theme}) => ({
  fontSize: 17,
  fontWeight: 700,
}));

export const ConcernContent = styled("p")(({theme}) => ({
  marginTop: 16,
}));

export const PaymentFaults = styled("div")(({theme})=>({
  marginTop: 16,
}));

export const PaymentFaultsTitle = styled("h5")(({theme})=>({
  fontSize: 15,
  fontWeight: 700,
}));

export const PaymentFaultsContent = styled("ul")(({theme}) => ({
  listStyleType: "'-'",
}));

export const PaymentFaultsReasons = styled("li")(({theme}) => ({
  marginLeft: 5,
  paddingTop: 5,

  "&:firstChild(1)": {
    paddingTop: 0,
  }
}));