const metaKeywords = {
  ru: {
    homePage: "Дешевые авиабилеты, Авиабилеты туда и обратно, Международные рейсы, Бронирование авиабилетов, Бюджетные авиакомпании, Авиабилеты первого класса, Авиабилеты бизнес-класса, Авиабилеты с гибкой комиссией за изменения, Авиабилеты с бесплатной отменой, Билеты на самолет",
		cashback: "Бронируйте рейсы и получайте cashback, кэшбэк на рейсах, Рейсы + кэшбэк вознаграждения, Международные рейсы с кэшбэк, Рейсы бизнес-классом с кэшбэк, Зарабатывайте максимальный cashback на рейсах",
  },
  en: {
    homePage: "Cheap flights, Round trip flights, International flights, Flight booking, Budget airlines, First class flights, Business class flights, Flights with flexible change fees, Flights with free cancellation, flight tickets",
		cashback: "Book flights & earn cashback, Cashback on flights, Travel cashback on flights, Flights + cashback rewards, International flights with cashback, Business class flights with cashback, Earn maximum cashback on flights",
  },
  uz: {
    homePage: "Arzon chiptalar, Arzon biletlar, ikki tomonlama reyslar, xalqaro reyslar, reyslarni bron qilish, arzon aviakompaniyalar, birinchi klass reyslari, biznes klassli reyslar, o'zgaruvchan to'lovlar bilan parvozlar, bepul bekor qilingan reyslar, samolyot chiptalari",
		cashback: "Parvozlarni bron qiling va keshbek oling, reyslarda keshbek, Parvozlar + keshbek mukofotlari, keshbekli xalqaro reyslar, keshbek bilan biznes-klass reyslari, Parvozlarda maksimal naqd pul oling",
  },
};

export default metaKeywords;
