import * as Yup from "yup";
import { PS, PSP, SR } from "../constants";
import moment from "moment";

export const getBookingFormValidationSchema = (t, deadlineDate) =>
  Yup.object().shape({
    payer_email: Yup.string().email(t("invalid_email")).required(t("required")),
    payer_phone: Yup.string()
      .test("validPhone", t("invalid_phone"), value => {
        const length = value?.replace(/[^\d]/g, "").length;
        return length > 10;
      })
      .required(t("required")),
    passengers: Yup.array().of(
      Yup.object().shape({
        surname: Yup.string()
          .min(2, t("min_2_symbol"))
          .test("onlyAZSpace", t("only_a_z_space"), value => /^[A-Z\s]*$/i.test(value))
          .required(t("required")),
        name: Yup.string()
          .min(2, t("min_2_symbol"))
          .test("onlyAZSpace", t("only_a_z_space"), value => /^[A-Z\s]*$/i.test(value))
          .required(t("required")),
        middle_name: Yup.string().when(["document_type"], ([documentType], schema) =>
          documentType !== PS && documentType !== SR ? schema.nullable() : schema.required(t("required"))
        ),
        citizenship: Yup.string().required(t("required")),
        gender: Yup.string().required(t("required")),
        date_of_birth: Yup.string()
          .test("validDate", t("check_date"), date => moment(date, "DD.MM.YYYY", true).isValid())
          .when(["type"], ([type], schema) => {
            if (type === "adt") {
              const min = moment("01.01.1900", "DD.MM.YYYY");
              const max = moment(deadlineDate, "DD.MM.YYYY").add(-12, "years");

              return schema.test("checkDateForADT", t("incorrect_date_from_adt"), value => {
                const inspect = moment(value, "DD.MM.YYYY HH:mm");

                return (min.isBefore(inspect) || min.isSame(inspect)) && (max.isAfter(inspect) || max.isSame(inspect));
              });
            } else if (type === "chd") {
              const min = moment(deadlineDate, "DD.MM.YYYY").add(-12, "years");
              const max = moment(deadlineDate, "DD.MM.YYYY").add(-2, "years");

              return schema.test("checkDateForCHD", t("incorrect_date_from_chd"), value => {
                const inspect = moment(value, "DD.MM.YYYY HH:mm");

                return min.isBefore(inspect) && (max.isAfter(inspect) || max.isSame(inspect));
              });
            } else {
              return schema.test("checkInfDate", t("incorrect_date_from_inf"), value => {
                const inspect = moment(value, "DD.MM.YYYY HH:mm");

                if (moment().isBefore(inspect) || moment().isSame(inspect)) return false;

                const min = moment(deadlineDate, "DD.MM.YYYY").add(-2, "years");
                const max = moment(deadlineDate, "DD.MM.YYYY").add(-14, "days");

                return min.isBefore(inspect) && max.isAfter(inspect);
              });
            }
          })
          .when(["type", "document_type"], ([type, documentType], schema) => {
            if (type === "adt" && documentType === SR) {
              return schema.test("checkADTDateOfBirth", t("check_date_and_change_doc"), value => {
                const inspect = moment(value, "DD.MM.YYYY HH:mm");
                const deadline = moment(deadlineDate, "DD.MM.YYYY").add(-14, "years");
                return deadline.isBefore(inspect);
              });
            }

            if (type === "adt" && documentType === PS) {
              return schema.test("checkADTDateOfBirth", t("check_date_and_change_doc"), value => {
                const inspect = moment(value, "DD.MM.YYYY HH:mm");
                const deadline = moment(deadlineDate, "DD.MM.YYYY").add(-14, "years");
                return deadline.isAfter(inspect) || deadline.isSame(inspect);
              });
            }

            return schema;
          })
          .required(t("required")),
        document_type: Yup.string().required(t("required")),
        document_number: Yup.string().when(["document_type"], ([documentType], schema) => {
          if (documentType === PS) {
            return schema
              .test(
                "documentTypeIsPS",
                t("ps_should_be_10_digits"),
                value => !isNaN(Number(value)) && value.length === 10
              )
              .required(t("required"));
          }
          if (documentType === PSP) {
            return schema
              .test(
                "documentTypeIsPS",
                t("psp_should_be_9_digits"),
                value => !isNaN(Number(value)) && value.length === 9
              )
              .required(t("required"));
          }
          if (documentType === SR) {
            return schema
              .test("incorrectDocNumber", t("incorrect_doc_number"), value => {
                let count = value?.length;

                if (count < 9 || count > 12) {
                  return false;
                } else {
                  if (isNaN(Number(value?.substr(-6)))) {
                    return false;
                  }
                  count = count - 6;
                  const lettersCode = value?.substr(0, count);

                  if (!/^[А-Я]*$/i.test(lettersCode?.substr(-2))) {
                    return false;
                  }
                  count = count - 2;
                  const latinLetters = lettersCode?.substr(0, count);

                  if (!/^[IVX]*$/i.test(latinLetters)) {
                    return false;
                  }
                }

                return true;
              })
              .required(t("required"));
          }

          return schema.max(15, t("incorrect_doc_number")).required(t("required"));
        }),
        expiration_date: Yup.string().when("document_type", {
          is: documentType => documentType !== PS && documentType !== SR,
          then: schema =>
            schema
              .test("validDate", t("check_date"), date => moment(date, "DD.MM.YYYY", true).isValid())
              .test("expirationDate", t("expired_date"), date => {
                const inspect = moment(date, "DD.MM.YYYY HH:mm");
                const deadline = moment(deadlineDate, "DD.MM.YYYY");

                return deadline.isBefore(inspect);
              })
              .required(t("required")),
        }),
        email: Yup.string().when("type", {
          is: "adt",
          then: schema => schema.email(t("invalid_email")).required(t("required")),
        }),
        phone: Yup.string().when("type", {
          is: "adt",
          then: schema =>
            schema
              .test("validPhone", t("invalid_phone"), value => {
                const length = value?.replace(/[^\d]/g, "").length;
                return length > 10;
              })
              .required(t("required")),
        }),
      })
    ),
  });
