import { useEffect } from "react";

const useEventListener = (
  eventType,
  handler,
  element = window,
  options = {},
) => {
  useEffect(() => {
    if (!element || !eventType || (typeof handler !== "function")) return;
    element.addEventListener(eventType, handler, options);

    return () => {
      element.removeEventListener(eventType, handler, options);
    };
  });
};

export default useEventListener;
