import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 20,
  flexGrow: 1,

  [theme.down("lg")]: {
    width: "100%",
  },
}));

export const ShowMoreButtonBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  ...theme.paddings(15),
}));

export const ShowMoreButton = styled("button")(({ theme }) => ({
  ...theme.paddings(5, 10),
  width: 170,
  height: 42,
  color: "#fff",
  backgroundColor: "#01A0D4",
  border: "1px solid #01A0D4",
  fontSize: 14,
  cursor: "pointer",
}));
