import styled from "styled-components";

export const Wrapper = styled("div")(({theme}) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	maxWidth: 900,
	margin: "40px auto",

	"& > div": {
		fontSize: "15px",
		lineHeight: "24px",
	},

	[theme.down("md")]: {
		maxWidth: 700,
	},

	[theme.down("sm")]: {
		maxWidth: 500,
	},

	[theme.down("xs")]: {
		width: "100%",
		padding: "0px 20px",
	},
}));

export const PrivacyPolicyTitle = styled("h4")(({theme}) => ({
	fontSize: 20,
	fontWeight: 700,
	textTransform: "uppercase",
	textAlign: "center",
	lineHeight: 2,
}));

export const PrivacyPolicySubtitle = styled("p")(({theme}) => ({
	fontSize: 16,
	fontWeight: 700,
}));

export const PrivacyPolicyItems =styled("div")(({theme}) => ({
	marginTop: "32px",
}));

export const PrivacyPolicyItemsTitle =styled("h4")(({theme}) => ({
	marginBottom: "16px",
	fontWeight: 700,
}));

export const PrivacyPolicyItemsContent =styled("p")(({theme}) => ({
	"& > ul": {
		marginLeft: 14,
	},
}));
