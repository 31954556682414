import i18next from "i18next";
import { About, Deals, IataLogo, LegalInformation, LinkItem, LinksBlock, LogosBlock, PaymentsLogo, Socials, SocialsLink, TkpLogo, Wrapper } from "./style";

const FooterTopComponent = () => {
  const { t } = i18next;

	return (	
    <Wrapper>
      <LinksBlock>
        <LegalInformation>
          <h2>{t("legal_information")}</h2>
          <div>
            <LinkItem to="/rules-of-use-info">{t("rules_of_use")}</LinkItem>
            <LinkItem to="/public-offer-info">{t("offer")}</LinkItem>
            <LinkItem to="/privacy-policy-info">{t("privacy_policy")}</LinkItem>
            <LinkItem to="/payment-info">{t("payment")}</LinkItem>
          </div>
        </LegalInformation>

        <Deals>
					<h2>{t("deals")}</h2>
					<div>
						<LinkItem to="/cashback-info">{t("cashback_text")}</LinkItem>
					</div>
				</Deals>
				
				<About>
          <h2>{t("wannatalk")}</h2>
          <div>
            <LinkItem href="#">{t("about_company")}</LinkItem>
            <LinkItem to="/contacts">{t("contact_information")}</LinkItem>
          </div>
          <SocialsLink>
            <LinkItem href="/" target="_blank">
              <Socials src="/socials/instagram-white.svg" alt="Instagram" />
            </LinkItem>
            <LinkItem href="/" target="_blank">
              <Socials src="/socials/telegram-white.svg" alt="Telegram" />
            </LinkItem>
            <LinkItem href="/" target="_blank">
              <Socials src="/socials/facebook-white.svg" alt="Facebook" />
            </LinkItem>
          </SocialsLink>
        </About>
      </LinksBlock>

      <LogosBlock>
        <div>
          <PaymentsLogo src="/images/payment-logos.png" alt="payment-logos" />
        </div>

        <div>
          <IataLogo src="/images/iata-logo.png" alt="iata-logo" />
          <TkpLogo src="/images/tkp-logo.png" alt="tkp-logo" />
        </div>

      </LogosBlock>
    </Wrapper>
  );
};

export default FooterTopComponent;