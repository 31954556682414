import { BY_MIN_PRICE } from "../constants";
import { objectsToParams, paramsToArrayObject } from "./common";

export const handleFilterParamsChange = async payload => {
  const search = window.location.search;
  let searchParams = new URLSearchParams(search);
  searchParams = paramsToArrayObject(searchParams.entries());

  switch (payload.type) {
    case "baggage":
      handleChangeBaggage(searchParams, payload.values);
      break;
    case "transfers":
      handleChangeTransfers(searchParams, payload.values);
      break;
    case "sorting":
      handleChangeSorting(searchParams, payload.values);
      break;
    case "airlines":
      handleChangeAirlines(searchParams, payload.values);
      break;
    case "flightNumbers":
      handleChangeFlightNumbersList(searchParams, payload.values);
      break;
    case "price":
      handleChangePrice(searchParams, payload.values);
      break;
    case "airports":
      handleChangeAirports(searchParams, payload.values);
      break;
    case "duration":
      handleChangeDuration(searchParams, payload.values);
      break;
    case "departureTime":
      handleChangeDepartureTime(searchParams, payload.values);
      break;
    case "arrivalTime":
      handleChangeArrivalTime(searchParams, payload.values);
      break;
    default:
      break;
  }
};

const handleChangeBaggage = (searchParams, baggage) => {
  let params = [...searchParams];
  const { withBaggage, withoutBaggage } = baggage.values;

  if (withBaggage) {
    if (!params.find(x => x.key === "withBaggage")) {
      params.push({ key: "withBaggage", value: 1 });
    }
  } else {
    params = params.filter(value => value.key !== "withBaggage");
  }

  if (withoutBaggage) {
    if (!params.find(x => x.key === "withoutBaggage")) {
      params.push({ key: "withoutBaggage", value: 1 });
    }
  } else {
    params = params.filter(value => value.key !== "withoutBaggage");
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeTransfers = (searchParams, transfers) => {
  let params = [...searchParams];
  const { withoutTransfers, oneTransfer, manyTransfers } = transfers.values;

  if (withoutTransfers) {
    if (!params.find(x => x.key === "withoutTransfers")) {
      params.push({ key: "withoutTransfers", value: 1 });
    }
  } else {
    params = params.filter(value => value.key !== "withoutTransfers");
  }

  if (oneTransfer) {
    if (!params.find(x => x.key === "oneTransfer")) {
      params.push({ key: "oneTransfer", value: 1 });
    }
  } else {
    params = params.filter(value => value.key !== "oneTransfer");
  }

  if (manyTransfers) {
    if (!params.find(x => x.key === "manyTransfers")) {
      params.push({ key: "manyTransfers", value: 1 });
    }
  } else {
    params = params.filter(value => value.key !== "manyTransfers");
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeSorting = (searchParams, sorting) => {
  let params = [...searchParams];

  if (sorting.value === BY_MIN_PRICE) {
    params = params.filter(value => value.key !== "sorting");
  } else {
    if (!params.find(x => x.key === "sorting")) {
      params.push({ key: "sorting", value: sorting.value });
    }
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeAirlines = (searchParams, airlines) => {
  let params = [...searchParams];
  const { isActive, values } = airlines;

  params = params.filter(value => value.key !== "airlines");

  if (isActive) {
    params.push({ key: "airlines", value: values.join() });
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangePrice = (searchParams, prices) => {
  let params = [...searchParams];
  const { isActive, values } = prices;

  params = params.filter(value => value.key !== "price");

  if (isActive) {
    params.push({ key: "price", value: `${values.min}-${values.max}` });
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeFlightNumbersList = (searchParams, flightNumbers) => {
  let params = [...searchParams];
  const { isActive, values } = flightNumbers;

  params = params.filter(value => value.key !== "flightNumbers");

  if (isActive) {
    params.push({ key: "flightNumbers", value: values.join(",") });
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeAirports = (searchParams, airports) => {
  let params = [...searchParams];
  const { isActive, values } = airports;

  params = params.filter(value => value.key !== "airports");

  if (isActive) {
    const string = values.map(value => {
      let result = "";

      if (value.departures.length) {
        result = `${result}${value.routeIndex}_departures-${value.departures.join()};`;
      }

      if (value.arrivals.length) {
        result = `${result}${value.routeIndex}_arrivals-${value.arrivals.join()};`;
      }

      return result;
    });

    params.push({ key: "airports", value: string.join("") });
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeDuration = (searchParams, duration) => {
  let params = [...searchParams];
  const { isActive, values } = duration;

  params = params.filter(value => value.key !== "duration");

  if (isActive) {
    const string = values.map(value => {
      return `${value.routeIndex}_${value.values.min}-${value.values.max};`;
    });

    params.push({ key: "duration", value: string.join("") });
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeDepartureTime = (searchParams, departureTime) => {
  let params = [...searchParams];
  const { isActive, values } = departureTime;

  params = params.filter(value => value.key !== "departureTime");

  if (isActive) {
    const string = values.map(value => {
      return `${value.routeIndex}_${value.values.min}-${value.values.max};`;
    });

    params.push({ key: "departureTime", value: string.join("") });
  }

  window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeArrivalTime = (searchParams, arrivalTime) => {
  let params = [...searchParams];
  const { isActive, values } = arrivalTime;

  params = params.filter(value => value.key !== "arrivalTime");

  if (isActive) {
    const string = values.map(value => {
      return `${value.routeIndex}_${value.values.min}-${value.values.max};`;
    });

    params.push({ key: "arrivalTime", value: string.join("") });
  }

  window.history.pushState(null, null, objectsToParams(params));
};
