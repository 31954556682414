import { NotFoundRecommFirstBlock, NotFoundRecommMargin, NotFoundRecommSecondBlock, Wrapper } from "./style";

const NotFoundRecommendationsComponent = ({ byFilters = false }) => {
  const secondText = byFilters
    ? "Пожалуйста, измените условия фильтров"
    : "Пожалуйста, попробуйте еще раз с другими  датами или аэропортом";

  return (
    <Wrapper>
      <NotFoundRecommMargin>
        <NotFoundRecommFirstBlock>Мы не смогли найти билеты, соответствующие Вашему запросу</NotFoundRecommFirstBlock>
        <NotFoundRecommSecondBlock>{secondText}</NotFoundRecommSecondBlock>
      </NotFoundRecommMargin>
    </Wrapper>
  );
};

export default NotFoundRecommendationsComponent;
