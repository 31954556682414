import i18next from "i18next";
import Card from "../../ui/Card";

import { Link } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import { CardSection, LinkItem, Subtitle, Title, Wrapper } from './style';

export const CashBackInfo = () => {
	const { t } = i18next;
	const { isAuth } = useAuth();
	
	return (
		<Wrapper>
			<LinkItem to="/">
				{t("to_main")}
			</LinkItem>

			<Title>{t("cashback_text")}</Title>

			{!isAuth ? (
				<Subtitle>
					{t("cashback_message")} 
					<Link to="/auth" className="link">{t("register")}</Link>
				</Subtitle>
			) : (
				<Subtitle>
					{t("cashback_message_auth")}
				</Subtitle>			
			)}

			<CardSection>
				<Card 
					imgSrc="./images/AK/Aeroflot_logo.svg"
					title={t("aeroflot")}
					cashback="3%"
					desc={t("cashback_from")}
				/>

				<Card 
					imgSrc="./images/AK/Uzbekistan_Airways_logo.svg"
					title="Uzbekistan Airways"
					cashback="3%"
					desc={t("cashback_from")}
				/>

				<Card 
					imgSrc="./images/AK/Turkish_Airlines_logo.svg"
					title={t("turkish")}
					cashback="3%"
					desc={t("cashback_from")}
				/>

				<Card 
					imgSrc="./images/AK/Belavia_logo.svg"
					title={t("belavia")}
					cashback="3%"
					desc={t("cashback_from")}
				/>

				<Card 
					imgSrc="./images/AK/logo_v2.svg"
					title={t("ural")}
					cashback="3%"
					desc={t("cashback_from")}
				/>

				<Card 
					imgSrc="./images/AK/S7_new_logo.svg"
					title={t("s7")}
					cashback="2%"
					desc={t("cashback_from")}
				/>

				{/* <Card 
					imgSrc="./images/AK/KoreanAir_logo.svg"
					title={t("korea")}
					cashback="3%"
					desc={t("cashback_from")}
				/> */}

				<Card 
					imgSrc="./images/AK/Qanot_Sharq_logo.svg"
					title={t("qanot")}
					cashback="3%"
					desc={t("cashback_from_in")}
				/>

				<Card 
					imgSrc="./images/AK/apg-logo-2023-white.png"
					title={t("apgAS")}
					cashback="2%"
					desc={t("cashback_from")}
				/>

				<Card 
					imgSrc="./images/AK/Logo-pobeda-en.svg"
					title={t("pobeda")}
					cashback="0%"
					desc={t("cashback0")}
				/>

				<Card 
					imgSrc="./images/AK/Air_Astana_logo.svg"
					title={t("airastana")}
					cashback="0%"
					desc={t("cashback0")}
				/>

				<Card 
					imgSrc="./images/AK/Fly-aristan.png"
					title={t("flyaristan")}
					cashback="0%"
					desc={t("cashback0")}
				/>
			</CardSection>	
		</Wrapper>
	);
};