import _ from "lodash";
import { ALL_COUNTRY, FRIENDLY_COUNTRY, ONLY_RU_COUNTRY } from "../constants";
import { friendlyCountriesCodes } from "../config";

export const getRoutesCountries = (routes, included) => {
  let airportList = [];

  _.map(routes, function (route) {
    _.map(route.segments, function (segment) {
      airportList = [...airportList, segment.arrival.airport, segment.departure.airport];
    });
  });

  airportList = _.uniq(airportList);

  return _.uniq(
    _.map(airportList, function (item) {
      return included.airport[item].country;
    })
  );
};

export const getFlightDocsType = routesCountries => {
  if (routesCountries.length === 1 && _.first(routesCountries) === "ru") {
    return ONLY_RU_COUNTRY;
  }

  let isFriendly = true;

  _.forEach(routesCountries, function (item) {
    if (
      _.findIndex(friendlyCountriesCodes, function (o) {
        return o === item;
      }) === -1
    ) {
      isFriendly = false;
    }
  });

  return isFriendly ? FRIENDLY_COUNTRY : ALL_COUNTRY;
};

export const normalizePhone = phone => {
  const formattedPhone = phone.replace(/[^\d]/g, "");

  return `+${formattedPhone}`;
};
