import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 15,
}));

export const RulesAccordion = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  padding: 15,
  border: "1px solid #9e9e9e",
}));

export const RulesAccordionTitle = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterBetween,
  fontWeight: 600,
}));

export const RulesAccordionToggleButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  fontSize: 24,
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",

  "&.active > svg": {
    lineHeight: 1,
    transform: "rotate(180deg)",
  },
}));

export const RulesAccordionContent = styled("div")(({ theme }) => ({
  marginTop: 20,
}));

export const SelectRoutes = styled.div`
`;

export const StyledPaper = styled.div`
  padding: 0 16px;
  max-height: 600px;
  background-color: #4086e40a;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
  padding: 15px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #dfe5ec;
  }

  ::-webkit-scrollbar-thumb {
    background: #1d3557;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #1d3557;
  }
`;