import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 10,

  [theme.down("xs")]: {
    gap: 5,
  },
}));

export const TimelimitValueBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  flexDirection: "column",
}));

export const TimelimitValueItem = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  fontWeight: 600,
  fontSize: 24,
  color: "#333",
  backgroundColor: "#dee3e6",
  width: 50,
  height: 50,
}));

export const TimelimitLabelItem = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: "#606060",
}));

export const TimelimitExpired = styled("div")(({ theme }) => ({
  fontWeight: 600,
  color: "#151515",
}));
