import { ContainerComponent } from "../../ui";
import BookingFormComponent from "../BookingFormComponent";
import PriceComponent from "../PriceComponent";
import RoutesDataComponent from "../RoutesDataComponent";
import { Block, RoutesDataAndFormBlock, Wrapper } from "./style";

const BookingComponent = ({ passengers, handleBookClick }) => {
  return (
    <Wrapper>
      <ContainerComponent>
        <Block>
          <RoutesDataAndFormBlock>
            <RoutesDataComponent />
            <BookingFormComponent passengers={passengers} handleBookClick={handleBookClick} />
          </RoutesDataAndFormBlock>

          <PriceComponent />
        </Block>
      </ContainerComponent>
    </Wrapper>
  );
};

export default BookingComponent;
