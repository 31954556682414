import styled from "styled-components";

export const RouteBlock = styled("div")(({ theme }) => ({
  // ...theme.margins(8, 0),
  // "&:nth-of-type(1)": {
  // ...theme.marginsY(2, 0),
  // },
}));

export const RouteCities = styled("div")(({ theme }) => ({
  fontSize: 14,
  marginBottom: 4,
  color: "#151515",
}));

export const CheckboxesBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(5, 0),
  ...theme.margins(0, -20),
  gap: 3,
}));

export const CheckboxItem = styled("div")(({ theme }) => ({
  ...theme.paddings(3, 20),

  "&:hover": {
    backgroundColor: "#c7e0f4",
  },
}));
