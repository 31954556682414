import { useEffect, useRef } from "react";

import classNames from "classnames";
import { BiChevronDown } from "react-icons/bi";

import { useToggle } from "../../../hooks";

import { DropdownBlock, DropdownIcon, DropdownOption, StyledButton, Wrapper } from "./style";

const Select = ({ value, options, onSelect }) => {
  const node = useRef();
  const buttonRef = useRef();
  const {
    isTrue,
    toggle,
    set: { off },
  } = useToggle();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node.current?.contains(e.target) || buttonRef.current?.contains(e.target)) {
      return;
    }

    off();
  };

  const handleSelect = optionValue => {
    onSelect(optionValue);
    off();
  };

  return (
    <Wrapper>
      <StyledButton ref={buttonRef} onClick={toggle} className={classNames({ active: isTrue })}>
        {options.find(option => option.value === value)?.label}

        <DropdownIcon className={classNames({ active: isTrue })}>
          <BiChevronDown />
        </DropdownIcon>
      </StyledButton>

      <DropdownBlock className={classNames({ active: isTrue })} ref={node}>
        {options.map((option, index) => (
          <DropdownOption key={index} onClick={() => handleSelect(option.value)}>{option.label}</DropdownOption>
        ))}
      </DropdownBlock>
    </Wrapper>
  );
};

export default Select;
