import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
}));

export const Title = styled("div")(({ theme }) => ({
  fontSize: 20,
  color: "#787878",
  borderBottom: "1px solid #cecece",
  paddingBottom: 5,
  marginBottom: 10,
}));

export const Description = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: "#787878",
  marginBottom: 10,
}));

export const FormBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: 5,

  [theme.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [theme.down("sm")]: {
    gridTemplateColumns: "1fr",
  },
}));

export const InputBlock = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));
