import { LoadingBlock } from "./style";

const Loading = ({ color }) => {
  return (
    <LoadingBlock color={color || "#151515"}>
      <div />
      <div />
      <div />
      <div />
    </LoadingBlock>
  );
};

export default Loading;
