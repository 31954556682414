const metaTitles = {
  ru: {
    homePage: "Мета загаловок главной страницы",
		cashbackPage: "Акции - Кэшбэк AlifAvia.uz",
  },
  en: {
    homePage: "Home page meta title",
		cashbackPage: "Deals - Cashback AlifAvia.uz",
  },
  uz: {
    homePage: "Мета загаловок главной страницы",
		cashbackPage: "Aksiyalar - Cashback AlifAvia.uz",
  },
};

export default metaTitles;
