import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const IconBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "absolute",
  right: 10,
  top: 12,

  "& > svg": {
    fill: "#b7b7b7",
    cursor: "pointer",
  }
}));

export const StyledSelect = styled("select")(({ theme, }) => ({
  ...theme.paddings(10, 30, 10, 10),
  width: "100%",
  border: "1px solid #b7b7b7",
  borderRadius: 0,
  outline: "none",
  "-moz-appearance": "none",
  "-webkit-appearance": "none",
}));
