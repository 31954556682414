import styled from "styled-components";

export const Wrapper = styled("div")(({theme}) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	width: "100%",
	maxWidth: "370px",
	height: "auto",
	padding: "25px",

	backgroundColor: "#fff",
	borderRadius: "10px",
	boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

	position: "relative",
	overflow: "hidden",

	[theme.down("md")]: {
		maxWidth: "340px",
	},

}));

export const ImgCashback = styled("div")(({theme}) => ({
	width: "100%",
	height: "auto",
}));

export const CardDesc = styled("div")(({theme}) => ({
	marginTop: "20px",
}));

export const Img = styled("div")(({theme}) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "41px",

	"& img": {
		width: "100%",
		maxWidth: "180px",
		height: "auto",
	},
}));

export const Cashback = styled("div")(({theme}) => ({
	padding: "3px 17px",
	position: "absolute",
	top: "20px",
	right: "-5px",
	zIndex: "1",

	backgroundColor: theme.palette.searchButtonBg,
	color: theme.palette.white,
	borderRadius: "5px",
	boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

	".transparent": {
		backgroundColor: "none",
		boxShadow: "none",
	},

	[theme.down("sm")]: {
		padding: "3px 13px",
	}
}));

export const Title = styled("div")(({theme}) => ({
	paddingBottom: "5px",
	fontSize: "15px",
	fontWeight: "bold",

	[theme.down("sm")]: {
		fontSize: "13px"
	},
}));

export const Desc = styled("div")(({theme}) => ({
	fontSize: "15px",
	fontWeight: "normal",
	lineHeight: "24px",

	[theme.down("sm")]: {
		fontSize: "13px"
	},
}));