import i18next from "i18next";
import { TimelimitExpired, TimelimitLabelItem, TimelimitValueBlock, TimelimitValueItem, Wrapper } from "./style";
import { useState } from "react";
import { useEffect } from "react";
import { noun } from "../../../utils";

const OrderTimelimitComponent = ({ timelimit, onTimerEnd }) => {
  const { t } = i18next;
  const [timelimitSecond, setTimelimitSecond] = useState(timelimit);

  useEffect(() => {
    let counter = setInterval(() => setTimelimitSecond(prev => prev - 1), 1000);

    return () => {
      clearTimeout(counter);
    };
  }, []);

  if (timelimitSecond < 0) {
    onTimerEnd();

    return <TimelimitExpired>{t("payment_timed_out")}</TimelimitExpired>;
  }

  const seconds = Math.floor(timelimitSecond % 60);
  const minutes = Math.floor((timelimitSecond / 60) % 60);
  const hours = Math.floor((timelimitSecond / (60 * 60)) % 24);
  const days = Math.floor(timelimitSecond / (60 * 60 * 24));

  return (
    <Wrapper>
      <TimelimitValueBlock>
        <TimelimitValueItem>{days}</TimelimitValueItem>
        <TimelimitLabelItem>{noun(days, t("day_one"), t("day_more"), t("days"))}</TimelimitLabelItem>
      </TimelimitValueBlock>
      <TimelimitValueBlock>
        <TimelimitValueItem>{hours}</TimelimitValueItem>
        <TimelimitLabelItem>{noun(hours, t("hour_one"), t("hour_more"), t("hours"))}</TimelimitLabelItem>
      </TimelimitValueBlock>
      <TimelimitValueBlock>
        <TimelimitValueItem>{minutes}</TimelimitValueItem>
        <TimelimitLabelItem>{noun(minutes, t("minute_one"), t("minute_more"), t("minutes"))}</TimelimitLabelItem>
      </TimelimitValueBlock>
      <TimelimitValueBlock>
        <TimelimitValueItem>{seconds}</TimelimitValueItem>
        <TimelimitLabelItem>{noun(seconds, t("second_one"), t("second_more"), t("seconds"))}</TimelimitLabelItem>
      </TimelimitValueBlock>
    </Wrapper>
  );
};

export default OrderTimelimitComponent;
