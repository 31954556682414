import { useSelector } from "react-redux";
import { LineDivider, PriceItem, Wrapper } from "./style";
import { normalizePrice } from "../../../utils";
import i18next from "i18next";

const PriceComponent = () => {
  const { t } = i18next;
  const { currency } = useSelector(state => state.appConfigs);
  const { totalPrice } = useSelector(state => state.prebook);

  return (
    <Wrapper>
      <PriceItem $isSmallText>
        <span>{t("flights_tickets")}:</span>
        <span>
          {normalizePrice(totalPrice[currency].toFixed(2))} <span>{currency}</span>
        </span>
      </PriceItem>

      <LineDivider />

      <PriceItem>
        <span>{t("total")}:</span>
        <span>
          {normalizePrice(totalPrice[currency].toFixed(2))} <span>{currency}</span>
        </span>
      </PriceItem>
    </Wrapper>
  );
};

export default PriceComponent;
