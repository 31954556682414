import MaskedInput from "react-text-mask";
import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const BeforeIconBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "absolute",
  left: 10,
  top: 12,

  "& > svg": {
    fill: "#b7b7b7",
    cursor: "pointer",
  }
}));

export const IconBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "absolute",
  right: 10,
  top: 12,

  "& > svg": {
    fill: "#b7b7b7",
    cursor: "pointer",
  }
}));

export const StyledInput = styled(MaskedInput)(({ theme, $hasClearIcon }) => ({
  ...theme.paddings(10, $hasClearIcon ? 30 : 10, 10, 30),
  width: "100%",
  border: "1px solid #b7b7b7",
  borderRadius: 0,
  outline: "none",
}));
