import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, fixed, flex }) => ({
  ...theme.paddings(0, 15),
  ...theme.margins(0, "auto"),
  ...(fixed
    ? {
        maxWidth: 1500,

        [theme.upDown("lg", "xl")]: {
          maxWidth: 1200,
        },

        [theme.upDown("md", "lg")]: {
          maxWidth: 1000,
        },

        [theme.upDown("sm", "md")]: {
          maxWidth: 800,
        },

        [theme.upDown("xs", "sm")]: {
          maxWidth: 600,
        },

        [theme.down("xs")]: {
          maxWidth: "100%",
        },
      }
    : {
        maxWidth: "100%",
      }),
  ...(flex ? { display: "flex" } : {}),
}));
