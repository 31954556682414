import { companyReqId } from "../config/configs";

export const getToken = () => localStorage.getItem("token") || null;

export const baseParams = {
  company_req_id: companyReqId,
};

export const authOptions = {
  headers: {
    "Authorization": `Bearer ${getToken()}`,
  },
};
