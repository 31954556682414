export const DPT = "DPT";
export const SPT = "SPT";
export const UNT = "UNT";
export const UZC = "UZC";
export const VSL = "VSL";
export const EPA = "EPA";
export const PDC = "PDC";
export const DCD = "DCD";

export const INITIAL_CODE = "initial";
export const ALL_ORDERS_CODE = "allOrders";
export const ORDER_FAIL_CODE = "orderFail";
export const ORDER_SUCCESS_CODE = "success";
export const ORDER_CANCEL_ERROR_CODE = "orderCancelError";
export const APPLICATION_REFUND_CODE = "applicationRefund";
export const PARTIAL_REFUND_CODE = "partialRefund";
export const REFUND_CODE = "refund";
export const IN_PROGRESS_BOOK_CODE = "inProgressBook";
export const BOOKING_DONE_CODE = "bookingDone";
export const CERTIFICATE_ISSUED_CODE = "certificateIssued";
export const BALANCE_PAYMENT_REQUEST_CODE = "balancePaymentRequest";
export const BALANCE_PAYMENT_APPROVED_CODE = "balancePaymentApproved";
export const BOOKING_FAIL_CODE = "bookingFail";
export const PAY_FAIL_CODE = "payFail";
export const TICKETING_DONE_CODE = "ticketingDone";
export const PAY_SUCCESS_CODE = "paySuccess";
export const BOOKING_CANCEL_FROM_CLIENT_CODE = "bookingCancelFromClient";
export const BOOKING_CANCEL_FROM_ADMIN_CODE = "bookingCancelFromAdmin";
export const BOOKING_CANCEL = "bookingCancel";
export const BOOKING_CANCEL_ERROR_CODE = "bookingCancelError";
export const EXCHANGE_CODE = "exchange";
export const VOID_CODE = "void";
export const TICKETING_FAIL_CODE = "ticketingFail";

export const BOOK_STATUS_CODES = [
  { value: INITIAL_CODE, code: "initial" }, // 'Создан заказ'
  { value: BOOKING_FAIL_CODE, code: "booking_fail" }, // 'Ошибка при бронировании'
  { value: PAY_FAIL_CODE, code: "pay_fail" }, // 'Ошибка при оплате'
  { value: IN_PROGRESS_BOOK_CODE, code: "initial" }, // 'Создан заказ'
  { value: TICKETING_DONE_CODE, code: "ticketing_done" }, // 'Успешная оплата, билеты выписаны'
  { value: PAY_SUCCESS_CODE, code: "pay_success" }, // 'Успешная оплата, билеты в процессе выписки'
  { value: BOOKING_DONE_CODE, code: "booking_done" }, // 'Билет забронирован'
  { value: CERTIFICATE_ISSUED_CODE, code: "certificate_issued" }, // 'Выписан чартерный сертификат'
  { value: BOOKING_CANCEL_FROM_CLIENT_CODE, code: "order_cancel_from_client" }, // 'Заказ отменен клиентом'
  { value: BOOKING_CANCEL_FROM_ADMIN_CODE, code: "order_cancel_from_admin" }, // 'Заказ отменен администратором'
  { value: BOOKING_CANCEL, code: "order_cancel_by_job" }, // 'Заказ отменен по таймлимиту'
  { value: BOOKING_CANCEL_ERROR_CODE, code: "order_cancel_error" }, // 'Ошибка при отмене заказа'
  { value: APPLICATION_REFUND_CODE, code: "application_refund" }, // 'Создана заявка на возврат средств'
  { value: PARTIAL_REFUND_CODE, code: "partial_refund" }, // 'Частичный возврат средств по заказу'
  { value: REFUND_CODE, code: "refund" }, // 'Полный возврат средств по заказу'
  { value: EXCHANGE_CODE, code: "exchange" }, // 'Обмен билета'
  { value: VOID_CODE, code: "void" }, // 'Заказ аннулирован'
  { value: TICKETING_FAIL_CODE, code: "ticketing_fail" }, // 'Ошибка при выписке билетов'
  { value: ORDER_FAIL_CODE, code: "order_fail" }, // 'Ошибка при создании заказа'
  { value: BALANCE_PAYMENT_REQUEST_CODE, code: "balance_payment_request" }, // 'Заявка на выписку с баланса'
  { value: BALANCE_PAYMENT_APPROVED_CODE, code: "balance_payment_approved" }, // 'Заявка на выписку с баланса подтверждена'
];

export const BOOK_DONE_CODE = "BOOK_DONE";
export const PAY_DONE_CODE = "PAY_DONE";
export const TICKET_DONE_CODE = "TICKET_DONE";
export const CANCEL_DONE_CODE = "CANCEL_DONE";
export const WAIT_REFUND_CODE = "WAIT_REFUND";
export const REFUND_DONE_CODE = "REFUND_DONE";
export const PARTIAL_REFUND_DONE_CODE = "PARTIAL_REFUND_DONE";
export const VOID_DONE_CODE = "VOID_DONE";
export const ERROR_ORDER_CODE = "ERROR_ORDER";

export const BOOK_STATUS_COLOR = [
  { value: "initial", color: "#8B0000" },
  { value: "paySuccess", color: "#FFD700" },
  { value: "bookingDone", color: "#c49000" },
  { value: "inProgressBook", color: "#c49000" },
  { value: "ticketingDone", color: "#4caf50" },
  { value: "certificateIssued", color: "#4caf50" },
  { value: "ticketingFail", color: "#c49000" },
  { value: "exchange", color: "#FFE4B5" },
  { value: "void", color: "#4B0082" },
  { value: "orderFail", color: "#c49000" },
  { value: "bookingFail", color: "#c49000" },
  { value: "payFail", color: "#c49000" },
  { value: "bookingCancelFromClient", color: "#ff0302" },
  { value: "bookingCancelFromAdmin", color: "#ff0302" },
  { value: "bookingCancelError", color: "#c49000" },
  { value: "applicationRefund", color: "#87CEEB" },
  { value: "partialRefund", color: "#4682B4" },
  { value: "bookingCancel", color: "#ff0302" },
  { value: "refund", color: "#4682B4" },
  { value: "balancePaymentRequest", color: "#4390ae" },
  { value: "balancePaymentApproved", color: "#45b0b0" },
];
