import { ContentType, request } from "../api";

import { baseParams } from "../common";

export const getRequisites = async (params = {}) => request({
  path: "/requisite-info",
  method: "GET",
  query: baseParams,
  type: ContentType.Json,
  format: "json",
  ...params,
});
