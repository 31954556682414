import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { PrivacyPolicy } from "../../components/info/PrivacyPolicyComponent";
import { setShowSearch } from "../../store/appConfigsSlice";

const PrivacyPolicePage = () => {
	const dispatch = useDispatch();

	useEffect (() => {
		dispatch(setShowSearch(false))
	}, []);
	
	return <PrivacyPolicy />
};

export default PrivacyPolicePage;