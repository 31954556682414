import styled from "styled-components";

import { FLIGHT_TYPE_CF } from "../../../constants";

export const Wrapper = styled("div")(({ theme, flightType }) => ({
  display: "flex",
  gap: 1,
  width: flightType !== FLIGHT_TYPE_CF ? 481 : 266,
  position: "relative",

  [theme.down("xl")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 401 : 226,
  },

  [theme.down("lg")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 321 : 186,
  },

  [theme.down("md")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 374 : 188,
  },

  [theme.down("sm")]: {
    display: "grid",
    gridTemplateColumns: flightType !== FLIGHT_TYPE_CF ? "1fr 1fr" : "1fr",
    width: "100%",
  },
}));

export const FromDateBlock = styled("div")(({ theme, flightType }) => ({
  ...(flightType === FLIGHT_TYPE_CF ? { borderRadius: "0 4px 4px 0" } : {}),
  backgroundColor: theme.palette.white,
  width: flightType !== FLIGHT_TYPE_CF ? 240 : 266,
  height: 60,
  position: "relative",
  cursor: "default",
  transition: "background-color .3s ease",

  [theme.down("xl")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 200 : 226,
  },

  [theme.down("lg")]: {
    width: flightType !== FLIGHT_TYPE_CF ? 160 : 186,
  },

  [theme.down("md")]: {
    ...(flightType !== FLIGHT_TYPE_CF ? { borderRadius: "4px 0 0 4px" } : { borderRadius: 4 }),
    width: flightType !== FLIGHT_TYPE_CF ? 186.5 : "100%",
  },

  [theme.down("sm")]: {
    width: "100%",
  },

  "&.notFilled": {
    backgroundColor: "#f5d1d1",
    transition: "background-color .3s ease",
  },
}));

export const ToDateBlock = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.white,
  width: 240,
  position: "relative",
  cursor: "default",

  [theme.down("xl")]: {
    width: 200,
  },

  [theme.down("lg")]: {
    width: 160,
  },

  [theme.down("md")]: {
    width: 186.5,
  },

  [theme.down("sm")]: {
    width: "100%",
    borderRadius: "0 4px 4px 0",
  },
}));

export const InputPlaceholder = styled("div")(({ theme }) => ({
  ...theme.paddings(20, 15, 0, 54),
  height: 60,
  width: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  fontSize: 14,
  transition: "all .2s ease",
  color: "#b7b7b7",

  "&.not-empty": {
    ...theme.paddings(4, 15, 0, 50),
    fontSize: 11,
  },
}));

export const InputIconBlock = styled("span")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  left: 25,
  top: 21,
  zIndex: 5,
  color: "#b7b7b7",
  fontSize: 18,
}));

export const InputDate = styled("div")(({ theme }) => ({
  ...theme.paddings(20, 15, 0, 54),
  fontSize: 14,
  color: "#333",
}));

export const InputClearBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  padding: 5,
  gap: 5,
  position: "absolute",
  right: 15,
  top: "calc(50% - 14px)",
  backgroundColor: theme.palette.white,

  "& > svg": {
    fontSize: 18,
    color: "#b7b7b7",
    cursor: "pointer",
  },
}));
