import { useState } from "react";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import { RangeBlock, RangeText } from "./style";

const FiltersRangeSlider = ({ value, onChange, min, max, formatLabel, step = 1 }) => {
  const [currentValue, setCurrentValue] = useState(value);

  return (
    <RangeBlock>
      <RangeText>{`${formatLabel(currentValue.min)} — ${formatLabel(currentValue.max)}`}</RangeText>

      <InputRange
        minValue={min}
        maxValue={max}
        value={currentValue}
        onChange={val => setCurrentValue(val)}
        onChangeComplete={() => onChange(currentValue)}
        step={step}
      />
    </RangeBlock>
  );
};

export default FiltersRangeSlider;
