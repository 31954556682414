import { useSelector } from "react-redux";

import RouteItemComponent from "../RouteItemComponent";

import { Wrapper } from "./style";

const RoutesDataComponent = () => {
  const { routes } = useSelector(state => state.prebook);

  return (
    <Wrapper>
      {routes.map((route, index) => (
        <RouteItemComponent key={index} route={route} />
      ))}
    </Wrapper>
  );
};

export default RoutesDataComponent;
