import { useLayoutEffect } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { BalanceRechargeComponent, SeoTags } from "../../components";
import { useAuth } from "../../context/auth";
import { setShowSearch } from "../../store/appConfigsSlice";

const BalancePage = () => {
  const { isAuth } = useAuth();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(setShowSearch(false));
  }, [dispatch]);

  if (!isAuth) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <SeoTags pageKey="balancePage" />
      <BalanceRechargeComponent />
    </>
  );
};

export default withTranslation()(BalancePage);
