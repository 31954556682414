import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#dee3e6",
  flexGrow: 1,
  padding: "30px 0",
}));

export const Block = styled("div")(({ theme }) => ({
  ...theme.mixins.flexJustifyBetween,
  alignItems: "flex-start",
  gap: 10,
  padding: "10px 0",

  [theme.down("lg")]: {
    flexDirection: "column",
  },
}));

export const RoutesDataAndFormBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  flexGrow: 1,
  gap: 20,

  [theme.down("lg")]: {
    order: 1,
    width: "100%",
  },
}));
