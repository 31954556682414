import { useEffect, useState } from "react";

const useToggle = (initialValue = false) => {
  const [isTrue, setIsTrue] = useState(initialValue);

  useEffect(() => {
    setIsTrue(initialValue);
  }, [initialValue]);

  const toggle = () => setIsTrue(!isTrue);

  const on = () => setIsTrue(true);

  const off = () => setIsTrue(false);

  const bool = (boolean = initialValue) => setIsTrue(boolean);

  return {
    isTrue,
    toggle,
    set: { on, off, bool },
  };
};

export default useToggle;