import { useCallback, useMemo, useState } from "react";

import { RiCloseLine } from "react-icons/ri";

import { debounce } from "../../../../utils";

import CircularLoading from "../../CircularLoading";

import { IconBlock, StyledSelect, Wrapper } from "./style";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const TableFiltersSelect = ({ filterKey, options = [], onChangeFilter, placeholder }) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getData = useCallback(async (text) => {
    setIsLoading(true);
    await onChangeFilter(filterKey, text);
    setIsLoading(false);
  }, []);

  const debouncedGetData = useMemo(() => debounce(getData, 300), [getData]);

  const handleChange = (newValue) => {
    setIsOpen(false);
    setValue(newValue);
    debouncedGetData(newValue);
  };

  return (
    <Wrapper>
      <StyledSelect
        value={value}
        onChange={e => handleChange(e.target.value)}
        placeholder={placeholder}
        onMouseDown={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
      >
        <option value="" disabled>{placeholder}</option>
        {options.map(option => (
          <option value={option.value}>{option.label}</option>
        ))}
      </StyledSelect>

      <IconBlock>
        {(!isLoading && !isOpen) ? <BiChevronDown /> : null}
        {(!isLoading && isOpen) ? <BiChevronUp /> : null}
        {(!isLoading && value) ? <RiCloseLine onClick={() => handleChange("")} /> : null}
        {isLoading ? <CircularLoading size="16px" color="#b7b7b7" /> : null}
      </IconBlock>
    </Wrapper>
  );
};

export default TableFiltersSelect;
