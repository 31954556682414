import HeaderLogoComponent from "../HeaderLogoComponent";
import HeaderNavigationComponent from "../HeaderNavigationComponent";

import { StyledContainer, Wrapper } from "./style";

const HeaderComponent = () => {
  return (
    <Wrapper>
      <StyledContainer>
        <HeaderLogoComponent />
        <HeaderNavigationComponent />
      </StyledContainer>
    </Wrapper>
  );
};

export default HeaderComponent;
