import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  marginBottom: 15,

  "@media (max-width: 460px)": {
    gap: 15,
  },
}));

export const FiltersBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(6, 1fr)",
  gap: 15,

  [theme.down("xl")]: {
    gridTemplateColumns: "repeat(5, 1fr)",
  },

  [theme.down("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },

  [theme.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media (max-width: 460px)": {
    gridTemplateColumns: "1fr",
    margin: 0,
  },
}));

export const FilterButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  ...theme.paddings(5, 15),
  border: "1px solid #b7b7b7",
  backgroundColor: "#fff",
  color: "#000",
  borderRadius: 4,
  width: "100%",
}));

export const ButtonIcon = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  padding: 4,
  color: "#808080",
  transition: "all .3s ease",
  transform: "rotate(0deg)",

  "&.active": {
    transition: "all .3s ease",
    transform: "rotate(180deg)",
  },
}));
