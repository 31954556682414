import { Fragment, useEffect, useState } from "react";

import classNames from "classnames";
import i18next from "i18next";
import moment from "moment";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";

import breakpoints from "../../../theme/breakpoints";

import { getMonths } from "../../../utils";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { CalendarDropdownComponent } from "../../ui";
import {
  CalendarBody,
  CalendarHeader,
  CalendarHeaderButton,
  CalendarHeaderText,
  CalendarMonthBlock,
  CalendarMonthDay,
  CalendarMonthDaysBlock,
  CalendarMonthName,
  CalendarMonthTitle,
  CalendarMonthWeek,
  CalendarMonthWeeksBlock,
  CalendarMonths,
  CalendarMonthsBlock,
  CalendarMonthsNamesBlock,
  CalendarMonthsNamesList,
  CalendarNextButton,
  CalendarPrevButton,
} from "./style";

const CalendarComponent = ({
  show,
  onClose,
  type,
  flightType,
  monthsCount = 2,
  activeDay,
  onSelectDay,
  selectedDays,
  fromDateComponentRef,
  toDateComponentRef,
  isCompact,
}) => {
  const { width } = useWindowDimensions();

  const { t } = i18next;

  const currentDay = moment();
  const lastActiveDay = moment().add(1, "year").add(-1, "month");

  const months = getMonths();
  const [showedMonth, setShowedMonth] = useState(currentDay.clone().startOf("month").format("YYYY-MM-DD"));
  const [animate, setAnimate] = useState(null);
  const [activeMonthsCount, setActiveMonthCount] = useState(monthsCount);

  useEffect(() => {
    if (width > breakpoints.md) {
      setActiveMonthCount(2);
    } else {
      setActiveMonthCount(1);
    }
  }, [width]);

  const handleClose = () => {
    onClose();
  };

  const renderMonthsList = () => {
    let currentYear = months[0].startDate.get("year");

    const renderYear = month => {
      if (month.startDate.get("year") !== currentYear) {
        currentYear = month.startDate.get("year");

        return (
          <CalendarMonthName key={currentYear} className="active year" type={type}>
            {currentYear}
          </CalendarMonthName>
        );
      }

      return null;
    };

    return months.map(month => (
      <Fragment key={month.startDate.format("YYYY-MM-DD")}>
        {renderYear(month)}
        <CalendarMonthName
          className={showedMonth === month.startDate.format("YYYY-MM-DD") && "active"}
          onClick={() => changeMonth(month.startDate.format("YYYY-MM-DD"))}
          type={type}
        >
          {month.startDate.format("MMMM")}
        </CalendarMonthName>
      </Fragment>
    ));
  };

  const renderWeekHeader = () => {
    const weekDays = [...Array(7).keys()];

    return (
      <CalendarMonthWeeksBlock>
        {weekDays.map(day => {
          return (
            <CalendarMonthWeek key={`calendar-week-day-${day}`}>{moment().weekday(day).format("dd")}</CalendarMonthWeek>
          );
        })}
      </CalendarMonthWeeksBlock>
    );
  };

  const renderMonthDays = month => {
    const startDate = month.clone();
    const days = [];

    while (startDate.get("month") === month.get("month")) {
      days.push({ key: startDate.format("YYYY-MM-DD"), day: startDate.format("D") });
      startDate.add(1, "days");
    }

    return days.map(data => {
      const props = {
        position: moment(data.key).get("day") || 7,
        className: classNames({
          selected: selectedDays.includes(data.key),
          active: data.key === activeDay,
          disabled: moment(data.key).startOf("date").isBefore(currentDay.clone().startOf("date")),
        }),
        $selectedIndex: selectedDays.indexOf(data.key),
        onClick: () => onSelectDay(data.key),
        type,
      };

      return (
        <CalendarMonthDay key={data.key} {...props}>
          {data.day}
        </CalendarMonthDay>
      );
    });
  };

  const renderMonth = month => (
    <CalendarMonthBlock key={`month-${month.format("YYYY-MM")}`}>
      <CalendarMonthTitle>
        {month.format("MMMM")}, {month.get("year")}
      </CalendarMonthTitle>

      {renderWeekHeader()}

      <CalendarMonthDaysBlock>{renderMonthDays(month)}</CalendarMonthDaysBlock>
    </CalendarMonthBlock>
  );

  const renderMonths = () => {
    if (activeMonthsCount === 2) {
      if (moment(showedMonth).isSame(lastActiveDay.startOf("month"))) {
        return [moment(showedMonth).add(-1, "month"), moment(showedMonth)].map(month => renderMonth(month));
      } else {
        return [moment(showedMonth), moment(showedMonth).add(1, "month")].map(month => renderMonth(month));
      }
    }

    return renderMonth(moment(showedMonth));
  };

  const prevMonth = () => {
    setShowedMonth(prev =>
      activeMonthsCount === 2 && moment(prev).isSame(lastActiveDay.startOf("month"))
        ? moment(prev).add(-2, "month").startOf("month").format("YYYY-MM-DD")
        : moment(prev).add(-1, "month").startOf("month").format("YYYY-MM-DD")
    );
    setAnimate("animate-prev");
    setTimeout(() => setAnimate(null), 200);
  };

  const nextMonth = () => {
    setShowedMonth(prev => moment(prev).add(1, "month").startOf("month").format("YYYY-MM-DD"));
    setAnimate("animate-next");
    setTimeout(() => setAnimate(null), 200);
  };

  const changeMonth = newMonth => {
    if (moment(showedMonth).isBefore(moment(newMonth))) {
      setAnimate("animate-next");
    } else {
      setAnimate("animate-prev");
    }
    setShowedMonth(newMonth);
    setTimeout(() => setAnimate(null), 200);
  };

  const handleOneWayClick = () => {
    onSelectDay(null);
    onClose();
  };

  return (
    <CalendarDropdownComponent
      show={show}
      onClickOutside={handleClose}
      fromDateComponentRef={fromDateComponentRef}
      toDateComponentRef={toDateComponentRef}
      flightType={flightType}
      isCompact={isCompact}
    >
      <CalendarHeader centered={activeMonthsCount === 1}>
        {activeMonthsCount === 2 ? (
          <CalendarHeaderText>
            {type === "from" ? t("select_dep_date") : t("select_return_date")}
          </CalendarHeaderText>
        ) : type === "from" ? (
          <CalendarHeaderText>
            {t("select_dep_date")}
          </CalendarHeaderText>
        ) : null}

        {type === "to" && <CalendarHeaderButton onClick={handleOneWayClick}>{t("one_way")}</CalendarHeaderButton>}
      </CalendarHeader>

      <CalendarBody>
        {activeMonthsCount === 2 && (
          <CalendarMonthsNamesBlock>
            <CalendarMonthsNamesList>{renderMonthsList()}</CalendarMonthsNamesList>
          </CalendarMonthsNamesBlock>
        )}

        <CalendarMonthsBlock>
          <CalendarPrevButton
            onClick={prevMonth}
            disabled={moment(showedMonth).isSame(currentDay.clone().startOf("month"))}
          >
            <BiSolidChevronLeft />
          </CalendarPrevButton>

          <CalendarNextButton
            onClick={nextMonth}
            disabled={
              activeMonthsCount === 1
                ? moment(showedMonth).isSame(lastActiveDay.startOf("month"))
                : moment(showedMonth).isSameOrAfter(lastActiveDay.clone().add(-1, "month").startOf("month"))
            }
          >
            <BiSolidChevronRight />
          </CalendarNextButton>

          <CalendarMonths className={animate}>{renderMonths()}</CalendarMonths>
        </CalendarMonthsBlock>
      </CalendarBody>
    </CalendarDropdownComponent>
  );
};

export default CalendarComponent;
