import classNames from "classnames";
import i18next from "i18next";
import { BsChevronDown } from "react-icons/bs";

import { useToggle } from "../../../hooks";

import FilterBlockComponent from "../FilterBlockComponent";

import { FiltersBlock, FiltersButton, IconBlock, Wrapper } from "./style";

const FiltersComponent = () => {
  const { t } = i18next;
  const { isTrue, toggle } = useToggle();

  return (
    <Wrapper>
      <FiltersButton onClick={toggle}>
        {t("filters")}
        <IconBlock className={classNames({ active: isTrue })}>
          <BsChevronDown />
        </IconBlock>
      </FiltersButton>

      <FiltersBlock className={classNames({ active: isTrue })}>
        <FilterBlockComponent />
      </FiltersBlock>
    </Wrapper>
  );
};

export default FiltersComponent;
