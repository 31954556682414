import { useDispatch, useSelector } from "react-redux";

import FilterGroupComponent from "../FilterGroupComponent";
import { FiltersRangeSliderComponent } from "../../ui";
import { setFilterParams } from "../../../store/resultFiltersSlice";
import { RouteBlock, RouteCities } from "./style";
import i18next from "i18next";

const FilterDepartureTimeComponent = () => {
  const { language, t } = i18next;
  const { included } = useSelector(state => state.resultData);
  const {
    filterParams: {
      departureTime: { values },
    },
    filtersData,
  } = useSelector(state => state.resultFilters);
  const { routesData } = filtersData;

  const dispatch = useDispatch();

  const handleChange = (routeIndex, newValues) => {
    let params = [...values];

    if (newValues.min !== 0 || newValues.max !== 86100) {
      const index = params.findIndex(param => param.routeIndex === routeIndex);

      if (index > -1) {
        params = params.map((param, ind) => (ind === index ? { ...param, values: newValues } : param));
      } else {
        params.push({ routeIndex, values: newValues });
      }
    } else {
      params = params.filter(param => param.routeIndex !== routeIndex);
    }

    dispatch(
      setFilterParams({
        type: "departureTime",
        values: {
          isActive: params.length > 0,
          values: params,
        },
      })
    );
  };

  const normalizeTime = (time, startOfDay) => {
    return startOfDay.clone().add(time, "seconds").format("HH:mm");
  };

  const renderRoute = route => {
    const rangeValues = values.find(value => value.routeIndex === route.index)?.values || { min: 0, max: 86100 };
    const { departureTime } = route;

    return (
      <RouteBlock key={`${route.routeIndex}-${route.departureCityCode}-${route.arrivalCityCode}`}>
        <RouteCities>
          {included.city[route.departureCityCode].name[language]} →{" "}
          {included.city[route.arrivalCityCode].name[language]}
        </RouteCities>

        <FiltersRangeSliderComponent
          formatLabel={number => normalizeTime(number, departureTime.defaultRange[0].startOf("day"))}
          value={rangeValues}
          min={0}
          max={86100}
          step={300}
          onChange={values => handleChange(route.index, values)}
        />
      </RouteBlock>
    );
  };

  return (
    <FilterGroupComponent title={t("departure_time")}>{routesData.map(route => renderRoute(route))}</FilterGroupComponent>
  );
};

export default FilterDepartureTimeComponent;
