import i18next from "i18next";
import { BsChevronRight } from "react-icons/bs";

import { ReactComponent as AdultIcon } from "../../../assets/icons/adult.svg";
import { ReactComponent as ChildIcon } from "../../../assets/icons/child.svg";
import { ReactComponent as InfantIcon } from "../../../assets/icons/infant.svg";
import { ReactComponent as InfantWithPlaceIcon } from "../../../assets/icons/infantWithPlace.svg";

import {
  BodyBlock,
  HeaderBlock,
  HeaderIcon,
  HeaderIconBlock,
  HeaderPassengerBirthday,
  HeaderPassengerName,
  HeaderPassengerType,
  HeaderToggleButton,
  InfoItemBlock,
  InfoItemTitle,
  InfoItemValue,
  Wrapper,
} from "./style";
import { useToggle } from "../../../hooks";
import moment from "moment";

const OrderPassengerItemComponent = ({ data }) => {
  const { language, t } = i18next;
  const { isTrue, toggle } = useToggle(true);

  const passengersItems = {
    adt: t("adt_main_label"),
    chd: t("chd_main_label"),
    ins: `${t("inf_main_label")} (${t("inf_with_seat_second_label")})`,
    inf: t("inf_main_label"),
  };

  const allDocuments = {
    PS: t("russian_passport"),
    SR: t("birth_certificate"),
    PSP: t("international_passport"),
    NP: t("foreign_document"),
  };

  return (
    <Wrapper>
      <HeaderBlock onClick={toggle}>
        <HeaderIconBlock>
          <HeaderIcon $isChild={data.type === "chd"}>
            <div>
              {data.type === "adt" && <AdultIcon />}
              {data.type === "chd" && <ChildIcon />}
              {data.type === "inf" && <InfantIcon />}
              {data.type === "ins" && <InfantWithPlaceIcon />}
            </div>
          </HeaderIcon>
        </HeaderIconBlock>
        <HeaderPassengerType>{passengersItems[data.type]}</HeaderPassengerType>
        <HeaderToggleButton $isActive={isTrue}>
          <BsChevronRight />
        </HeaderToggleButton>
        <HeaderPassengerName>
          {data.surname} {data.name} {data.middle_name}
        </HeaderPassengerName>
        <HeaderPassengerBirthday>{moment(data.date_of_birth).format("DD.MM.YYYY")}</HeaderPassengerBirthday>
      </HeaderBlock>
      {isTrue && (
        <BodyBlock>
          <InfoItemBlock $isCitizenship>
            <InfoItemTitle>{t("citizenship")}</InfoItemTitle>
            <InfoItemValue>{data.citizenship_country[language]}</InfoItemValue>
          </InfoItemBlock>

          <InfoItemBlock>
            <InfoItemTitle>{t("gender")}</InfoItemTitle>
            <InfoItemValue>{t(data.gender)}</InfoItemValue>
          </InfoItemBlock>

          <InfoItemBlock>
            <InfoItemTitle>{t("document_type")}</InfoItemTitle>
            <InfoItemValue>{allDocuments[data.document_type]}</InfoItemValue>
          </InfoItemBlock>

          <InfoItemBlock>
            <InfoItemTitle>{t("document_number")}</InfoItemTitle>
            <InfoItemValue>{data.document_number}</InfoItemValue>
          </InfoItemBlock>

          <InfoItemBlock>
            <InfoItemTitle>{t("expiration_date")}</InfoItemTitle>
            <InfoItemValue>{moment(data.expiration_date).format("DD.MM.YYYY")}</InfoItemValue>
          </InfoItemBlock>

          <InfoItemBlock>
            <InfoItemTitle>{t("phone")}</InfoItemTitle>
            <InfoItemValue>{data.phone}</InfoItemValue>
          </InfoItemBlock>

          <InfoItemBlock>
            <InfoItemTitle>{t("email")}</InfoItemTitle>
            <InfoItemValue>{data.email}</InfoItemValue>
          </InfoItemBlock>
        </BodyBlock>
      )}
    </Wrapper>
  );
};

export default OrderPassengerItemComponent;
