import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { PublicOffer } from "../../components/info/PublicOfferComponent";
import { setShowSearch } from "../../store/appConfigsSlice";

const PublicOfferPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setShowSearch(false));
	}, []);
	
	return (
		<PublicOffer />
	);
};

export default PublicOfferPage;