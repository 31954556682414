import PropTypes from "prop-types";
import i18next from "i18next";
import { TbPlaneTilt } from "react-icons/tb";

import { DropdownComponent } from "../../ui";

import { AirportItem, Wrapper } from "./style";

const SearchAirportDropdownComponent = ({
  show,
  onClickOutside,
  airports,
  onClickAirport,
}) => {
  const { language } = i18next;

  return (
    <DropdownComponent show={show} onClickOutside={onClickOutside} noPadding>
      <Wrapper>
        {airports.map((airport, index) => (
          <AirportItem
            key={index}
            isMain={airport.isMain}
            onClick={() => onClickAirport(airport)}
          >
            <div>
              {!airport.isMain && <TbPlaneTilt />}
              {airport.name[language]}, {airport.countryName[language]}
            </div>
            <span>{airport.code}</span>
          </AirportItem>
        ))}
      </Wrapper>
    </DropdownComponent>
  );
};

export default SearchAirportDropdownComponent;

SearchAirportDropdownComponent.defaultProps = {
  show: false,
  onClickOutside: () => {},
  airports: [],
  onClickAirport: () => {},
};

SearchAirportDropdownComponent.propTypes = {
  show: PropTypes.bool,
  onClickOutside: PropTypes.func,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      countryCode: PropTypes.string,
      countryName: PropTypes.shape({}),
      name: PropTypes.shape({}),
      isMain: PropTypes.bool,
    })
  ),
  onClickAirport: PropTypes.func,
};
