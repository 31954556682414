import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.paddings(30, 0),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 30,

  [theme.down("md")]: {
    flexDirection: "column",
    gap: 20,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const LinksBlock = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 20% 1fr",
  flexGrow: 2,


  [theme.upDown("xs", "sm")]: {
    flexGrow: 1,
    gridTemplateColumns: "1fr",
    textAlign: "center",
  },

	[theme.down("md")]: {
		gap: 20,
	},

  [theme.down("xs")]: {
    flexGrow: 1,
    gridTemplateColumns: "1fr",
    textAlign: "center",
  }
}));

export const LegalInformation = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& > h2": {
    fontSize: 20,
    marginBottom: 10,
  }
}));

export const Deals = styled("div")(({theme}) => ({
	display: "flex",
  flexDirection: "column",

  "& > h2": {
    fontSize: 20,
    marginBottom: 10,
  }
}));

export const About = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& > h2": {
    fontSize: 20,
    marginBottom: 10,
  }
}));

export const LinkItem = styled((props) => (
  <div>
    <Link {...props} />
  </div>
))(({ theme }) => ({
  fontSize: 15,
  lineHeight: "30px",
  transition: "color .3s ease-in-out",

  "&: hover": {
    color: "#E7E7E7"
  }
}));

export const SocialsLink = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: 10,
	gap: 15,

  "& > firstChild": {
    marginLeft: "-5px",
  },

  [theme.down("sm")]: {
    justifyContent: "center",
  },
  
}));

export const Socials = styled("img")(({theme}) => ({
  width: "40px",
  height: "40px",
}));

export const LogosBlock = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 10,
  flexGrow: 2,
  
  [theme.down("sm")]: {
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "center",
  },

  "& > div": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}));

export const PaymentsLogo = styled("img")(({ theme }) => ({
  width: 144,
  height: 49,
}));

export const IataLogo = styled("img")(({ theme }) => ({
  marginTop: -8,
  width: 85,
  height: 28,
}));

export const TkpLogo = styled("img")(({ theme }) => ({
  width: 71,
  height: 39,
}));

export const CopyRight = styled("p")(({ theme }) => ({
  fontSize: 14,
  lineHeight: "17px",
  textAlign: "center",
}));