import styled from "styled-components";

import { FLIGHT_TYPE_CF } from "../../../constants";

export const Wrapper = styled("div")(({ theme, $isCompact }) => ({
  ...theme.paddings(0, 15),
  ...($isCompact
    ? {
        ...theme.paddings(20, 0),
      }
    : {
        position: "absolute",
        bottom: 100,
        left: 0,
        zIndex: 20,
      }),
  width: "100%",

  [theme.down("sm")]: {
    ...theme.paddings($isCompact ? 20 : 0, 15),
    position: "relative",
    bottom: 0,
  },
}));

export const FormBlock = styled("div")(({ theme, flightType }) => ({
  ...(flightType !== FLIGHT_TYPE_CF ? {} : { flexWrap: "wrap" }),
  display: "flex",
  gap: flightType !== FLIGHT_TYPE_CF ? 1 : 10,

  [theme.down("md")]: {
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: 10,
  },

  [theme.down("sm")]: {
    ...(flightType !== FLIGHT_TYPE_CF ? {} : { rowGap: 20 }),
  },
}));

export const SwapAirportButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  width: 24,
  height: 24,
  border: "1px solid #dfdfdf",
  position: "absolute",
  left: "calc(50% - 12px)",
  top: "calc(50% - 12px)",
  zIndex: 10,
  borderRadius: "50%",
  fontSize: 16,
  color: "#333",
  backgroundColor: theme.palette.white,
  lineHeight: 1,
  cursor: "pointer",
  transform: "rotate(90deg)",
  boxShadow: "0 0 5px 0 rgba(0,0,0,.5)",

  [theme.down("sm")]: {
    transform: "none",
  },
}));

export const RouteBlock = styled("div")(({ theme, gap, smGap }) => ({
  display: "flex",
  gap: gap || 1,
  position: "relative",

  [theme.down("sm")]: {
    flexDirection: "column",
    width: "100%",
    gap: smGap || 10,
  },
}));

export const RemoveRouteButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  width: 22,
  height: 22,
  position: "absolute",
  right: -7,
  top: -7,
  zIndex: 10,
  borderRadius: "50%",
  border: "none",
  fontSize: 16,
  color: theme.palette.white,
  backgroundColor: theme.palette.removeRouteButtonBg,
  lineHeight: 1,
  cursor: "pointer",
}));

export const SearchFormBlock = styled("div")(({ theme }) => ({
  padding: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.searchFormBg,
}));

export const SelectAirportsFromToBlock = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 1,
  position: "relative",

  [theme.down("sm")]: {
    flexDirection: "column",
    gap: 10,
    width: "100%",
  },
}));

export const AddRouteButton = styled("button")(({ theme, disabled }) => ({
  ...(disabled ? { cursor: "not-allowed", opacity: 0.5 } : { cursor: "pointer" }),
  backgroundColor: "transparent",
  border: "none",
  boxShadow: "2px 2px 5px 0 rgba(0,0,0,.5)",
  width: 1182,
  height: 60,
  color: theme.palette.white,
  fontSize: 22,
  borderRadius: 4,

  [theme.down("xl")]: {
    width: 922,
  },

  [theme.down("lg")]: {
    width: 762,
  },

  [theme.down("md")]: {
    width: 500,
  },

  [theme.down("sm")]: {
    width: "100%",
  },
}));

export const SearchButtonBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  paddingTop: 20,
}));

export const SearchButton = styled("button")(({ theme }) => ({
  ...theme.paddings(0, 15),
  border: "none",
  backgroundColor: theme.palette.searchButtonBg,
  cursor: "pointer",
  height: 46,
  fontSize: 20,
  color: theme.palette.white,
  borderRadius: 4,

  "&:hover": {
    filter: "brightness(90%)",
  },
}));
