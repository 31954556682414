import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(15),
  gap: 15,
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
}));

export const BlockTitle = styled("h3")(({ theme }) => ({
  color: "#787878",
  borderBottom: "1px solid #ababab87",
  fontWeight: 400,
  paddingBottom: 10,
}));

export const PaymentMethodsBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 10,
  // display: "grid",
  // gridTemplateColumns: "repeat(4, 1fr)",
  // gap: 5,

  // [theme.down("xl")]: {
  //   gridTemplateColumns: "repeat(3, 1fr)",
  // },

  // [theme.down("sm")]: {
  //   gridTemplateColumns: "repeat(2, 1fr)",
  // },

  // [theme.down("xs")]: {
  //   gridTemplateColumns: "1fr",
  // },
}));

export const PaymentMethodItem = styled("div")(({ theme, $isActive }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(10),
  gap: 5,
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  border: `1px solid ${$isActive ? "#0b4da2" : "#ccc"}`,
  cursor: "pointer",

  "& .balance-icon": {
    display: "flex",
    fontSize: 32,
  },

  "& .balance-not-enough": {
    ...theme.mixins.flexCenterCenter,
    fontSize: 12,
    color: "red",
  }
}));

export const PaymentMethodCheckboxBlock = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 5,
  top: 5,
}));

export const PaymentMethodLogo = styled("img")(({ theme }) => ({
  maxWidth: 160,
  maxHeight: 100,

  [theme.down("xs")]: {
    maxWidth: 120,
  },
}));

export const PaymentMethodDescription = styled("div")(({ theme, $isActive }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: $isActive ? "#151515" : "#9e9e9e",
}));

export const TimelimitAndPaymentButtonBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  flexDirection: "column",
  gap: 10,
  fontSize: 14,
  color: "#151515",
}));

export const PaymentButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  ...theme.paddings(0, 15),
  position: "relative",
  fontSize: 20,
  border: "none",
  borderRadius: 0,
  backgroundColor: "#ff7900",
  color: "#fff",
  height: 46,
  width: "100%",
  cursor: "pointer",
}));

export const PaymentButtonLoadingCover = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "#ff7900",
}));
