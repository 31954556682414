import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";

import { setFilterParams } from "../../../store/resultFiltersSlice";

import FilterGroupComponent from "../FilterGroupComponent";
import { CheckboxComponent } from "../../ui";

import { CheckboxItem, CheckboxesBlock } from "./style";

const FilterTransfersComponent = () => {
  const { t } = i18next;
  const {
    filterParams: {
      transfers: { values },
    },
  } = useSelector(state => state.resultFilters);
  const dispatch = useDispatch();

  const handleChangeAll = value => {
    if (value) {
      dispatch(
        setFilterParams({
          type: "transfers",
          values: {
            isActive: false,
            values: { withoutTransfers: false, oneTransfer: false, manyTransfers: false },
          },
        })
      );
    }
  };

  const handleChange = (key, value) => {
    const filters = {
      ...values,
      [key]: value,
    };

    dispatch(
      setFilterParams({
        type: "transfers",
        values: {
          isActive: !filters.withoutTransfers || !filters.oneTransfer || !filters.manyTransfers,
          values: filters,
        },
      })
    );
  };

  return (
    <FilterGroupComponent isOpenable={false}>
      <CheckboxesBlock>
        <CheckboxItem>
          <CheckboxComponent
            label={t("all_flights")}
            value={!values.withoutTransfers && !values.oneTransfer && !values.manyTransfers}
            onChange={e => handleChangeAll(e.target.checked)}
          />
        </CheckboxItem>

        <CheckboxItem>
          <CheckboxComponent
            label={t("without_transfers")}
            value={values.withoutTransfers}
            onChange={e => handleChange("withoutTransfers", e.target.checked)}
          />
        </CheckboxItem>

        <CheckboxItem>
          <CheckboxComponent
            label={t("one_transfer")}
            value={values.oneTransfer}
            onChange={e => handleChange("oneTransfer", e.target.checked)}
          />
        </CheckboxItem>

        <CheckboxItem>
          <CheckboxComponent
            label={t("many_transfer")}
            value={values.manyTransfers}
            onChange={e => handleChange("manyTransfers", e.target.checked)}
          />
        </CheckboxItem>
      </CheckboxesBlock>
    </FilterGroupComponent>
  );
};

export default FilterTransfersComponent;
