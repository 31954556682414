import _ from "lodash";
import { webTransferTimeFormat } from "../../config";
import { BALANCE_PAYMENT_APPROVED_CODE, BALANCE_PAYMENT_REQUEST_CODE, ORDER_FAIL_CODE } from "../../constants";
import { formattedDate, formattedDateWithTimeZone } from "../../utils";
import { baseParams } from "../common";
import { getChangeBaggage } from "./prebook";
import moment from "moment";
import { ContentType, request } from "../api";

const orderResultFormat = order => {
  let currentOrder = { ...order };
  currentOrder["timestamp"] = formattedDateWithTimeZone(currentOrder["timestamp"]);

  if (_.isNull(order["booking_id"])) {
    currentOrder["brokenOrder"] = true;
    currentOrder["order_status"] = ORDER_FAIL_CODE;
  } else {
    if (currentOrder["timelimit"]) {
      const timelimitWithoutTz = currentOrder["timelimit"].split("+");
      const convertedUTCTime = moment.utc(timelimitWithoutTz[0]).tz(moment.tz.guess());
      const formatedTimelimitTime = formattedDateWithTimeZone(convertedUTCTime);
      const dateNow = moment();
      const momentTimelimit = moment(formatedTimelimitTime, webTransferTimeFormat).endOf("seconds");
      currentOrder["timelimit"] = momentTimelimit.diff(dateNow, "seconds");
    } else {
      currentOrder["timelimit"] = -1;
    }

    currentOrder["status_at"] = formattedDateWithTimeZone(currentOrder["status_at"]);

    if (currentOrder["order_status"] === BALANCE_PAYMENT_REQUEST_CODE) {
      currentOrder["booking_status"] = BALANCE_PAYMENT_REQUEST_CODE;
    }

    if (currentOrder["order_status"] === BALANCE_PAYMENT_APPROVED_CODE) {
      currentOrder["booking_status"] = BALANCE_PAYMENT_APPROVED_CODE;
    }

    if (_.isNull(currentOrder["airline_booking_number"])) {
      currentOrder["airline_booking_number"] = [];
    }

    currentOrder["routes"].forEach(route => {
      route["segments"].forEach(segment => {
        segment["departure"]["time"] = formattedDate(segment["departure"]["time"]);
        segment["arrival"]["time"] = formattedDate(segment["arrival"]["time"]);

        let techStops = [];

        _.map(segment["tech_stops"], function (techStop) {
          const formatTime = "YYYY-MMM-DD HH:mm:ss";
          let cityCode = order.included.airport[techStop.airport_code].city;
          let countryCode = order.included.airport[techStop.airport_code].country;
          let momentObj = moment(techStop.arrival_at, formatTime);
          const prevDateUnix = momentObj.unix();
          const actualDateUnix = moment(techStop.departure_at, formatTime).unix();
          const unixDifference = actualDateUnix - prevDateUnix;
          const hours = Math.trunc(unixDifference / 3600);
          const minutes = Math.floor((unixDifference - hours * 3600) / 60);

          techStops = [
            ...techStops,
            {
              city: {
                code: cityCode,
              },
              country: {
                code: countryCode,
              },
              duration: {
                hour: hours,
                minute: minutes,
              },
              arrival: {
                time: momentObj.format("HH:mm"),
              },
            },
          ];
        });
        segment["tech_stops"] = techStops;
      });
    });
  }

  currentOrder = getChangeBaggage(currentOrder);

  return currentOrder;
};

export const getOrder = async (data = {}, params = {}) => {
  const response = await request({
    path: "/order",
    method: "POST",
    body: { ...baseParams, ...data },
    type: ContentType.Json,
    format: "json",
    secure: true,
    ...params,
  });

  return orderResultFormat(response?.data);
};
