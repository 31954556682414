const tj = {
  agreement_text:
    "Бо ворид шудан ба сайт, шумо бо сиёсати махфият ва қоидаҳои ҷамъоварӣ ва коркарди маълумоти шахсӣ розӣ мешавед",
  login_title_text: "Воридшави ба сомона",
  login_text: "Логин",
  password_text: "Рамз",
  forgot_password_text: "Рамзро фаромӯш кардед?",
  registration_text: "Сабти ном",
  enter_text: "Ворид шудан",
  invalid_password: "Ҳадди аққал 6 аломат",
  invalid_email: "Почтаи электронӣ беэътибор",
  required: "Соҳаи ҳатмӣ",
  travel_time: "Вақти сафар",
  flight: "Парвоз",
  terminal: "Терминал",
  baggage: "Бағоҷ",
  hand_luggage: "Интиқоли бағоҷ",
  refund_ticket: "Баргардонидани чипта",
  change: "Мубодилаи билетҳо",
  no_refund: "Бозгашт надорад",
  yes_refund: "Бозгашт дорад",
  no_change: "Мубодила надорад",
  yes_change: "Мубодила дорад",
  yes_baggage: "Бағоҷ дорад",
  no_baggage: "Бағоҷ надорад",
  yes_hand_luggage: "Бағоҷи дастӣ дорад",
  no_hand_luggage: "Бағоҷи дастӣ надорад",
  show_rules: "нишон додани қоидаҳо",
  total: "Ҳамагӣ",
  passengers: "Мусофирон",
  select_from_list: "Аз рӯйхат интихоб кунед",
  name_as_in_document: "Ном чун дар ҳуҷҷат",
  middlename_as_in_document: "Номи падар чун дар ҳуҷҷат",
  surname_as_in_document: "Насаб чун дар ҳуҷҷат",
  date_of_birth: "Санаи таваллуд",
  gender: "Ҷинс",
  citizenship: "Шаҳрвандӣ",
  age: "Синну сол",
  document: "Ҳуҷҷат",
  payer: "Харидор",
  for_payer_mess:
    "Мо маълумоти фармоишро ба почта мефиристем. Телефон барои паёмҳои таъҷилӣ дар сурати тағир додани ҷадвал зарур аст",
  series_and_number: "Силсила ва рақам",
  valid_until: "Анҷоми эътибор",
  airline_bonus_card: "Корти бонуси ҳавопаймоӣ",
  number_bonus_card: "Рақами корти бонусӣ",
  phone_number: "Рақами телефон",
  email: "Email",
  how_to_read_rules: "Қоидаҳоро чӣ тавр бояд хонд",
  tariff_rules: "Қоидаҳои тарофавӣ",
  book_and_proceed_to_payment: "Банд кунед ва ба пардохт кардан гузаред",
  agreement_book_text: ' Бо клик кардани "Банд кунед ва ба пардохт кардан гузаред" шумо розӣ мешавед бо',
  agreement_book_link: "Шартҳои тарифии ширкати ҳавопаймоӣ",
  everywhere_local_time: "Дар ҳама ҷоҳо вақти парвоз ва омадани маҳаллӣ нишон дода шудааст",
  duration: "давомнокии",
  in: "дар",
  no_data_contact_technical_support: "Маълумот нест. Бо дастгирии техникӣ тамос гиред.",
  back_to_orders: "Бозгашт ба фармоишҳо",
  incorrect_date_from_adt: "Санаи нодуруст барои калонсолон",
  incorrect_date_from_chd: "Санаи нодуруст барои кӯдак",
  incorrect_date_from_inf: "Санаи нодуруст барои кӯдак",
  incorrect_date: "Санаи нодуруст",
  check_date_and_change_doc: "Санаи таваллудро санҷед ё навъи тағироти ҳуҷҷатро ",
  warning_inf_date: "Ширкати ҳавопаймоӣ метавонад шаҳодатномаи қабули кӯдаки навзод ба парвоз талаб кунад ",
  ps_should_be_10_digits: "Шиносномаи миллии Русия бояд 10 рақам дошта бошад",
  psp_should_be_9_digits: "Шиносномаи хориҷии Русия бояд 9 рақам дошта бошад",
  incorrect_doc_number: "Рақами нодурусти ҳуҷҷат",
  hint_for_ps_doc: "Силсила ва рақами шиносномаро ворид кунед, масалан 1234567890 ",
  hint_for_psp_doc: "Рақами шиносномаро ворид кунед, масалан 123456789",
  hint_for_sr_doc: "Рақами шаҳодатномаи таваллудатонро ворид кунед, мисол IXЯЯ123456 ",
  hint_for_np_doc: "Силсила ва рақами ҳуҷҷатро ворид кунед",
  male: "Мард",
  female: "Зан",
  russian_passport: "Шиносномаи миллии Русия",
  birth_certificate: "Шаҳодатномаи таваллуди Русия",
  international_passport: "Шиносномаи хориҷии Русия",
  foreign_document: "Ҳуҷҷати хориҷӣ",
  place_one: "ҷой",
  place_more: "ҷой",
  place_many: "ҷой",
  customer_support: "Дастгирии муштариён",
  financial_matters: "Масъалаҳои молиявӣ",
  agreement_footer_text: "Истифодаи мавод бе розигии хаттӣ манъ аст ",
  flight_schedule: "Ҷадвали парвоз",
  offer: "Пешниҳоди оммавӣ",
  question_answer: "Саволу ҷавоб",
  jobs: "Ҷойи кор",
  facebook: "Facebook",
  about_us: "Дар бораи мо",
  currency: "Асъор",
  language: "Забон",
  search: "Ҷустуҷӯ",
  personal_info: "Маълумоти шахсӣ",
  personal_area: "Ҳуҷраи инфироди",
  not_found_page: "Саҳифае, ки шумо кушодан мехоҳед, ёфт нашуд",
  to_main: "Ба аввал",
  log_out: "Баромад",
  session: "Сессия",
  error_500_text:
    "Чунин ба назар мерасад, ки чизе хато шудааст, аммо мо аллакай хабардор ҳастем дар бораи мушкилот ва барои ислоҳи он кор карда истодаем. Барои нороҳат карданатон бахшиш мехоҳем. ",
  lang_ru: "Русӣ",
  lang_tj: "Тоҷикӣ",
  lang_en: "Англисӣ",
  balance: "Тавозун",
  otb: "Тавозун",
  own_funds: "Маблағҳои худӣ",
  credit_line: "Хатти кредитӣ",
  no: "Не",
  for_route: "Барои маршрут",
  to_result_search: "Ба натиҷаҳои ҷустуҷӯ",
  only_a_z_space: "Танҳо рамзҳои лотинӣ ва фазо",
  only_a_z: "Танҳо аломатҳои лотинӣ",
  invalid_phone: "Рақами телефон нодуруст",
  min_2_symbol: "Ҳадди ақал 2 аломат",
  select_value_from_list: "Аз рӯйхат арзиши интихоб кунед",
  check_date: "Санаро санҷед",
  incorrect_number: "Рақами корт нодуруст",
  incorrect_cvc: "Рамзи нодуруст",
  incorrect_card_holder: "Номи дорандаи номувофиқ",
  incorrect_card_month: "Моҳи нодуруст",
  incorrect_card_year: "Соли хато",
  code: "Код",
  message: "Паём",
  close: "Пӯшидан",
  error_message_part_1:
    "Чунин ба назар мерасад, ки чизе хато шуда аст. То он ки мо мушкилотро дарк карда тавонем, лутфан бо дастгририи техники дар тамос шавед.",
  error_message_part_2: "ва рақами сессияро ворид кунед",
  error_message_part_3: "Пас аз пӯшидани паём, шумо ба саҳифаи асосии хидмат равон карда мешавед ",
  choose: "Интихоб кунед",
  collapse: "Ҷамъ кардан",
  looking_tickets: "Мо чипта меҷӯем",
  wait_please: "Лутфан мунтазир шавед",
  we_issue_tickets: "Мо чипта медиҳем, он вақти зиёдро намегирад",
  created: "Илова шуд",
  left: "Боқи монд",
  less_than_minute: "аз як дақиқа камтар",
  help: "Кӯмак",
  exchange_and_return_rules: "Мубодила ва бозгашт: қоидаҳо",
  after_flight: "Баъди парвоз",
  go_to_section: "Ба боб равед",
  next: "Давом",
  earlier: "Ба пеш",
  not_book_tickets: "Шумо парвозҳои бандкарда надоред",
  route_from: "Аз куҷо",
  route_to: "Ба куҷо",
  date_from: "Он ҷо",
  date_to: "Бозгашт",
  find: "Барои ёфтан",
  add_route: "Илова кардани парвоз",
  one_way: "Ба як тараф",
  two_way: "Он ҷо ва бозгашт",
  multi_way: "Роҳи мушкил",
  economy: "Эконом",
  business: "Бизнес",
  first: "Якум",
  all_class: "Ягон синфи ҳатмӣ нест",
  adt_main_label: "Калонсолон",
  inf_main_label: "Кӯдак",
  chd_main_label: "Кӯдак",
  adt_second_label: "12 сола ва калон аз он",
  chd_second_label: "2-12 сола",
  inf_second_label: "то 2 сол (бе ҷой)",
  inf_with_seat_second_label: "бо ҷой",
  passenger_1: "Як мусофир",
  passenger_2: "Ду мусофир",
  passenger_3: "Се мусофир",
  passenger_4: "Чор мусофир",
  passenger_5: "Панҷ мусофир",
  passenger_6: "Шаш мусофир",
  agents: "Барои агентҳо",
  passenger_7: "Ҳафт мусофир",
  passenger_8: "Ҳашт мусофир",
  passenger_9: "Нӯҳ мусофир",
  only_there: "Ба як тараф",
  roundtrip: "Он ҷо ва бозгашт",
  your_profile: "Профили шумо",
  client_name: "Номи мизоҷ",
  client_code: "Рамзи фармоишгар",
  partner_code: "Рамзи шарик",
  partner_name: "Номи шарик",
  partner_currency: "Асъори шарик",
  current_balance: "Тавозуни ҳозира",
  debt: "Қарз",
  credit_limit: "Меъёри кредит",
  deposit: "Амонат",
  change_password: "Ивази рамз",
  old_password: "Рамзи ҷорӣро ворид кунед",
  new_password: "Рамзи нав",
  confirmation_password: "Рамзро тасдиқ кунед",
  save: "Сабт кардан",
  passwords_must_match: "Рамзҳо бояд мувофиқат кунанд",
  password_changed_successfully: "Рамз бомуваффақият иваз карда шуд",
  departure_title: "Парвоз",
  there_title: "Он ҷо",
  back_title: "Бозгашт",
  validates_flights: "парвозҳоро тасдиқ мекунад",
  pay_for_your_reservation: "Пардохт барои фармоиши худ",
  hour_short: "с",
  day_short: "рӯзҳо",
  minutes_short: "д",
  minutes_short_3: "дақ",
  on_way: "Дар роҳ",
  no_transfers: "Бе интиқол",
  class: "Синф",
  tariff_options: "Роҳҳои тарофавӣ",
  with_luggage: "Бо бағоҷ",
  without_luggage: "Бе бағоҷ",
  no_timelimit: "Маълумот оиди вақт барои пардохт нест",
  provider: "Таъминкунанда",
  price: "Нарх",
  detail: "Тафсилоти бештар",
  label_price_by_min: "Нарх (афзоиш меёбад)",
  label_price_by_max: "Нарх (коҳиш меёбад)",
  transfers: "Интиқоли",
  all_flights: "Ҳама парвозҳо",
  without_transfers: "Бе интиқол",
  one_transfer: "1 интиқол",
  many_transfer: "2 интиқоли ва бештар",
  not_found_recomm_first_text: "Мо аз рӯи дархости шумо чиптаҳои мувофиқро ёфта натавонистем  ",
  not_found_recomm_second_text: "Лутфан, бори дигар бо санаҳо ё фурудгоҳҳои дигар кӯшиш кунед ",
  not_found_recomm_by_filters: "Лутфан, шароити филтрҳоро тағир диҳед.",
  flight_details: "Тафсилоти парвоз",
  change_tariff: "Тағир додани тарофа",
  tariffs: "Тарофаҳо",
  teh_stop: "Истгоҳи техникӣ",
  transfer: "Интиқол",
  local_time_message: "Вақтҳои парвоз кардан ва бозгашт маҳаллӣ аст",
  route_tariffs_message: "Тарофа барои тамоми масир эътибор дорад.",
  airlines: "Ширкатҳои ҳавопаймоӣ",
  airports: "Фурудгоҳҳо",
  departure: "Баромад",
  arrival: "Расидан",
  departure_time: "Вақти парвоз",
  arrival_time: "Вақти расидан",
  flight_number: "Рақами парвоз",
  multiple_numbers_separated_by_spaces: "Рақамҳои парвоз бо фазои ҷудо",
  lowcost: "lowcost",
  transfer_1: "интиқол",
  transfer_2_4: "интиқоли",
  transfer_5: "интиқоли",
  free_seats_0: "ҷой нест",
  free_seats_1: "ҷой",
  free_seats_2_4: "ҷой",
  free_seats_5: "ҷой",
  one_adt: "Калонсолон",
  more_adt: "Калонсолон",
  many_adt: "Калонсолон",
  one_chd: "Кӯдак",
  more_chd: "Кӯдак",
  many_chd: "Кӯдакон",
  one_inf: "Кӯдак",
  more_inf: "Кӯдак",
  many_inf: "Кӯдакон",
  flight_one: "парвоз",
  flights_more: "парвоз",
  flights_many: "парвозҳо",
  found_one: "Ёфт",
  found_more: "Ёфт",
  flight_overview: "Шарҳи парвоз",
  to_top: "Ба боло",
  book: "Фармоиши",
  buyer: "Харидор",
  pnr: "Рамзи бандкунӣ / PNR",
  online_registration_locator: "Ҷойгиркунии онлайн",
  route_information: "Маълумот дар бораи масир",
  order: "Фармон",
  flights_tickets: "Парвозҳо",
  void_text: "Шумо фармоишро дар вақти дилхоҳ бекор карда метавонед.",
  void_order: "Фармоишро бекор кунед",
  support_chat: "Чат дастгирӣ",
  payment_method: "Усули пардохт",
  payment_with_balance: "Пардохт аз тавозун",
  payment_with_card: "Пардохт бо корт",
  info_account: "Ҳисоби маълумот",
  get_tickets: "Чиптаҳоро нависед",
  left_before_the_time_limit: "лимити вақт монд",
  expires: "Ба анҷом мерасад",
  payment_timed_out: "Муҳлати пардохт ба анҷом расид",
  time_expired: "Вақт ба анҷом расид",
  order_created: "Фармон таҳия карда шуд",
  return_ticket: "Баргардонидани чипта",
  exchange_ticket: "Табодули чипта",
  download_itinerary_receipt: "Борномаи чиптаро зеркашӣ кунед",
  order_cost: "Арзиши фармоиш",
  invoice_for_payment: "Ҳисобнома барои пардохт",
  void_in_process: "Фармоиш бекор карда шуд",
  initial: "Фармон таҳия карда шуд",
  in_progress: "Чиптаҳо брон карда шудаанд",
  success: "Пардохти бомуваффақият, чиптаҳои додашуда",
  partially_success: "Қисман муваффақ барои",
  booking_fail: "Хатогии бронкунӣ",
  order_fail: "Хатогӣ ҳангоми сохтани фармоиш",
  pay_fail: "Хатои пардохт",
  order_cancel_from_client: "Фармоишро мизоҷ бекор кардааст",
  order_cancel_from_admin: "Фармоишро маъмур рад кардааст",
  order_cancel_by_job: "Фармоиш бо маҳдудияти вақт бекор карда шуд",
  order_cancel_error: "Хато бекор кардани фармоиш",
  application_refund: "Дархости баргардонидани маблағ эҷод карда шуд",
  partial_refund: "Баргардонидани қисм аз фармоиш",
  order_cancel_by_gds: "Фармоиш бо маҳдудияти вақт бекор карда шуд",
  refund: "Баргардонидани пурраи фармоиш",
  ticketing_done: "Пардохти бомуваффақият, чиптаҳои додашуда",
  pay_success: "Пардохти бомуваффақият, чиптаҳо дар ҷараёни кассавӣ",
  booking_done: "Билетҳо брон карда шудаанд",
  exchange: "Мубодилаи билетҳо",
  void: "Фармоиш бекор карда шуд",
  ticketing_fail: "Хато додани чиптаҳо",
  year_one: "сол",
  year_more: "соли чорӣ",
  years: "солҳо",
  day_one: "рӯз",
  day_more: "имруз",
  days: "рӯзҳо",
  hour_one: "соат",
  hour_more: "соат",
  hours: "соат",
  minute_one: "як дақиқа",
  minute_more: "дақиқа",
  minutes: "дақиқа",
  second_one: "дуюм",
  second_more: "сонияҳо",
  seconds: "сонияҳо",
  passenger_list: "Рӯйхати мусофирон",
  your_name: "Номи шумо",
  search_order: "Ҷустуҷӯи фармоиш ",
  locator: "Ҷойгиркунанда",
  cancel: "Бекор кардан",
  passenger_name: "Номи мусофир",
  do_not_find_order_message: "Фармон ёфта нашуд. Лутфан, имконоти ҷустуҷӯии худро тағир диҳед",
  orders: "Фармонҳо",
  contract_settings: "Танзимоти шартнома",
  main: "Асосӣ",
  users: "Истифодабарандагон",
  affiliated_companies: "Ширкатҳои фаръӣ",
  all_orders_label: "Ҳама фармонҳо",
  book_done_label: "Брон карда шудааст",
  pay_done_label: "Пардохта шудааст",
  ticket_done_label: "Партофта шудааст",
  cancel_done_label: "Бекор карда шудааст",
  wait_refund_label: "Бозгаштро интизор",
  refund_done_label: "Бозгашт",
  partial_refund_done_label: "Қисман баргашт",
  void_done_label: "Бекор карда шудааст",
  error_order_label: "Хатогии фармоиш",
  date_create_from: "Санаи офариниш аз",
  order_status_label: "Мақоми фармоиш",
  search_title: "Насаб, макон ё шаҳрро ворид кунед",
  date_create_to: "Санаи офарида то",
  clear: "Тоза кардан",
  passenger_success_save: "Мусофир бомуваффақият сабт шуд",
  payment_with_dpt: "Пардохт аз тавозун пардохт",
  payment_with_unt: "Пардохт тавассути Uniteller",
  payment_with_spt: 'Пардохт таввасути "Корти Миллӣ"',
  include_your_fees: "дохилкунии пардохти шумо",
  before: "пеш",
  your_fees: "Пардохти шумо",
  month_one: "моҳ",
  months_more: "моҳ",
  months: "моҳҳо",
  all_passengers: "Ҳама мусофирон",
  passenger_success_remove: "Мусофир бомуваффақият нест карда шуд",
  companies: "Ширкатҳо",
  no_companies: "Ширкатҳо нестанд",
  no_matches_found: "Ҳеҷ мувофиқат пайдо нашуд",
  no_passengers: "Шумо то ҳол ягон мусофирро сабт накардаед",
  enter_passenger_name: "Ном ва насаби мусофирро ворид кунед",
  add_passenger: "Илова кардани мусофир",
  your_partner_fees: "Пардохти шумо",
  type_of_fee: "Намуди пардохт",
  value: "Арзиш",
  percent: "Фоиз",
  saved_successfully: "бомуваффақият сабт шуд",
  error_saving: "Хатогӣ ҳангоми сабт кардан",
  percent_from_total: "Фоизи ҳамагӣ",
  percent_from_fare: "Фоизи тарофа",
  fix_flat: "Пардохти собит",
  fix_flat_and_percent_from_total: "Пардохти собит ва фоизи ҳамагӣ",
  fix_flat_and_percent_from_fare: "Пардохти собит ва фоизи тарофа",
  reset: "Бекор кардан",
  invalid_value: "Маънои нодуруст",
  no_orders_found: "Ягон фармоиш ёфта нашуд",
  personal_data: "Маълумоти шахсӣ",
  upload: "Зеркашӣ кунед",
  your_logo: "Логотипи шумо",
  image_uploaded_successfully: "Тасвир бо муваффақият сабт карда шуд",
  upload_logo: "Логотипро зер кунед",
  image_too_large: "Тасвир хеле калон аст",
  image_resolution_too_high: "Ҳалномаи тасвир хеле баланд аст",
  image_resolution_too_small: "Ҳалли тасвир хеле хурд аст",
  rule_for_upload_img_1: "Формати тасвирӣ .jpg, .jpeg, .png, .gif",
  rule_for_upload_img_2: "Ҳалли он аз 300x100px то 2000x2000px.",
  rule_for_upload_img_3: "Андозаи файл на бештар аз 4 МБ.",
  amount: "Маблағ",
  rules_text_1: "Ба ибораҳои зерин диққат диҳед",
  rules_text_2: "чипта баргардонида намешавад",
  rules_text_3: "Чипта наметавонад баргардонида шавад, агар шумо ба парвоз ҳозир нашавед",
  rules_text_4: "Қоидаҳои мубодила дар бахши зерлоиҳа тавсиф карда шудаанд",
  line: "Хат",
  rules_text_5:
    "маънои онро дорад, ки тағирот қабул карда намешавад ва дар ин сурат тағир додани таърихи парвоз ғайриимкон аст",
  passenger_data: "Маълумот дар бораи мусофирон",
  comment: "Шарҳ",
  contact_details: "Маълумот барои тамос",
  remove: "Нест кардан",
  add_document: "Сохтани ҳуҷҷат",
  return_avia_rules: "Табодул кардан ва ё бозгардонид",
  booking_avia_rules: "Чӣ тавр чипта харидан мумкин аст",
  created_at: "Санаи таъсис",
  updated_at: "Санаи навсозӣ",
  phone: "Телефон",
  create_user: "Корбар созед",
  add: "Илова кунед",
  edit: "Таҳрир кунед",
  confirm: "Тасдиқ кунед",
  successfully_deleted: "Бомуваффақият нест карда шуд",
  error_deleting: "Хатогӣ ҳангоми нест кардани",
  mess_want_to_delete: "Шумо мутмаин ҳастед, ки мехоҳед тоза кунед",
  record_one: "сабт кунед",
  record_more: " сабтҳо",
  record_many: "сабтҳо",
  user_type: "Навъи муштариён",
  admin_role: "Админ",
  manager_role: "Мудир",
  user_agent_role: "Агент",
  user_role: "Корбар",
  security_guarantees_unitaller: "Кафолати амният",
  activation_title: "Фаъолсозии корбар",
  confirmation_activation_password: "Рамзро тасдиқ кунед",
  enter_activation_password: "Рамзро ворид кунед",
  activation: "Фаъолсозӣ",
  login: "Даромадан",
  error_activating_user: "Хатоги ҳангоми фаъолсозии корбар",
  error: "Хатогӣ",
  to_login_form: "Ба саҳифаи авторизатсия",
  expired_date: "Муҳлати эътибор ба анҷом расид",
  refund_button_title: "Бозгашт",
  itinerary_receipt: "Чиптаи маршрутӣ",
  action: "Амал",
  to_pay: "Барои пардохт",
  order_cancel_error_mess: "Хатогӣ ҳангоми бекор кардани фармоиш",
  order_cancel_successfully_mess: "Бекоркунии бомуваффақият",
  partner: "Шарик",
  inn: "РМА",
  kpp: "KPP",
  logo: "Логотип",
  create_partner: "Шарикро эҷод кунед",
  status: "Вазъият",
  add_your_details: "Тафсилоти худро илова кунед",
  return_request_created: "Дархости бозгашт эҷод карда шуд",
  error_creating_refund_request: " хатогӣ Ҳангоми эҷоди дархости баргардонидан",
  activation_status: "Ҳолати фаъолсозӣ",
  placeholder_date_picker: "рр.мм.сссс",
  created_company_successfully: "Ширкат сохта шуд",
  error_created: "Хатогӣ ҳангоми эҷод кард",
  deposit_statement: "Ҳисоботи пасандоз",
  transactions: "Амалиётҳо",
  weight_unit: "кг",
  credit_funds: "Маблағҳои қарзӣ",
  overrun: "Аз ҳад зиёд",
  transaction_time: "Вақти муомилот",
  before_balance_change: "Пеш аз тағир додани тавозун",
  allow_balance_payment: "Пардохт аз суратҳисоб",
  approved_balance_request_successfully: "Дархост барои чипта аз хисоби тавозун тасдиқ карда шуд",
  error_approved_balance_request_successfully: "Хатогӣ дар тасдиқи дархост аз ҳисоби тавозун",
  balance_payment_request: "Ариза барои таъини тавозун",
  balance_payment_approved: "Ариза барои тасдиқи тавозун тасдиқ карда шуд",
  payment_with_uzc: 'Бо "Uzcard" пардохт кардан',
  allow_payment: "Иҷозати пардохт",
  statement_request: "Дархост дархост",
  client: "Мизоҷ",
  was_changed_to: "иваз карда шуд",
  deposit_statement_request_created: "Барои ибрози амонат ариза таҳия карда шуд",
  registration: "Сохтани аккаунт",
  error_creating_deposit_statement_request: "Хатогӣ ҳангоми эҷоди ариза аз пасандоз",
  deposit_statement_request_confirmed: "Ариза барои сабти амонат тасдиқ карда шуд",
  error_confirming_deposit_statement_request: "Хатогӣ ҳангоми тасдиқкунандаи ариза аз пасандоз",
  fare: "Тарофа",
  fees: "Боҷҳо",
  partner_fees: "Боҷи шарикон",
  taxes: "Андозҳо",
  ticket: "Чипта",
  pay_for_the_order: "Пардохти фармоиш",
  operating_company: "Ширкати амалкунанда",
  download_charter_certificate: "Сертификати чартериро сабт кунед",
  certificate_issued: "Сертификати чартерӣ дода шуд",
  charter: "Чартер",
  service: "Хизматрасони",
  income: "Даромад",
  spending: "Хароҷот",
  remainder: "Боқимонда",
  description: "Тавсиф",
  contract: "Шартнома",
  column_selection: "Интихоби сутун",
  your_commission: "Комиссияи шумо",
  no_result: "Натиҷае нест",
  date: "Сана",
  movement_balance: "ҳаракати маблағҳо дар тавозун",
  current_balance_sheet: "Тавозуни ҷорӣ",
  indebtedness: "қарз",
  date_from_d: "Санаи аз",
  date_to_d: "Санаи то",
  order_num: "Рақами супориш",
  booking_num: "Рақами фармоиш",
  search_operations: "ҷустуҷӯи амалиётҳо",
  upload_report: "ҳисоботро бор кардан",
  payment_with_vsl: 'Пардохт тавассути "Корти Миллӣ"',
  popular_destinations: "Самтҳои машҳур ",
  more_options: "Имконоти бештар",
  hide_list: "Руйхатро пинҳон кардан",
  straight: "Рост",
  with_transfer: "Бо интиқолҳо",
  finances: "Молия",
  contacts: "Нишони",
  all_classes: "ҳама синфҳо",
  difficult_routes: "Pоҳи душвор сохтан",
  search_history: "Таърихи ҷустуҷӯ",
  go_to_page: " гузаштан",
  find_tickets: "Ҷустуҷӯи чиптаҳо",
  tours_discount: " турҳои мусоид ва таҳфифҳо",
  last_news: "Охирин хабарҳо",
  more_details: "Тафсилоти бештар",
  about_company: "Дар бораи ширкат",
  customer_rew: "Баррасиҳои муштариён",
  my_profile: "Профили ман",
  contact_us: "Бо мо тамос гиред",
  last_name: "Насаб",
  first_name: "Ном",
  father_name: "Номи падар",
  enter_login: "Логинатонро ворид кунед",
  enter_name: "Номатонро ворид кунед",
  enter_surname: "Насабатонро ворид кунед",
  enter_fathname: "Номи падаратонро ворид кунед",
  enter_password: "Пароли худро ворид кунед",
  flight_search: "Ҷустуҷӯи чиптахо",
  buy_air_tickets: "Бидуни тарк кардани хона чиптаҳои ҳавопаймо харед",
  log_in: "Даромадан",
  return_to_simple_route: "Бозгашт ба роҳи оддӣ",
  delete: "Нест кардан",
  max_num_flights: "Шумо шумораи максималии парвозҳоро илова кардед",
  select_dep_date: "Санаи рафтанро интихоб кунед",
  select_return_date: "Санаи бозгаштро интихоб кунед",
  select_class: " Классро интихоб кунед",
  login_as_user: " Ҳамчун корбар ворид шавед",
  enter_recieved_code: " Рамзи қабулшударо ворид кунед",
  come_up_your_pass: " Рамзи худро биёред",
  enter_valid_mail: " Почтаи дуруст ворид кунед",
  must_field_characters: "Майдон бояд на камтар аз 6 аломат дошта бошад",
  register_to_save_data: " Сабт кунед то маълумоти худро захира кунед",
  ins_main_label: " Кӯдак",
  ins_second_label: " то 2 сол (бо ҷой)",
  newsletter_subscription: "Обуна ба номаи ахбор",
  newsletter_subscription_text: "Обуна шавед, то аз ҳама навигариҳои муҳим ва аҳдҳои хуб огоҳ бошед!",
  subscribe: "Обуна шавед",
  successfully_subscribed: "Шумо бомуваффақият обуна шудед!",
  subscription_error: "Хато ҳангоми обуна шудан, лутфан баъдтар дубора кӯшиш кунед!",
  all_news: "Ҳама хабарҳо",
  tel: "Тел",
  enter_email: "Email-атонро ворид кунед",
  menu: "Меню",
  settings: " Танзимот",
  deposit_method: "Усули пасандозро интихоб кунед",
  recharging_amount: " Маблағи пуркунӣ",
  recharge: " Пур кардан",
  recharging_balance: " Пур кардани тавозун",
  done: " Омода",
  not_found_content: " Дарёфт нашуд",
  not_found_content_text: " Мутаасифона, ин мундариҷа бо забони интихобшуда дастрас нест",
  airport_code: "Коди фурудгоҳ",
  from_city: "Аз ш.",
  to_city: "Ба шаҳр",
  weekdays: "Рӯзҳои ҳафта",
  airline: "Ширкати ҳавопаймоӣ",
  min_number: "Маблағи пуркунӣ бояд зиёдтар аз",
  only_numbers: " Танҳо рақамҳо",
  must_register: " Барои харидани чиптаҳо дар сайти мо, шумо бояд ворид шавед",
  daily: "Ҳаррӯза",
  fly_tagline: "Бо мо парвоз кардан осон аст",
  sorting: "Ҷудо кардан",
  i_read_fares: "Ман бо",
  i_accept_fares: "шинос шудам ва онҳоро қабул мекунам",
  prices_table: "Ҷадвали нархҳо",
  continue: "Давом дихед",
  reset_error_title: "Хатогӣ",
  reset_error_content: "Узр, ҳангоми иҷроиши дархост хатогӣ рӯй дод",
  reset_success_title: "Шумо бомуваффакият рамзи худро иваз намудед",
  page_my_transactions: "Ҳисобот дар бораи пардохтҳои анҷомшуда",
  reset_success_content: 'Лутфан, тугмаи "Ворид"-ро пахш намоед ва бо рамзи нав ворид шавед',
  forgot_success_title: "Бомуваффакият!",
  forgot_success_content: "Пайванд барои барқароркунии рамз ба почтаи электрониатон равон карда шуд",
  forgot_error_title: "Хатогӣ",
  forgot_error_content: "Узр, Шумо почтаи электронии нодурустро ворид  кардед",
  password_confirm: "Рамзро тасдиқ кунед",
  password_confirm_error: "Рамзҳо мувофиқат намекунанд(Рамзҳо гуногун ҳастанд)",
  enter_password_confirm: "Тасдиқи рамз",
  reset_password: "Бозсозии рамз",
  enter_new_password: "Рамзи навро ворид кунед",
  forgot_password: "Рамзро фаромуш кардед?",
  forgot_password_summary: "Барои бозсозии рамз почтаи электронии худро ворид кунед",
  popup_event_text: "Дар сомона худро бақайд гирифта ба ҳисоби кабинети инфиродиатон 100 сомони бонус қабул кунед",
  popup_description: "Дар сомона худро бақайд гиред ва бидуни тарк намудани хонаатон Онлайн чипта харед!",
  payment: 'Пардохт тавассути "Корти миллӣ"',
  from_airport: "Аз фурудгоҳи",
  not_enough_balance: "Маблағ барои пардохт кофӣ нест",
  questions_and_answers: "Саволу ҷавоб",
  payment_methods: "Усулҳои пардохт",
  our_partners: "Шарикони мо",
  confirm_actions: "Амали худро дар сомона тасдиқ кунед",
  payer_contacts: "Алоқаҳои харидор",
  buyer_email: "Email-и харидор",
  enter_buyer_email: "Email-и харидорро ворид кунед",
  locator_order_number_or_booking_number: "Локатор, рақами фармоиш ё рақами брон",
  enter_locator_order_number_or_booking_number: "Локатор, рақами фармоиш ё рақами бронро ворид кунед",
  activate: "Фаъол кунед",
  register: "Дар сайт номнавис кунед",
  pre_cashback: "Чиптаҳои ҳавопаймои харед ва",
  cashback_text: "КЭШБЕКИ",
  post_cashback: "муайян ба ҳисоби саҳифаи шахсиатон гиред",
  page_home: "Онлайн хариди чиптахо, бидуни боздид ба касса",
  page_faq: "Ҷавобҳо ба саволҳои машҳур",
  page_login: "Ба саҳифаи шахсии худ ворид шавед",
  page_registration: "Бақайдгирӣ кунед ва чипта харед",
  page_results: "Натиҷаҳо барои маршрути лозима",
  page_booking: "Брон кардани чиптаи додашуда",
  page_order: "Маълумот дар бораи фармоиши иҷрошуда",
  page_orders_list: "Маълумот дар бораи фармоишҳои иҷрошуда",
  page_my_profile: "Маълумоти шахсӣ",
  page_add_balance: "Пур кардани бақияи суратҳисоби шахсӣ",
  page_popular_destinations: "Ҷадвали парвозҳои машҳур",
  page_news: "Хабарҳои машҳури авиатсия ва Fly TJ",
  page_forgot_password: "Хариди чиптаҳои ҳавопаймо бидуни тарк аз хона!",
  page_reset_password: "Хариди чиптаҳои ҳавопаймо бидуни тарк аз хона!",
  useful_info: "Иттилооти муфид",
  copy_link: "Истинодро нусхабардорӣ кунед",
  copy_order_link_text: "Барои мубодилаи дастрасӣ ба ин фармоиш истинодро нусхабардорӣ кунед",
  link_copied_to_clipboard: "Истинод ба буфер нусхабардорӣ карда шуд",
  unsubscribe_success: "Бо муваффақият обунаро қатъ кардед!",
  unsubscribe_error: "Ҳангоми коркард хатогӣ рӯй дод",
  activation_code_send_to_email: "Рамзи тасдиқкунанда ба суроғаи почтаи электронии шумо фиристода мешавад",
  choose_gender: "Ҷинсро интихоб кунед",
  old_information: "Маълумот кӯҳна шудааст!",
  refresh: "Навсозӣ",
  refresh_page:
    "Нархи чиптаҳои ҳавопаймо дар як рӯз чанд маротиба нав карда мешавад. Саҳифаро навсозӣ кунед, то нархҳои ҷориро тафтиш кунед.",
  will_take_2_minutes: "Ин метавонад каме вақт мегирад",
  error_additional_options_text:
    "Ҳангоми фаъол кардани имконоти иловагӣ барои брон хатогӣ рух дод. Бе имконоти иловагӣ идома медиҳед?",
  edit_personal_data: "Иттилооти шахсиро иваз кардан",
  minimum_amount: "Ҳадди ақали маблағ",
  additional_options: "Хизматҳои иловагӣ",
  additional_options_not_found: "Хизматҳои иловагӣ ёфт нашуданд!",
  profile_changed: "Профил бомуваффақият иваз карда шуд",
  welcome: "Хуш омадед ",
  register_on_somon_air: "Бақайдгири дар Somon Air",
  min_6_symbol: "Ҳадди аққал 6 аломат",
  your_id: "ID-и Шумо",
  cheapest_without_baggage: "Арзонтарин бе бағоҷ",
  cheapest_with_baggage: "Арзонтарин бо бағоҷ",
  most_convenient_transfes: "Интиқоли қулайтарин",
  fly_ru_tagline: "",
  fly_ru_subtagline: "",
  fly_ru_round_the_clock: "",
  refund_order: "Ариза оид ба бозхонд",
  refund_reason: "Сабаби бозхонд",
  order_payment_method: "Усули пардохти фармоиш",
  payer_user_id: "",
  four_last_digits_of_card: "",
  user_id: "",
  buyer_phone: "",
  requisite_of_account_to_return: "",
  requisite_of_account: "",
  passenger_name_and_surname: "",
  passport_serial_and_number: "",
  passport_copy: "",
  send: "",
  payment_from_client_balance: "",
  payment_from_card: "",
  vasl_tagline: "Бароҳатӣ афзалият аст!",
  technical_support: "Дастгирии техники",
  success_refund_order_request: "Дархости баргардонидани маблағ бомуваффақият фиристода шуд!",
  error_refund_order_request: "Хатогӣ ҳангоми ирсоли дархости баргардонидани маблағ!",
  find_options: "Ҷустуҷӯи чиптаҳо",
  buy_tickets_to_all_destinations: "ва чиптаҳоро ба ҳама самтҳо харед!",
  filters: "Филтерҳо",
  night_flight: "Интиқоли шабона",
  change_airport: "Ивази фурудгоҳ",
  and: "ва",
  from: "аз",
  refund_order_success: "Аризаи бозгашт бо муваффакият фиристода шуд",
  refund_order_error: "Хатоги дар мавриди фиристонидани ариза ",
  buy_tour: "Турро дастрас кардан",
  payment_alert: "Истифодабарандаи мӯҳтарам, фармоиши шумо пардохт карда шуд.  Вақти истихроҷ 1 дақиқа.",
  customer_office_address: "Суроғаи идораи муштариён",
  inner_document: "Ҳуҷҷати дохилӣ",
  document_copy: "Нусхая ҳуҷҷат",
  maximum_amount: "Маблағи максималӣ",
  search_limits_exceeded:
    "Мизоҷони мӯҳтарам, Шумо аз шумораи ҷустуҷӯҳо гузаштаед, лутфан барои идомаи кори худ аз авторизатсия/қайдгирӣ гузаред",
  flight_without_seats: "Мутаасифона, ҷойҳо барои ин парвоз ба анҷом расиданд.",
  document_type: "Намуди ҳуҷҷат",
  document_number: "Рақами ҳуҷҷат",
  expiration_date: "Санаи эътибор",
  any_cabin: "Ҳамаи синфҳо",
  my: "Сайти",
  site: "Ман",
  show_more: "Бисёртар нишон додан",
  route: "Маршрут",
  pay_before: "Пардохт пештар аз",
};

export default tj;
