import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  maxHeight: 280,
  overflowY: "auto",
}));

export const AirportItem = styled("div")(({ theme, isMain }) => ({
  ...(isMain ? theme.paddings(5, 15) : theme.paddings(5, 15, 5, 30)),
  ...theme.mixins.flexCenterBetween,
  gap: 5,
  fontSize: 13,
  color: "#333",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#3598db1a",
  },

  "& > div": {
    ...theme.mixins.flexCenterBetween,
    gap: 5,
  },

  "& > div > svg": {
    minWidth: 13,
  },

  "& > span": {
    color: "#b7b7b7",
  }
}));
