import { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setShowSearch } from "../../store/appConfigsSlice";

import { useAuth } from "../../context/auth";

import { ActivationForm, RegistrationForm } from "../../components/auth";

const RegistrationPage = () => {
  const dispatch = useDispatch();
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const [step, setStep] = useState("registration");
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    dispatch(setShowSearch(false));
  }, []);

  if (isAuth) {
    navigate("/");
    return null;
  }

  return (
    <>
      {step === "registration" && <RegistrationForm onChangeStep={setStep} onChangeClientId={setClientId} />}
      {step === "activation" && <ActivationForm clientId={clientId} />}
    </>
  );
};

export default withTranslation()(RegistrationPage);
