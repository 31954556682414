import _ from "lodash";
import moment from "moment";

import { BY_MAX_PRICE, BY_MIN_PRICE } from "../constants";

import { sortByMaxPrice, sortByMinPrice } from "./resultData";

export const getFilterRecommendations = (filterParams, recommendations, currency) => {
  const {
    transfers,
    baggage,
    airlines,
    duration,
    airports,
    departureTime,
    arrivalTime,
    flightNumbers,
    price,
  } = filterParams;

  let recList = recommendations;

  if (transfers.isActive) {
    recList = byTransfers(recommendations, transfers.values);
  }

  if (airlines.isActive) {
    recList = byAirlines(recList, airlines.values);
  }

  if (flightNumbers.isActive) {
    recList = byFlightNumbers(recList, flightNumbers.values);
  }

  if (airports.isActive) {
    recList = byAirports(recList, airports.values);
  }

  if (departureTime.isActive) {
    recList = byDepartureTime(recList, departureTime.values);
  }

  if (arrivalTime.isActive) {
    recList = byArrivalTime(recList, arrivalTime.values);
  }

  if (duration.isActive) {
    recList = byDuration(recList, duration.values);
  }

  if (price.isActive) {
    recList = byPrice(recList, price.values, currency);
  }

  if (baggage.isActive) {
    recList = byBaggage(recList, baggage.values);
  }

  return recList;
};

export const byBaggage = (recommendations, filtersData) => {
  const { withoutBaggage, withBaggage } = filtersData;
  if (!withBaggage && !withoutBaggage) {
    return [];
  } else {
    const { withoutBaggage, withBaggage } = filtersData;

    return _.filter(recommendations, function (recommendation) {
      const { baggage } = recommendation.routes[0].segments[0];

      if (withBaggage) {
        return baggage;
      }

      if (withoutBaggage) {
        return !baggage;
      }
    });
  }
};

export const byTransfers = (recommendations, filtersData) => {
  const { withoutTransfers, oneTransfer, manyTransfers } = filtersData;
  let list = [];

  if (withoutTransfers) {
    const filterData = _.filter(recommendations, function (recommendation) {
      let filterStatus = true;

      _.forEach(recommendation.routes, function (route) {
        if (route.segments.length > 1) {
          filterStatus = false;
        }
      });

      return filterStatus;
    });

    list = [...list, ...filterData];
  }

  if (oneTransfer) {
    const filterData = _.filter(recommendations, function (recommendation) {
      let filterStatus = false;

      _.forEach(recommendation.routes, function (routes) {
        if (routes.segments.length === 2) {
          filterStatus = true;
        }
      });

      _.forEach(recommendation.routes, function (routes) {
        if (routes.segments.length > 2) {
          filterStatus = false;
        }
      });

      return filterStatus;
    });

    list = [...list, ...filterData];
  }

  if (manyTransfers) {
    const filterData = _.filter(recommendations, function (recommendation) {
      let filterStatus = false;

      _.forEach(recommendation.routes, function (routes) {
        if (routes.segments.length > 2) {
          filterStatus = true;
        }
      });

      return filterStatus;
    });

    list = [...list, ...filterData];
  }

  return _.uniq(list);
};

export const byAirlines = (recommendations, airlines) => {
  return _.filter(recommendations, function (recommendation) {
    const { validating_supplier } = recommendation;

    return airlines.indexOf(validating_supplier) > -1;
  });
};

export const byAirports = (recommendations, filtersData) => {
  return _.filter(recommendations, function (recommendation) {
    let status = true;

    _.forEach(filtersData, function (item) {
      let departureStatus = true;
      let arrivalStatus = true;

      if (item.departures.length !== 0) {
        departureStatus =
          item.departures.indexOf(_.first(recommendation.routes[item.routeIndex].segments).departure.airport) !== -1;
      }

      if (item.arrivals.length !== 0) {
        arrivalStatus =
          item.arrivals.indexOf(_.last(recommendation.routes[item.routeIndex].segments).arrival.airport) !== -1;
      }

      if (!departureStatus || !arrivalStatus) {
        status = false;
      }
    });

    return status;
  });
};

export const byFlightNumbers = (recommendations, filtersData) => {
  return _.filter(recommendations, function (recommendation) {
    let status = false;

    _.forEach(recommendation.routes, function (route) {
      _.forEach(route.segments, function (segment) {
        if (filtersData.indexOf(segment.carrier_number) !== -1) {
          status = true;
        }
      });
    });

    return status;
  });
};

export const byDuration = (recommendations, filtersData) => {
  return _.filter(recommendations, function (recommendation) {
    let status = true;

    _.forEach(filtersData, function (item) {
      const { min, max } = item.values;
      const duration = recommendation.routes[item.routeIndex].duration;
      const durationStatus = min <= duration && duration <= max;

      if (!durationStatus) {
        status = false;
      }
    });

    return status;
  });
};

export const byDepartureTime = (recommendations, filtersData) => {
  return _.filter(recommendations, function (recommendation) {
    let status = true;

    _.forEach(filtersData, function (item) {
      const { min, max } = item.values;
      const departureTimeParsed = _.first(recommendation.routes[item.routeIndex].segments).departure.time.split(" ");
      const departureTime = moment(_.last(departureTimeParsed), "HH:mm").diff(moment().startOf("day"), "seconds");
      const departureTimeStatus = min <= departureTime && departureTime <= max;

      if (!departureTimeStatus && min && max) {
        status = false;
      }
    });

    return status;
  });
};

export const byArrivalTime = (recommendations, filtersData) => {
  return _.filter(recommendations, function (recommendation) {
    let status = true;

    _.forEach(filtersData, function (item) {
      const { min, max } = item.values;
      const arrivalTimeParsed = _.last(recommendation.routes[item.routeIndex].segments).arrival.time.split(" ");
      const arrivalTime = moment(_.last(arrivalTimeParsed), "HH:mm").diff(moment().startOf("day"), "seconds");
      const arrivalTimeStatus = min <= arrivalTime && arrivalTime <= max;

      if (!arrivalTimeStatus && min && max) {
        status = false;
      }
    });

    return status;
  });
};

export const byPrice = (recommendations, priceRange, currency) => {
  const { min, max } = priceRange;

  return _.filter(recommendations, function (recommendation) {
    const totalPrice = recommendation["total_price"][currency];

    return min <= totalPrice && totalPrice <= max;
  });
};

export const getSortingRecommendations = (filterParams, recommendations, currency) => {
  const { sorting } = filterParams;

  switch (sorting.value) {
    case BY_MIN_PRICE:
      return sortByMinPrice(recommendations, currency);
    case BY_MAX_PRICE:
      return sortByMaxPrice(recommendations, currency);
    default:
      return recommendations;
  }
};
