import RecommendationFlightItemComponent from "../RecommendationFlightItemComponent";

import { Wrapper } from "./style";

const RecommendationFlightsComponent = ({ recommendation }) => {
  const {
    routes,
    brandRecId,
    rec_id: recId,
    validating_supplier: validatingSupplier,
    has_branded_tariffs: hasBrandedTariffs,
    brand_name,
  } = recommendation;

  return (
    <Wrapper>
      {routes.map((route, index) => (
        <RecommendationFlightItemComponent
          key={index}
          recId={recId}
          route={route}
          validatingSupplier={validatingSupplier}
          hasBrandedTariffs={hasBrandedTariffs}
          brandRecId={brandRecId}
          brandName={brand_name}
        />
      ))}
    </Wrapper>
  );
};

export default RecommendationFlightsComponent;
