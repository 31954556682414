import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 15,
}));

export const SearchInputBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 15,
  width: "100%",
}));

export const NotFoundText = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontSize: 14,
  color: "#e53935",
}));

export const SearchButtonBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
}));

export const SearchButton = styled("button")(({ theme }) => ({
  ...theme.paddings(0, 25),
  border: "none",
  backgroundColor: theme.palette.searchButtonBg,
  cursor: "pointer",
  height: 40,
  fontSize: 14,
  color: theme.palette.white,
  borderRadius: 4,
  position: "relative",

  "&:hover": {
    filter: "brightness(90%)",
  },

  "&:disabled": {
    opacity: 0.6,
    cursor: "default",

    "&:hover": {
      filter: "none",
    },
  },

  "& > div": {
    ...theme.mixins.flexCenterCenter,
    borderRadius: 4,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.searchButtonBg,
  },
}));

export const IconButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "50%",
  color: "#000000",
  fontSize: 34,

  "&:hover": {
    cursor: "pointer",
  },
}));
