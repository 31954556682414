import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  ...theme.paddings(30, 20),
  gap: 20,
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
}));

export const PassengersBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 10,
}));

export const CheckboxBlock = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const AgreementText = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: "#151515",
}));

export const AgreementBookLink = styled("span")(({ theme }) => ({
  color: "#039be5",
  cursor: "pointer",
}));

export const SubmitBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
}));

export const Button = styled("button")(({ theme }) => ({
  ...theme.paddings(0, 15),
  position: "relative",
  border: "none",
  backgroundColor: theme.palette.searchButtonBg,
  cursor: "pointer",
  height: 46,
  fontSize: 20,
  color: theme.palette.white,
  borderRadius: 4,

  "&:disabled": {
    filter: "brightness(50%)",
    cursor: "default",
  },

  "&:hover": {
    filter: "brightness(90%)",
  },

  [theme.down("sm")]: {
    fontSize: 16,
  },

  "& > div": {
    ...theme.mixins.flexCenterCenter,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.searchButtonBg,
  },
}));
