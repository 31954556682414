import { useCallback, useMemo, useState } from "react";

import { RiCalendarLine, RiCloseLine } from "react-icons/ri";

import { debounce } from "../../../../utils";

import CircularLoading from "../../CircularLoading";

import { BeforeIconBlock, IconBlock, StyledInput, Wrapper } from "./style";

const TableFiltersDate = ({ filterKey, onChangeFilter, placeholder }) => {
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async (text) => {
    setIsLoading(true);
    await onChangeFilter(filterKey, text);
    setIsLoading(false);
  }, []);

  const debouncedGetData = useMemo(() => debounce(getData, 300), [getData]);

  const handleChangeInput = (newValue) => {
    setInputText(newValue);

    if (!newValue.includes("_")) {
      debouncedGetData(newValue.split(".").reverse().join("-"));
    }
  };

  return (
    <Wrapper>
      <BeforeIconBlock>
        <RiCalendarLine />
      </BeforeIconBlock>

      <StyledInput
        value={inputText}
        onChange={e => handleChangeInput(e.target.value)}
        placeholder={placeholder}
        $hasClearIcon={inputText || isLoading}
        mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
      />

      <IconBlock>
        {(!isLoading && inputText.length > 0) ? <RiCloseLine onClick={() => handleChangeInput("")} /> : null}
        {isLoading ? <CircularLoading size="16px" color="#b7b7b7" /> : null}
      </IconBlock>
    </Wrapper>
  );
};

export default TableFiltersDate;
