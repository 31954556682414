import i18next from "i18next";
import _ from "lodash";
import { useState } from "react";
import { ModalComponent, SelectComponent } from "../../ui";
import { RulesAccordion, RulesAccordionContent, RulesAccordionTitle, RulesAccordionToggleButton, SelectRoutes, StyledPaper, Wrapper } from "./style";
import classNames from "classnames";
import { BiChevronDown } from "react-icons/bi";

const RulesModalComponent = ({ fareRules, included, routes, actualSegment, openModal, handleCloseModal }) => {
  const { t, language } = i18next;

  let segment = actualSegment;

  if (!actualSegment) {
    segment = routes[0].segments[0];
  }

  const { fare_code, departure } = segment;
  const departureDate = departure.time.split(' ');
  const fareCodeDefaultKey = fare_code + '|' + _.first(departureDate) + '|' + segment.index;

  const [fareCodeKey, setFareCodeKey] = useState(fareCodeDefaultKey);
  const [isShowAccordion, setIsShowAccordion] = useState(false);

  const getSegmentCity = segment => {
    const departureTime = segment.departure.time;
    const departureCode = segment.departure.city;
    const arrivalCode = segment.arrival.city;
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureCity = included.city[departureCode].name[language];

    return [departureCity, arrivalCity, departureTime];
  };

  const getSegmentsCityList = () => {
    let data = [];

    _.forEach(routes, function (route) {
      _.forEach(route.segments, function (segment) {
        const [departureCity, arrivalCity, departureTime] = getSegmentCity(segment);
        const departureDateArray = departureTime.split(' ');
        const label = departureCity + ' — ' + arrivalCity + ', ' + departureDateArray[0];
        const fareCode = segment.fare_code;

        data = [
          ...data,
          {
            value: fareCode + '|' + departureDateArray[0] + '|' + segment.index,
            label,
          },
        ];
      });
    });

    return data;
  };

  const segmentsList = getSegmentsCityList(routes, included, language);
  const segmentList = Object.keys(segmentsList).map(key => segmentsList[key]);

  const getRulesContent = () => {
    let content = t('no_data_contact_technical_support');
    const fareCode = _.first(fareCodeKey.split('|'));

    if (fareRules.length > 0 && _.isString(fareCode)) {
      const fareRule = _.find(fareRules, { fare_code: fareCode });

      if (fareRule !== undefined) {
        content = fareRule.penalties;
      }
    }

    return content;
  };

  const onCloseRules = () => {
    handleCloseModal();
    setFareCodeKey(fareCodeDefaultKey);
  };
  
  return (
    <ModalComponent active={openModal} onClose={onCloseRules} title={t('tariff_rules')} fixedWidth>
      <Wrapper>
        <RulesAccordion>
          <RulesAccordionTitle>
            {t("how_to_read_rules")}
            <RulesAccordionToggleButton onClick={() => setIsShowAccordion(prev => !prev)} className={classNames({ active: isShowAccordion })}>
              <BiChevronDown />
            </RulesAccordionToggleButton>
          </RulesAccordionTitle>
          {isShowAccordion && (
            <RulesAccordionContent>
              <div>
                <p>
                  {t('rules_text_1')} <b>CANCELLATIONS ({t('cancel')}):</b>
                </p>
                <p>
                  <b>TICKET IS NON-REFUNDABLE</b> — {t('rules_text_2')};<br />
                  <b>TICKET IS NON-REFUNDABLE FOR CANCEL/REFUND</b> — {t('rules_text_2')};<br />
                  <b>REFUND IS NOT PERMITTED</b> — {t('rules_text_2')};<br />
                  <b>ANY TIME TICKET IS NON-REFUNDABLE</b> — {t('rules_text_2')};<br />
                  <b>TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW</b> — {t('rules_text_3')}.<br />
                </p>
                <p>
                  {t('rules_text_4')} <b>CHANGES.</b>
                </p>
                <p>
                  {t('line')} <b>CHANGES ARE NOT PERMITTED</b> {t('rules_text_5')}.
                </p>
              </div>
            </RulesAccordionContent>
          )}
        </RulesAccordion>

        <SelectRoutes>
          <SelectComponent
            value={fareCodeKey}
            options={segmentList}
            onSelect={value => setFareCodeKey(value)}
          />
        </SelectRoutes>

        <StyledPaper>
          <div dangerouslySetInnerHTML={{ __html: getRulesContent(fareCodeKey, fareRules) }} />
        </StyledPaper>
      </Wrapper>
    </ModalComponent>
  );
};

export default RulesModalComponent;
