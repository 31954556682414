const mixins = {
  flexCenterCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default mixins;
