/* eslint-disable no-console */
import { useCallback, useState } from "react";

const useLocalStorage = (key, defaultValue = null) => {
  const emitEvent = () => window.dispatchEvent(new Event("local-storage"));

  const removeItem = useCallback((k) => {
    localStorage.removeItem(k);
    emitEvent();
  }, []);

  const clear = useCallback(() => {
    localStorage.clear();
    emitEvent();
  }, []);

  const getItem = useCallback((k, isString = false) => {
    if (!k) return;

    try {
      const item = localStorage.getItem(k);

      return isString ? item : JSON.parse(item) ?? null;
    } catch (error) {
      console.warn(error);

      return null;
    }
  }, []);

  const [value, setValue] = useState(() => {
    const item = getItem(key);

    if (item != null) return item;

    if (defaultValue) {
      try {
        localStorage.setItem(key, JSON.stringify(defaultValue));
      } catch (error) {
        console.warn(error);
      }
    }

    return defaultValue;
  });

  const setItem = useCallback((k, val) => {
    try {
      if (k) {
        localStorage.setItem(k, JSON.stringify(val));
      }

      setValue(val);

      emitEvent();
    } catch (error) {
      console.warn(error);
    }
  }, []);

  return {
    value,
    setItem,
    getItem,
    removeItem,
    clear,
  };
};

export default useLocalStorage;

