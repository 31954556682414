import { useEffect, useRef, useState } from "react";

import { DropdownBlock, Wrapper } from "./style";

const CalendarDropdown = ({
  children,
  show,
  onClickOutside,
  noPadding = false,
  fromDateComponentRef,
  toDateComponentRef,
  flightType,
  isCompact,
}) => {
  const node = useRef();
  const [wrapperShow, setWrapperShow] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (show) {
      setWrapperShow(true);
      setDropdownShow(true);
    }
  }, [show]);

  const handleClick = e => {
    if (
      node.current?.contains(e.target) ||
      fromDateComponentRef.current?.contains(e.target) ||
      toDateComponentRef.current?.contains(e.target)
    ) {
      return;
    }

    handleClose();
  };

  const handleClose = () => {
    setDropdownShow();
    setTimeout(() => {
      setWrapperShow(false);
      onClickOutside();
    }, 300);
  };

  return (
    <Wrapper ref={node} active={wrapperShow} $flightType={flightType} isCompact={isCompact}>
      <DropdownBlock active={dropdownShow} noPadding={noPadding}>
        {children}
      </DropdownBlock>
    </Wrapper>
  );
};

export default CalendarDropdown;
