import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_RT } from "../../../constants";

import { removeExtraRoutes, setFlightType } from "../../../store/searchParamsSlice";

import { ReactComponent as AdvancedRouteIcon } from "../../../assets/icons/advancedRouteIcon.svg";

import {
  DirectionButton,
  DirectionButtonIcon,
  DirectionButtonText,
  Wrapper,
} from "./style";

const SearchFlightTypeSelectComponent = () => {
  const { t } = i18next;
  const { flightType } = useSelector((store) => store.searchParams);
  const dispatch = useDispatch();

  const toggleRouteType = () => {
    if (flightType === FLIGHT_TYPE_CF) {
      dispatch(setFlightType(FLIGHT_TYPE_RT));
      dispatch(removeExtraRoutes());
    } else {
      dispatch(setFlightType(FLIGHT_TYPE_CF));
    }
  };

  return (
    <Wrapper>
      <DirectionButton onClick={toggleRouteType}>
        <DirectionButtonIcon>
          <AdvancedRouteIcon />
        </DirectionButtonIcon>

        <DirectionButtonText>
          {flightType === FLIGHT_TYPE_CF
            ? t("return_to_simple_route")
            : t("difficult_routes")}
        </DirectionButtonText>
      </DirectionButton>
    </Wrapper>
  );
};

export default SearchFlightTypeSelectComponent;
