import styled from "styled-components";

export const ButtonsBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
}));

export const BaggageButton = styled("button")(({ theme }) => ({
  width: "50%",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: 0,
  color: "#151515",
  fontSize: 14,
  height: 32,
  cursor: "pointer",
  transition: "all .3s ease",

  "&.active": {
    backgroundColor: "#ff8100",
    borderColor: "#ff8100",
    color: "#fff",
  }
}));
