import { useRef, useEffect } from 'react';

import ReactDOM from 'react-dom';
import { RiCloseLine } from "react-icons/ri";

import { Wrapper, Backdrop, Modal, Header, LeftButton, Title, CloseButton, Body } from './style';

const ModalComponent = ({
  children,
  active,
  onClose,
  title,
  leftButtonText,
  leftButtonOnClick,
  flexCenter,
  maxWidth = 1280,
  bodyPadding = '0 15px 15px',
  mobileBodyPadding = '15px',
  fixedWidth = false,
}) => {
  const backdropRef = useRef(null);

  useEffect(() => {
    if (active) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }
  }, [active]);

  const handleClose = () => {
    onClose();
  };

  const handleBackdropClick = event => {
    if (event.target === backdropRef.current) {
      handleClose();
    }
  };

  if (!active) return null;

  return ReactDOM.createPortal(
    <Wrapper isShow={active}>
      <Backdrop isShow={active} onClick={handleBackdropClick} ref={backdropRef}>
        <Modal isShow={active} maxWidth={maxWidth} fixedWidth={fixedWidth}>
          <Header>
            <div>{leftButtonText && <LeftButton onClick={leftButtonOnClick}>{leftButtonText}</LeftButton>}</div>

            <Title>{title}</Title>

            <CloseButton onClick={handleClose}>
              <RiCloseLine />
            </CloseButton>
          </Header>

          <Body flexCenter={!!flexCenter} bodyPadding={bodyPadding} mobileBodyPadding={mobileBodyPadding}>
            {children}
          </Body>
        </Modal>
      </Backdrop>
    </Wrapper>,
    document.body
  );
};

export default ModalComponent;
