import { useRef, useState } from "react";

import classNames from "classnames";
import i18next from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { LuCalendarDays } from "react-icons/lu";
import { RiCloseLine } from "react-icons/ri";
import { useSelector } from "react-redux";

import { FLIGHT_TYPE_CF, FLIGHT_TYPE_OW, FLIGHT_TYPE_RT } from "../../../constants";

import useToggle from "../../../hooks/useToggle";

import CalendarComponent from "../../calendar/CalendarComponent";

import {
  FromDateBlock,
  InputClearBlock,
  InputDate,
  InputIconBlock,
  InputPlaceholder,
  ToDateBlock,
  Wrapper,
} from "./style";

const SearchDatesSelectComponent = ({
  flightType,
  routeIndex,
  onChangeDate,
  fromActiveDate,
  toActiveDate,
  selectedDays,
}) => {
  const { t } = i18next;
  const { notFilledFields } = useSelector(state => state.searchParams);
  const { searchBlockCompact } = useSelector(store => store.appConfigs);

  const {
    isTrue: isShowCalendar,
    set: { on, off },
  } = useToggle();
  const [currentActiveType, setCurrentActiveType] = useState("from");
  const fromDateComponentRef = useRef(null);
  const toDateComponentRef = useRef(null);

  const handleChangeDate = newDate => {
    if (flightType !== FLIGHT_TYPE_CF) {
      onChangeDate(currentActiveType === "from" ? 0 : 1, newDate);

      if (currentActiveType === "from") setCurrentActiveType("to");
    } else {
      onChangeDate(routeIndex, newDate);
    }
  };

  const handleClearDate = from => {
    if (flightType !== FLIGHT_TYPE_CF) {
      onChangeDate(from === "from" ? 0 : 1, null);
    } else {
      onChangeDate(routeIndex, null);
    }
  };

  const handleClickInput = value => {
    setCurrentActiveType(value);
    on();
  };

  return (
    <Wrapper flightType={flightType}>
      <FromDateBlock
        flightType={flightType}
        onClick={() => handleClickInput("from")}
        ref={fromDateComponentRef}
        className={classNames({ notFilled: notFilledFields["date"] && routeIndex === 0 })}
      >
        <InputPlaceholder className={fromActiveDate && "not-empty"}>{t("date_from")}</InputPlaceholder>

        <InputIconBlock>
          <LuCalendarDays />
        </InputIconBlock>

        {fromActiveDate && <InputDate>{moment(fromActiveDate).format("DD MMMM")}</InputDate>}

        {fromActiveDate && (
          <InputClearBlock>
            <RiCloseLine onClick={() => handleClearDate("from")} />
          </InputClearBlock>
        )}
      </FromDateBlock>

      {flightType !== FLIGHT_TYPE_CF && (
        <ToDateBlock onClick={() => handleClickInput("to")} ref={toDateComponentRef}>
          <InputPlaceholder className={toActiveDate && "not-empty"}>{t("date_to")}</InputPlaceholder>

          <InputIconBlock>
            <LuCalendarDays />
          </InputIconBlock>

          {toActiveDate && <InputDate>{moment(toActiveDate).format("DD MMMM")}</InputDate>}

          {toActiveDate && (
            <InputClearBlock>
              <RiCloseLine onClick={() => handleClearDate("to")} />
            </InputClearBlock>
          )}
        </ToDateBlock>
      )}

      {isShowCalendar && (
        <CalendarComponent
          show={isShowCalendar}
          onClose={off}
          type={currentActiveType}
          flightType={flightType}
          onSelectDay={handleChangeDate}
          activeDay={currentActiveType === "from" ? fromActiveDate : toActiveDate}
          selectedDays={selectedDays}
          fromDateComponentRef={fromDateComponentRef}
          toDateComponentRef={toDateComponentRef}
          isCompact={searchBlockCompact}
        />
      )}
    </Wrapper>
  );
};

export default SearchDatesSelectComponent;

SearchDatesSelectComponent.defaultProps = {
  flightType: FLIGHT_TYPE_RT,
  routeIndex: null,
};

SearchDatesSelectComponent.propTypes = {
  flightType: PropTypes.oneOf([FLIGHT_TYPE_OW, FLIGHT_TYPE_RT, FLIGHT_TYPE_CF]),
  routeIndex: PropTypes.number,
};
